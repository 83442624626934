import React from "react";
import CustomTable from "../../../../Components/CustomTable";
import {Fab, Tooltip } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import DialogGut from "./DialogGut";

export default function Table({data, setData}) {
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState({})
  const [index, setIndex] = React.useState(0)
 
  const submit = async  (values, { setSubmitting }) => {
    const itens = data.map((value) => value);
    itens[index] = values
     setData(itens);
     setSubmitting(false)
     setOpen(false);
     setEdit(null);
     setIndex(null)
   };

  function handleDelete ({value, tableMeta, updateValue}){
    const buffer = data
    buffer.splice(tableMeta.rowIndex, 1)
    setData([...buffer])
   }

   function handleEdit ({value, tableMeta, updateValue}){
    setIndex(tableMeta.rowIndex)
    setEdit(data[tableMeta.rowIndex])
    setOpen(true)
   }

const columns = [
  { name: "id", options: { display: "false", empty: false, } },
  { name: "risk", label: "Risco", options: { filter: true, sort: true } },
  {
    name: "gravity",
    label: "Gravidade",
    options: { filter: true, sort: true,
     customBodyRender: (value) => {return value.name? value.name: value},
     },
  },
  { name: "urgency", label: "Urgência", options: { filter: true, sort: true,
    customBodyRender: (value) => {return value.name? value.name: value},
   } },
  { name: "trend", label: "Tendência", options: { filter: true, sort: true,
    customBodyRender: (value) => {return value.name? value.name: value},
   } },
   { name: "average",  label: "Nível do problema", options: { filter: true, sort: true}, },
  { name: "gut", label: "GUT", options: { filter: true, sort: true } },
  {
    name: "",
    label: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Tooltip placement="top" title="Editar GUT" aria-label="Editar GUT">
          <span>
          <Fab size="small" color="primary" aria-label="edit"
          onClick={() => handleEdit({value, tableMeta, updateValue})}
          >
            <Icons.Edit/>
          </Fab>
          </span> 
          </Tooltip>
        );
      },
    },
  },
  {
    name: "",
    label: "",
    options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Tooltip placement="top" title="Apagar GUT" aria-label="Apagar GUT">
           <span>
           <Fab size="small" color="primary" aria-label="edit" 
                onClick={() => handleDelete({value, tableMeta, updateValue})}>
             <Icons.Delete/>
           </Fab>
           </span> 
          </Tooltip>
        );
      },
    },
  },
];
  return (
  <>
 {edit !== null && index !== null ? <DialogGut submit={submit} rowData={edit} open={open} setOpen={setOpen} title='Editar GUT'/>:null }
  <CustomTable data={data} columns={columns}/>
  </>);
}
