import React from 'react'
import CustomTable from "../../../../Components/CustomTable";
import {ShowPriority} from '../../../../Components/ShowInfos';
import {Fab, Tooltip } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import DialogTask from './DialogTask';
import { FormatDate, FormatRealNumber } from '../../../../Components/Helper/FormatValues';
import DuplicTask from './Dup';
 
export default function Table({data, setData}) {
  const [open, setOpen] = React.useState(false)
  const [openDup, setOpenDup] = React.useState(false)

  const [edit, setEdit] = React.useState(null)
  const [index, setIndex] = React.useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submit = async  (values, { setSubmitting }) => {
    const buffer ={  
      name: values.name,
      initDate: values.initDate,
      deadLine: values.deadLine,
      predictedValue: !values.predictedValue ? 0 :values.predictedValue,
      description: values.description,
      priority: values.priority,
      responsible: values.responsible,
      responsibleSecretary: values.responsibleSecretary}


    const itens = data.map((value) => value);
    itens[index] = buffer
     setData(itens);
     setSubmitting(false)
     setOpen(false);
     setEdit(null);
     setIndex(null)
   };

   function handleDup ({tableMeta}){
     setIndex(tableMeta.rowIndex)
     setEdit(data[tableMeta.rowIndex])
     setOpenDup(true)
   }

  function handleEdit ({value, tableMeta, updateValue}){
    setIndex(tableMeta.rowIndex)
    setEdit(data[tableMeta.rowIndex])
    setOpen(true)
   }

 function handleDelete ({value, tableMeta, updateValue}){
  const buffer = data
  buffer.splice(tableMeta.rowIndex, 1)
  setData([...buffer])
 }

  const columns = [
    { name: "id", options: { display: "false" } },
    { name: "name", label: "Etapa", options: { filter: true, sort: true } },
    { name: "responsibleSecretary", label: "Secretaria", options: { filter: true, sort: true,
      customBodyRender: (value) => {return value.name? value.name: value},
    }, 
  },  
    { name: "responsible", label: "Responsavel", options: { filter: true, sort: true,
      customBodyRender: (value) => {return value.name? value.name: value},
    }
  },
    { name: "priority", label: "Prioridade", options: { filter: true, sort: true,
      customBodyRender: (value) => {return <ShowPriority value={value.name}/>
    },
  }
},
    { name: "predictedValue", label: "Valor previsto", options: { filter: true, sort: true,   
      customBodyRender: (value) => FormatRealNumber(value)
    
  }
},
    { name: "initDate", label: "Data inicial", options: { filter: true, sort: true, customBodyRender: (value) => FormatDate(value) } },
    { name: "deadLine", label: "Data limite", options: { filter: true, sort: true, customBodyRender: (value) => FormatDate(value) } },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip placement="top" title="Editar etapa" aria-label="Editar etapa">
            <Fab size="small" color="primary" aria-label="edit"
              onClick={() => handleEdit({value, tableMeta, updateValue})}
            >
              <Icons.Edit/>
            </Fab>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip placement="top" title="Duplicar etapa" aria-label="Duplicar etapa">
            <Fab size="small" color="primary" aria-label="delete"
            onClick={() => handleDup({tableMeta})}
            >
              <Icons.Queue/>
            </Fab>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip placement="top" title="Apagar etapa" aria-label="Apagar etapa">
            <Fab size="small" color="primary" aria-label="delete"
            onClick={() => handleDelete({value, tableMeta, updateValue})}
            >
              <Icons.Delete />
            </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

 

  return(
  <>
  {edit !== null && index !== null && open ? <DialogTask submit={submit} rowData={edit} open={open} setOpen={setOpen} title='Editar etapa'/>:null}
  {edit !== null && index !== null && openDup ? 
  <DuplicTask  index={index} open={openDup} setOpen={setOpenDup}/>:null}
  <CustomTable data={data} columns={columns} />
  </> );
}

