import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import FormLoadingComponent from "../../../../../Components/FormLoading";
import CardStyle from "../../../../../Components/CardStyle";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding:"0.2rem",
    [theme.breakpoints.up("xs")]: {height: "110px"},
    [theme.breakpoints.up("sm")]: {height: "130px"},
    [theme.breakpoints.up("md")]: {height: "100px"},
    [theme.breakpoints.up("lg")]: {height: "100px"},
    [theme.breakpoints.up("xl")]: {height: "110px"},  
  },
  title:{
    fontWeight: "bold",
    fontFamily: "sans-serif",
    [theme.breakpoints.up("xs")]: { fontSize: "1.3rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.3rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.2rem"},  
  },
  subtitle:{
    fontWeight: "normal",
    fontWeight: "bold",
    [theme.breakpoints.up("xs")]: { fontSize: "1.9rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.8rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "2.1rem"},  
  },
  avatar: {
    color: "white",
    [theme.breakpoints.up("xs")]: {width: theme.spacing(3), height: theme.spacing(3)},      
    [theme.breakpoints.up("sm")]: {width: theme.spacing(2), height: theme.spacing(2)},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(3), height: theme.spacing(3)},    
    [theme.breakpoints.up("lg")]: {width: theme.spacing(3), height: theme.spacing(3)},    
    [theme.breakpoints.up("xl")]: {width: theme.spacing(4), height: theme.spacing(4)},    
  },
  large: {
    [theme.breakpoints.up("xs")]: {width: theme.spacing(5), height: theme.spacing(5),},   
    [theme.breakpoints.up("sm")]: {width: theme.spacing(4), height: theme.spacing(4),},     
    [theme.breakpoints.up("md")]: {width: theme.spacing(5), height: theme.spacing(5),},  
    [theme.breakpoints.up("lg")]: {width: theme.spacing(5), height: theme.spacing(5),},  
    [theme.breakpoints.up("xl")]: {width: theme.spacing(6), height: theme.spacing(6),},  
  },
}));

export const CardProblem = ({value}) => {
 const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Problemas analisados
             </Typography>}
   
    value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
            {value ? value :<Skeleton variant="text" width="2rem"/>}
           </Typography>}
           
           color="linear-gradient(60deg, rgba(251,140,0,1) 0%, rgba(255,202,41,1) 100%)"
    customIcon={Icons.ReportProblem}
   />
  //  <Card variant="outlined" elevation={1} className={classes.root}>
  //   <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
  //    avatar={ 
  //     <Avatar className={classes.large} style={{backgroundColor: "orange"}}>
  //      <Icons.ReportProblem className={classes.avatar}/>
  //     </Avatar>}
  //        title={
  //         <Typography className={classes.title} component="h1" color="textPrimary">
  //           Problemas analisados
  //          </Typography>}
  //         subheader={
  //           <Typography className={classes.subtitle} variant="subtitle1" color="textSecondary">
  //           {value ? value :<FormLoadingComponent/>}
  //         </Typography>}
  //       />  
  //     </Card>
  );
};


export const CardActions = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Ações planejadas
             </Typography>}
   
    value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
            {value ? value :<Skeleton variant="text" width="2rem"/>}
           </Typography>}
           
    color="linear-gradient(60deg, rgba(67,160,71,1) 0%, #4CC417 100%)"
    customIcon={Icons.ListAlt}
   />
    // <Card variant="outlined" elevation={1} className={classes.root}>
    //  <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
    //   avatar={ 
    //     <Avatar className={classes.large} style={{backgroundColor: "green"}}>
    //     <Icons.ListAlt  className={classes.avatar}/>
    //    </Avatar>}
    //      title={
    //       <Typography className={classes.title} component="h1" color="textPrimary">
    //         Ações planejadas
    //        </Typography>}
    //       subheader={
    //         <Typography className={classes.subtitle} variant="subtitle1" color="textSecondary">
    //           {value ? value :<FormLoadingComponent/>}
    //         </Typography>}
    //     />  
    //   </Card>
  );
};

export const CardActionsProblem = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Problemas com ações
             </Typography>}
   
    value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
            {value ? value :<Skeleton variant="text" width="2rem"/>}
           </Typography>}
           
    color="linear-gradient(60deg, rgba(245,0,87,1) 0%, rgba(255,138,128,1) 100%)"
    customIcon={Icons.Report}
   />
    // <Card variant="outlined" elevation={1} className={classes.root}>
    //  <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
    //   avatar={ 
    //     <Avatar className={classes.large} style={{backgroundColor: "red"}}>
    //        <Icons.Report className={classes.avatar}/>
    //      </Avatar>}
    //      title={
    //       <Typography className={classes.title} component="h1" color="textPrimary">
    //         Problemas com ações
    //        </Typography>}
    //       subheader={
    //         <Typography className={classes.subtitle} variant="subtitle1" color="textSecondary">
    //           {value ? value :<FormLoadingComponent/>}
    //         </Typography>}
    //     />  
    //   </Card>
  );
};

 
