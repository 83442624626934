import React from "react";
import { useParams } from "react-router-dom";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import { NumberFormatCustom } from "../../../Components/Helper/FormatValues";
import { SelectApi } from "../../../Components/SelectsApi";
import { GET_RESPONSIBLESECRETARY, GET_SECRETARY, POST_INSERT_TASK } from "../../../services/options";
import { ViewProjectContext } from "..";
import { TextEditor } from "../../../Components/Editor";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";
import { CustomDatePicker } from "../../../Components/CustomDatePicker";
import { useSnackbar } from "notistack";
import api from "../../../services/Api";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const initialValues = {
  name: "",
  initDate: null,
  deadLine: null,
  predictedValue: undefined,
  description: "<p></p>",
  priority: null,
  responsible: null,
  responsibleSecretary: null,
};

export default function DialogTask({open, setOpen, updateData}) {
  const { id } = useParams();
  const { infosData } = React.useContext(ViewProjectContext);
  const {projectInitDate, projectDeadLine, projectPredictedValue} = infosData;
  const init = {...initialValues,  projectInitDate, projectDeadLine, projectPredictedValue}

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object({
    name: Yup.string().required("Obrigatório")
      .min(6, "O nome deve ter pelo menos 6 caracteres")
      .max(200, "Limite maximo de 200 caracteres"),
  
     // description: Yup.string().optional(),
      initDate: Yup.date().required("Obrigatório").nullable("Obrigatório").typeError("Data inválida"),
      deadLine: Yup.date().required("Obrigatório").nullable("Obrigatório").typeError("Data inválida"),

      // deadLine: Yup.date().min(
      // Yup.ref('initDate'),
      //   "A data final não pode ser anterior à data de inícial"
      // ).required("Obrigatório").nullable(),
  
    predictedValue: Yup.number().required("Obrigatório"),
  
   // priority: Yup.object().shape().optional(),
    responsibleSecretary: Yup.object().shape().required("Obrigatório").nullable(),
    responsible: Yup.object().shape().required("Obrigatório").nullable(),
  });

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      projectId     : id,
      name          : values.name,
      initDate      : values.initDate,
      deadLine      : values.deadLine,
      predictedValue: Number(values.predictedValue),
      description   : values.description,
      responsibleSecretaryId: values.responsibleSecretary.id,
      responsibleId : values.responsible.id,
      priorityId    : 2, //priority: {id: "2", name: "Média"},
    };

    await api(POST_INSERT_TASK(buffer)).then((r) => {
        const { data } = r;
        switch (data.status) {
          case 'success':{
            updateData();
            setSubmitting(false);
            setOpen(false);
            enqueueSnackbar("Etapa criada !!!", { variant: "success" });
            break;
          }
          case 'caution':{
            updateData();
            setSubmitting(false);
            setOpen(false);
            enqueueSnackbar(data.msg, { variant: "warning" });
            break;
          } 
          case 'error':{
            setSubmitting(false);
            enqueueSnackbar(data.msg, { variant: "Error" });
            break;
          } 
          default: enqueueSnackbar(data.status, { variant: "Error" });
        }
      }).catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ao criar etapa`, { variant: "Error" });
      });
  };


  return (
    <DefaultModal open={open} handleClose={()=> setOpen(false)} title="Nova etapa">
      <Formik
        initialValues={init}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms {...props}/>}
      </Formik>
    </DefaultModal>
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values: {
      name,
      responsible,
      responsibleSecretary,
      initDate,
      deadLine,
      predictedValue,
      description,
      projectInitDate,
      projectDeadLine
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = props;

  return ( 
    <form onSubmit={handleSubmit} noValidate> 
      <DefaultModalContent dividers >
        <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="Etapa"
            name="name"
            autoFocus
            inputProps={{ autoComplete: 'off' }} 
            onChange={handleChange}
            value={name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

       <Grid item xs={12} style={{display: "flex", marginTop: "0.5rem"}}> 
        <Grid item xs={6} style={{ display: "grid" }} >
         <Grid item xs={12} style={{ maxWidth: "85%" }}>
          <CustomDatePicker
           fullWidth
           id="initDate"
           name="initDate"
           label="Data inicial"
           minDate={projectInitDate || undefined}
           maxDate={deadLine||projectDeadLine}
           onChange={(value) =>{
           setFieldValue("initDate",  value || null)}}
           value={initDate}
           error={touched.initDate && Boolean(errors.initDate)}
           helperText={touched.initDate && errors.initDate}
          />
          </Grid>

          <Grid item xs={12} style={{ maxWidth: "85%", paddingTop: "1.5rem" }}>
          <CustomDatePicker
           fullWidth
           id="deadLine"
           name="deadLine"
           label="Data limite"
           minDate={initDate||projectInitDate}
           maxDate={projectDeadLine}
           onChange={(value) =>{
           setFieldValue("deadLine", value || null)}}
           value={deadLine}
           error={touched.deadLine && Boolean(errors.deadLine)}
           helperText={touched.deadLine && errors.deadLine}
          />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            id="predictedValue"
            label="Valor previsto"
            name="predictedValue"        
            InputProps={{
              autoComplete: 'off',
              inputComponent: NumberFormatCustom,
            }}
            onChange={handleChange}
            value={predictedValue}
            error={touched.predictedValue && Boolean(errors.predictedValue)}
            helperText={touched.predictedValue && errors.predictedValue}
          />
        </Grid>
        </Grid>

        <Grid item xs={12} style={{marginBottom:"1rem"}}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            //toolbarButtonSize="small"
            onChange={(event) => {
              setFieldValue("description", convertToHTML(event.getCurrentContent()));
            }}
            value={description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) =>
              { 
               setFieldValue("responsibleSecretary", value || null)
               setFieldValue("responsible", null)
              }
            }
            value={responsibleSecretary}
            config={GET_SECRETARY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
            error={touched.responsibleSecretary && Boolean(errors.responsibleSecretary)}
            helperText={touched.responsibleSecretary && errors.responsibleSecretary}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            disabled={responsibleSecretary === null}
            onChange={(event, value) =>{
              (value?.id !== null || value === null ? setFieldValue("responsible", value || null):
              setFieldValue("responsible", null))
            }}
            value={responsible}
            nullMessage={"Nenhum usuário nessa secretaria!"}
            config={GET_RESPONSIBLESECRETARY(responsibleSecretary?.id)}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Responsável",
            }}
            error={touched.responsible && Boolean(errors.responsible)}
            helperText={touched.responsible && errors.responsible}
          />
        </Grid>

        </Grid>
        </DefaultModalContent>
        <DefaultModalActions style={{marginRight:"0.5rem"}}>
         <ButtonSuccess
         variant="contained"
         color="primary"
         type="submit"
         success={false}
         loading={isSubmitting}
        > Confirmar
         </ButtonSuccess>
        </DefaultModalActions>  
    </form>
  );
};
