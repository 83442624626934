import React from "react";
import {
  createStyles,
  makeStyles,
  Grid,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";

// Mapa Geral dos projetos (Executados, Não executados e Em andamento)
export const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: 5,
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 300},
    [theme.breakpoints.up("md")]: {width: "100%", height: 350},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 350},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 400},
    paddingTop: "1rem",
  }
})
);

const GET_INFO = () => {
  return {
    method: "get",
   // url: `dashboard/projectsProgress.php`,
   url: `dashboard/secretaryProgress.php`,
  };
};
 
function ViewChart({series}){
  const options = {
    chart:{
      // dropShadow: {
      //   enabled: true,
      //   top: 0,
      //   left: 0,
      //   blur: 1,
      //   opacity: 0.5
      // },
    },
    labels: ["Nao iniciados",
             "Em andamento", 
             "Em atraso", 
             "Cancelado", 
             "Concluído"],
    colors: ["#808080", 
             "#2E93fA",
             "#FFA500", 
             "#ff3330", 
             "#66DA26",], 

    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      offsetY: 0,
    },
    dataLabels: {
      enabled: true,
    },
  };
  return <ReactApexChart options={options} series={series} type="donut" width="100%" height="100%"/>
}

export default function StatusProjects() {
  const classes = useStyles();
  const [series, setSeries] = React.useState([]);
  const updateData = async () => {
  await api(GET_INFO()).then(({data}) => {
      const nao_iniciados = data.reduce((curr, index) => curr + parseFloat(index.dados.nao_iniciados,2), 0)
      const em_andamento  = data.reduce((curr, index) => curr + parseFloat(index.dados.em_andamento,2), 0)
      const em_atraso     = data.reduce((curr, index) => curr + parseFloat(index.dados.em_atraso,2), 0)
      const cancelado     = data.reduce((curr, index) => curr + parseFloat(index.dados.cancelado,2), 0)
      const concluido     = data.reduce((curr, index) => curr + parseFloat(index.dados.concluido,2), 0)

      setSeries([Number(nao_iniciados), Number(em_andamento), Number(em_atraso), Number(cancelado), Number(concluido)]);
    }).catch(e=>{
      setSeries([])
    });
  };

  React.useEffect(() => {
    updateData();
  }, []);

 return (
 <>
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> Mapa geral de projetos </Typography>
   <Divider/>
  </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart series={series}/> 
  </Grid>
 </Grid>
 </>
 );
}
