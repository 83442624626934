////////////// PROJECT ///////////////////
export const POST_INSERT_PROJECT = (body) =>{
  return {
    url: "project/insert.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const POST_UPDATE_PROJECT = (body) =>{
  return {
    url: "project/alter.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const GET_DELETE_PROJECT = (id) =>{
  return { 
    method: 'get',
    url: `project/delete.php?id=${id}`,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  }
}

//https://back.draftmanagementproject.com/api/project/description.php?id=88
export const GET_RQSTINFO_PROJECT = (id) =>{
  return { 
    method: 'get',
    url: `project/description.php?id=${id}`,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  }
}

export const GET_TABLE_PROJECT = (body) =>{
 return { 
  headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  url: `project/search.php`,
  method: 'post',
    data: body,
  }
 }  
///////////////////////  TASK ////////////////////////
export const GET_RQSTINFO_TASK = (id) =>{
  return { 
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    method: 'get',
    url: `task/description.php?id=${id}`,
   }
  }  

export const GET_RQSTFORMINFO_TASK = (id) =>{
  return { 
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    method: 'get',
    url: `task/view.php?id=${id}`,
  }
}    

export const POST_INSERT_TASK = (body) =>{
  return {
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    url:  "task/insert.php",
    method: 'post',
    data: body,
  };
}

export const POST_UPDATE_TASK = (body) =>{
  return {
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    url: "task/alter.php",
    method: 'post',
    data: body,
  };
}

export const GET_TASK_DELETE = (id) => {
  return { 
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
   method: 'get',
   url: `task/delete.php?id=${id}`,
  }
 }

//https://back.draftmanagementproject.com/api/task/list.php?id=87
export const GET_TASK_LIST = (body) => {
  return { 
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
   method: 'post',
   url: "task/search.php",
   data: body,
  }
 }
//////////// USER /////////////////
export const POST_INSERT_USER = (body) =>{
  return {
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    url: "user/insert.php",
    method: 'post',
    data: body,
  };
}

export const POST_UPDATE_USER = (body) =>{
  return {
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    url:"user/alter.php",
    method: 'post',
    data: body,
  };
}

export const POST_UPDATE_USER_MASTER_PASSWORD = (body) =>{
  return {
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    url:"user/alterpassword.php",
    method: 'post',
    data: body,
  };
}

export const GET_USER_INFOS = (token) => {
  return { 
   method: 'get',
   url: `auth/view.php`,
   headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
   },
  }
 }
//////////////// GROUP /////////////////
export const POST_INSERT_GROUP = (body) =>{
  return {
    url: "group/insert.php",
    method: 'post',
    data: body,
  };
}

export const POST_UPDATE_GROUP = (body) =>{
  return {
    url: "group/alter.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const GET_GROUP_DESCRIPTION = (id) => {
  return { 
   method: 'get',
   url: `group/description.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

export const GET_GROUP_LIST = (id) => {
  return { 
   method: 'get',
   url: `loadAxis.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

export const GET_GROUP ={
  method: 'get',
  url: 'loadGroup.php',
  headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
}

//////////////// EIXO /////////////////////
//https://back.draftmanagementproject.com/api/axis/insert.php
export const POST_INSERT_AXIS = (body) =>{
  return {
    url: "axis/insert.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const POST_UPDATE_AXIS = (body) =>{
  return {
    url:  "axis/alter.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const GET_AXIS_TABLE = () => {
  return { 
   method: 'get',
   url: `axis/listGrid.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

export const GET_AXIS_LIST = (id) => {
  return { 
   method: 'get',
   url: `loadAxis.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

export const GET_AXIS = (id) => {
  return { 
   method: 'get',
   url: `loadAxis.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

//////////////// SECRETARY /////////////// 
export const POST_INSERT_SECRETARY = (body) =>{
  return {
    url: "secretary/insert.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const POST_UPDATE_SECRETARY = (body) =>{
  return {
    url: "secretary/alter.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const GET_SECRETARY_TABLE = () => {
  return { 
   method: 'get',
   url: `secretary/listGrid.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

export const GET_SECRETARY_LIST = () => {
  return { 
   method: 'get',
   url: `secretary/load.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

export const GET_SECRETARY = () => {
  return { 
   method: 'get',
   url: `secretary/list.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }
 ////////////////////////////////////

 export const GET_LEVELUSER = () => {
  return { 
   method: 'get',
   url: `access_level/load.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

 export const GET_RESPONSIBLESECRETARY = (secretaryID) => {
  const aux = secretaryID === null? 0:secretaryID
  return { 
   method: 'get',
   url: `user/load.php?id=${aux}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

 /////////////////// USER /////////////////////////////
 export const GET_TABLE_USER = () => {
  return { 
   method: 'get',
   url: `user/list.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

 ///////////////// GUT //////////////////////
 //https://back.draftmanagementproject.com/api/gut/insert.php
 export const POST_INSERT_GUT = (body) =>{
  return {
    url: "gut/insert.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const POST_UPDATE_GUT = (body) =>{
  return {
    url: "gut/alter.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const GET_RQST_GUT = (id) => {
  return { 
   method: 'get',
   url: `gut/view.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 } 

//https://back.draftmanagementproject.com/api/gut/list.php?id=87
export const GET_LIST_GUT = (id) => {
  return { 
   method: 'get',
   url: `gut/list.php?id=${id}`,
 
  }
 } 

 export const GET_DELETE_GUT = (id) => {
  return { 
   method: 'get',
   url: `gut/delete.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 } 
 
 export const GET_GRAVITY = (id) => {
  return { 
   method: 'get',
   url: 'loadGutGravity.php',
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 } 

 export const GET_URGENCY = (id) => {
  return { 
   method: 'get',
   url: 'loadGutUrgency.php',
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }

 export const GET_TREND = (id) => {
  return { 
   method: 'get',
   url:  'loadGutTrend.php',
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }
/////////////////////////////////////////// 
//////////// 5w2H ////////////////////////
export const GET_LIST_RISK = (id) => {
  return { 
   method: 'get',
   url: `gut/risklist.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 } 

export const GET_LIST_5W2H = (id) => {
  return { 
   method: 'get',
   url: `5sw2h/list.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 } 

 export const GET_DELETE_5W2H = (id) => {
  return { 
   method: 'get',
   url: `5sw2h/delete.php?id=${id}`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 } 

export const POST_INSERT_5W2H = (body) =>{
  return {
    url:"5sw2h/insert.php",
    method: 'post',
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const POST_REQUEST_5W2H = (body) =>{
  return {
    url: "5sw2h/info.php",
    method: "POST",
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

export const POST_UPDATE_5W2H = (body) =>{
  return {
    url: "5sw2h/alter.php",
    method: "POST",
    data: body,
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}

//////////////PROFILE ////////////////////
export const POST_UPDATE_PROFILE_PASSWORD = (body) =>{
  return {
    url:  "user/alter.php",
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    data: body,
  };
}

//////////////////////////////////////////
/////https://back.draftmanagementproject.com/api/loadPriority.php
export const GET_PRIORITY_TASK = () => {
  return { 
   method: 'get',
   url: `loadPriority.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 } 

 export const GET_STATUS_TASK = () => {
  return { 
   method: 'get',
   url: `loadStatusTask.php`,
   headers: {
    'Content-Type': 'application/json',
//    'authorization': `Bearer ${getToken()}`,
   },
  }
 }
 
export const TOKEN_RQST = (body) => {
  return {
    url: "auth/login.php",
    method: "post",
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
    data: body,
  };
};
 
export function TOKEN_VALIDATE_POST(token) {
  return {
    url:  "auth/auth.php",
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
//      'authorization': `Bearer ${getToken()}`,
     },
  };
}
