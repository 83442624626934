import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Box,
    IconButton,
    Button,
    Icon,
  } from "@material-ui/core";

  import {Menu, WebAsset} from "@material-ui/icons";

  const drawerWidth = 240;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    menuButton: {
      marginRight: 36,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    toolbar: {
        width: drawerWidth,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1000,
        padding: "0.35rem",
        // necessary for content to be below app bar
        //...theme.mixins.toolbar,
      },
    selectedPage: {
      boxShadow:
        "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
      borderRadius: "5px",
      borderLeft: "5px solid #CCCCCC",
    },
    page: {
      borderLeft: "5px solid #0000",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));

const ShowMenu = ({listMenu, open, setOpen}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    
    const location = useLocation();
    const actualLocation = location.pathname;

    const isSelected = (route) => actualLocation === route;
   

    return (
        <Drawer
        className={classes.drawer}
        open={open}
        anchor="left"
        onClose={setOpen}
       >
        <Box className={classes.toolbar}>

        <IconButton onClick={setOpen}>
         <Menu/>
        </IconButton>

        <Button
          variant="contained"
          color="default"
          onClick={() => navigate("/projects")}
          style={{borderRadius: 15, marginRight: 10}}
        >
          <WebAsset className={classes.extendedIcon} />
           Projetos 
        </Button>

        </Box>
        <Divider />
           <List style={{alignItems: "center"}}>
             {listMenu.map((listMenu, index) => (
               <Link key={index} to={listMenu.to} passhref="true">
                 <ListItem
                   style={{paddingBottom:"0.8rem", paddingTop:'0.8rem'}}
                   onClick={() => setOpen()}
                   button
                   component="a"
                   className={
                     isSelected(listMenu.to)
                       ? classes.selectedPage
                       : classes.page
                   }
                 >
                    <ListItemIcon >
                     <Icon color={isSelected(listMenu.to) ?"primary":"inherit"} >
                      {listMenu.icon}
                     </Icon>
                    </ListItemIcon>
                  <ListItemText primary={listMenu.name} />
                 </ListItem>
               </Link>
             ))}
           </List>
       </Drawer>
    )
}

export default ShowMenu
