import React from "react";

import {
  Box,
  Typography,
  createStyles,
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Card,
  CardContent,
  ListItemText,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import api from "./../../../services/Api";
import DefaultModal, { DefaultModalContent } from "../../../Components/DefaultModal";

export const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      margin: "10px auto",
      alignContent: "start",
      alignItems: "start",
      [theme.breakpoints.up("xs")]: { width: 390, height: "100%"},   
      [theme.breakpoints.up("sm")]: { width: 490, height: "100%"},    
      [theme.breakpoints.up("md")]: { width: 740, height: "100%"},  
      [theme.breakpoints.up("lg")]: { width: 840, height: "100%"},  
      [theme.breakpoints.up("xl")]: { width: 940, height: "100%"},  
    },
    description: {
      minWidth: 500,
      display: 'grid',
    },
    info: {
      minWidth: 500,
      paddingLeft: 30,
    },
    divider: {
      margin: theme.spacing(0, 2, 0, 2),
    },
  })
);

export const GET_RQSTINFO_PROJECT = (id) =>{
  return { 
    method: 'get',
    url: `projects_old/description.php?id=${id}`,
    headers: {
      'Content-Type': 'application/json',
     },
  }
}

export default function View({ rowId, setRowId }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState(null);
  const updateData = React.useCallback(async () => {
    await api(GET_RQSTINFO_PROJECT(rowId))
      .then((resp) => {
         const data = resp.data[0];
         setData({
            name: data.name,
            responsible: data.responsible,
            responsibleSecretary: data.responsibleSecretary,
            group: data.groupName,
            axis: data.axisName,
            status: data.Status,
            resume: data.resume,
            description: data.description,
            predictedValue: data.predictedValue,
            executedValue: data.executeValue,
            initDate: data.initDate,
            deadLine: data.deadLine,
         });
      })
      .catch((e) => {
        setData(null);
      });
  },[rowId]);

  React.useEffect(() => {
    updateData()
  }, []);

  const handleClose = () => {
    setOpen(false);
    setRowId(null);
  };

  return (
    <DefaultModal
     open={open}
     handleClose={handleClose}
     loading={data === null}
     title={data?.name? data?.name :''}
    >
    <DefaultModalContent dividers>
     <Grid container className={classes.main}>
      <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
       <Grid item xs={12} style={{marginBottom: 30}}> 
        <CardResume resume={data?.resume} /> 
       </Grid>
       <Grid item xs={12} style={{marginBottom: 5}}> 
        <CardDescription description={data?.description} /> 
       </Grid>
       </Grid>
       <Grid item xs={12} sm={12} md={7} lg={7} xl={7} >
        <ShowInfos data={data} />
       </Grid>
      </Grid>
     </DefaultModalContent>
    </DefaultModal>
  );
}

const CardResume = ({ resume }) => {
  return (
    <Card style={{ width: "100%" }}>
      <Typography
        variant="h6"
        gutterBottom
        style={{ margin: "5px 0px 5px 10px" }}
      >
        Resumo
      </Typography>
      <Divider />
      <CardContent>
        <Typography variant="subtitle1" gutterBottom paragraph={true}>
          {resume}
        </Typography>
      </CardContent>
    </Card>
  );
};
  
  const CardDescription = ({description}) => {
    return (
      <Card style={{ width: "100%", minHeight: 200 }}>
      <Typography variant="h6" style={{ margin: "5px 0px 5px 10px" }}>
        Descrição
      </Typography>
        <Divider />
        <CardContent>
          <Typography variant="subtitle1" gutterBottom paragraph={true}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    );
  };

const ShowInfos = ({ data }) => {
  const {
    responsibleSecretary,
    responsible,
    status,
    axis,
    group,
    predictedValue,
    executedValue,
    initDate,
    deadLine,
  } = data;

  const dataInfo = [
    {
      icon: <Icons.Person />,
      text: "Responsável: ",
      data: <Typography>{responsible}</Typography>,
    },
    {
      icon: <Icons.AccountBalance />,
      text: "Secretaria responsável: ",
      data: responsibleSecretary,
    },
    {
      icon: <Icons.GroupWork />,
      text:"Portifólio: ",
      data: group,
    },
    {
      icon: <Icons.ScatterPlot/>,
      text: "Programa: ",
      data:  axis,
    },
    {
      icon: <Icons.LocalAtm />,
      text: "Valor previsto: ",
      data: predictedValue,
    },
    {
      icon: <Icons.AttachMoney />,
      text: "Valor executado: ",
      data: executedValue,
    },
    {
      icon: <Icons.Event />,
      text: "Data inicial: ",
      data: initDate,
    },
    {
      icon: <Icons.EventAvailable />,
      text: "Data limite: ",
      data: deadLine,
    },
    {
      icon: <Icons.EventAvailable />,
      text: "Status: ",
      data: status,
    },
  ];

  return (
    <List dense disablePadding >
    {dataInfo.filter((r) => r.data !== null).map((value) => (
     <ListItem alignItems="start" key={value.text}>
     <ListItemIcon >{value.icon}</ListItemIcon>
     <ListItemText
     style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
     primary={<Typography variant="h6" component="span">{value.text}</Typography>}
     secondary={
      <Typography
        component="span"
        style={{marginLeft: "0.5rem" }}
        color="textSecondary"
      >
      {value.data} 
      </Typography>
    }/>
    </ListItem>
  ))}
</List>
  );
};

