import React from "react";
import {
  Typography,
  makeStyles,  
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";
import { ShowPercent } from "../../../../Components/ShowInfos";
import { FormatRealNumber } from "../../../../Components/Helper/FormatValues";
import FormLoadingComponent from "../../../../Components/FormLoading";
import CardStyle from "../../../../Components/CardStyle";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding:"0.2rem",
    [theme.breakpoints.up("xs")]: {height: "125px"},
    [theme.breakpoints.up("sm")]: {height: "130px"},
    [theme.breakpoints.up("md")]: {height: "130px"},
    [theme.breakpoints.up("lg")]: {height: "130px"},
    [theme.breakpoints.up("xl")]: {height: "110px"},  
  },
  title:{
    fontWeight: "bold",
    fontFamily: "sans-serif",
    [theme.breakpoints.up("xs")]: { fontSize: "1.3rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.4rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.2rem"},  
  },
  subtitle:{
    fontWeight: "normal",
    fontWeight: "bold",
    [theme.breakpoints.up("xs")]: { fontSize: "1.9rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.9rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.7rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.7rem"},  
  },
}));

export const CardSecretary = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
     primary={<Typography className={classes.title} component="h1" color="textPrimary">
               Secretarias
              </Typography>}
    
     value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
             {value !== undefined ? value : <Skeleton variant="text" width="2rem"/>}
            </Typography>}
            
     color="linear-gradient(60deg,rgba(3,155,229,1) 20%,#00CED1 100%)"
     customIcon={Icons.AccountBalance}
    />
      // <Card elevation={1} className={classes.root}>
      //   <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
      //    avatar={ 
      //    <Avatar className={classes.large} style={{backgroundColor: "white"}} >
      //      <Icons.AccountBalance variant="h4" className={classes.avatar}/>
      //    </Avatar>}
      //    title={
      //      <Typography className={classes.title} component="h1" color="textPrimary">
      //       Secretarias
      //      </Typography>}
      //     subheader={
      //     <Typography className={classes.subtitle} component="h2" color="textSecondary">
      //       {value !== undefined ? value : <FormLoadingComponent />}
      //     </Typography>}
      //   />  
      // </Card>
  );
};

export const CardTask = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Etapas
             </Typography>}
 
    value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
            {value !== undefined ? value : <Skeleton variant="text" width="2rem"/>}
           </Typography>}
    color="linear-gradient(60deg, rgba(245,0,87,1) 0%, rgba(255,138,128,1) 100%)"
    customIcon={Icons.Assignment}
   />
      // <Card elevation={1} className={classes.root}>
      //   <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
      //    avatar={ 
      //    <Avatar className={classes.large} style={{backgroundColor: "white"}} >
      //      <Icons.Assignment variant="h4" className={classes.avatar}/>
      //    </Avatar>}
      //    title={
      //     <Typography className={classes.title} component="h1" color="textPrimary">
      //       etapas
      //      </Typography>}
      //     subheader={
      //       <Typography className={classes.subtitle} component="h2" color="textSecondary">
      //       {value !== undefined ? value : <FormLoadingComponent />}
      //     </Typography>}
      //   />  
      // </Card>
  );
};

export const CardProgress = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Progresso
             </Typography>}
 
    value={<Typography className={classes.subtitle} style={{width: "40%", justifyContent: "end"}} component="h2" color="textSecondary">
            {value !== undefined ? <ShowPercent value={Number(value)} /> : <Skeleton variant="text" width="4rem"/>}
           </Typography>}
    color="linear-gradient(60deg, rgba(251,140,0,1) 0%, rgba(255,202,41,1) 100%)"
    customIcon={Icons.DonutLarge}
   />
      // <Card elevation={1} className={classes.root}>
      //   <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
      //    avatar={ 
      //    <Avatar className={classes.large} style={{backgroundColor: "white"}} >
      //      <Icons.DonutLarge variant="h4" className={classes.avatar}/>
      //    </Avatar>}
      //    title={
      //     <Typography className={classes.title} component="h1" color="textPrimary">
      //       Progresso
      //      </Typography>}
      //     subheader={
      //       <Typography className={classes.subtitle} component="h2" color="textSecondary">
      //        {value !== undefined ? <ShowPercent value={Number(value)} /> : <FormLoadingComponent/>}
      //     </Typography>}
      //   />  
      // </Card>
  );
};

export const CardMoney = ({value}) => {
  const classes = useStyles();
  return (
    <CardStyle
    primary={<Typography className={classes.title} component="h1" color="textPrimary">
              Valor executado atual
             </Typography>}
 
    value={<Typography className={classes.subtitle} component="h2" color="textSecondary">
            {value !== undefined ? FormatRealNumber(value) : <Skeleton variant="text" width="2rem"/>}
           </Typography>}
    color="linear-gradient(60deg, rgba(67,160,71,1) 0%, #4CC417 100%)"
    customIcon={Icons.AttachMoney}
   />
      // <Card elevation={1} className={classes.root}>
      //   <CardHeader style={{justifyContent:"space-between", display: "flex", alignContent: "start", alignItems: "start", textAlign:"center"}}
      //    avatar={ 
      //    <Avatar className={classes.large} style={{backgroundColor: "white"}} >
      //      <Icons.AttachMoney variant="h4" className={classes.avatar}/>
      //    </Avatar>}
      //    title={
      //     <Typography className={classes.title} component="h1" color="textPrimary">
      //       Valor executado atual
      //      </Typography>}
      //     subheader={
      //       <Typography className={classes.subtitle} component="h2" color="textSecondary">
      //       {value !== undefined ? FormatRealNumber(value) : <FormLoadingComponent/> }
      //     </Typography>}
      //   />  
      // </Card>
  );
};

// export const CardMoney = ({ value }) => {
//   const classes = useStyles();
//   return (
//     <>
//       <Card elevation={2} className={classes.root}>
//         <CardContent>
//           <Grid
//             container
//             direction="row"
//             justify="space-between"
//             alignItems="center"
//           >
//             <Grid item xs={4}>
//               <AttachMoney style={{ borderRadius: 10, fontSize: 50 }} />
//             </Grid>
            
//             <Grid item xs={8}>
//               <Typography
//                 style={{
//                  // fontSize: 20,
//                   fontWeight: "bold",
//                   textAlign: "center",
//                 }}
//                 variant="h5"
//               >
//                 Valor executado atual
//               </Typography>
//               <Typography
//                 style={{
//                   //    fontSize: 29,
//                   fontWeight: "bold",
//                   color: "#CCC",
//                   textAlign: "center",
//                 }}
//                 variant="h5"
//               >
//                 {value !== undefined ? (
//                   <> {FormatRealNumber(value)} </>
//                 ) : (
//                   <FormLoadingComponent />
//                 )}
//               </Typography>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>
//     </>
//   );
// };