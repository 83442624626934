import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';

import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonSuccess } from '../../../../Components/ButtonSuccess';
import { Box, Divider, Typography } from '@material-ui/core';
import { SelectCustom } from '../../../../Components/SelectsCustom';

const useStyles = makeStyles({
  root:{
    margin: "1rem auto",
    minWidth: 650,
    maxWidth: 1350,
  },
  table: {
    width: "100%",
  },
});

function createData(situation, id) {
  return { situation, id};
}

const initialValues = {
   responsible:{
    Canceled: true,
    Concluded: true,
    ConcludedLate: true,
    Late: true,
    Progress: true,
    ProgressLate: true,
    Suspended: true,
   },
   involved:{
    Canceled: true,
    Concluded: true,
    ConcludedLate: true,
    Late: true,
    Progress: true,
    ProgressLate: true,
    Suspended: true,
   },
   secretary:{
    Canceled: true,
    Concluded: true,
    ConcludedLate: true,
    Late: true,
    Progress: true,
    ProgressLate: true,
    Suspended: true,
   },
   email:{
    Canceled: true,
    Concluded: false,
    ConcludedLate: true,
    Late: false,
    Progress: true,
    ProgressLate: false,
    Suspended: true,
   },
};

const validationSchema = Yup.object({
 
});

export function PanelUser() {
  const classes = useStyles();

  const submit = async(values, { setSubmitting })=>{
    setSubmitting(false); 
  }

  const {errors, touched, values, isSubmitting, setFieldValue, handleSubmit, handleChange} =  useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: submit,
    });

      const rows = [
        createData('Gestor'     , "manager"),
        createData('Colaborador', "collaborator"),
        createData('Prefeitura' , "cityHall"),   
      ];    

  return (
  <form noValidate onSubmit={handleSubmit}>
    <TableContainer component={Paper} className={classes.root} elevation={1}>
      <Box>
      <Typography variant="h6"  gutterBottom style={{margin: "1rem"}}> Configure as notificações referente aos usuários</Typography>
      </Box>
      <Divider/>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Nível de acesso</TableCell>
            <TableCell align="center">Dias ausente</TableCell>
            <TableCell align="right">Notificar administrador</TableCell>
            <TableCell align="right">Notificar secretaria</TableCell>
            <TableCell align="right">Notificar usuário</TableCell>
            <TableCell align="right">Enviar email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody> 
          {rows.map((row) => (
            <TableRow key={row.situation}>
              <TableCell component="th" scope="row" > {row.situation} </TableCell>

              <TableCell align="right"> 
              <SelectCustom
              id="days"
              name="days"
              onChange={(e, value) => {
                setFieldValue("days", value);
              }}
              value={values.days}
              options={[
                { id: 0, name: "15 Dias" },
                { id: 1, name: "30 Dias" },
                { id: 2, name: "45 Dias" },
                { id: 3, name: "60 Dias" },
                { id: 4, name: "75 Dias" },
                { id: 5, name: "90 Dias" },
              ]}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                margin: "normal",
                variant: "outlined",
                label: "Dias",
              }}
            />
             </TableCell>  

              <TableCell align="right">{
              <Checkbox  id={`responsible.${row.id}`} 
                         name={`responsible.${row.id}`} 
                         checked={values.responsible[`${row.id}`]}
                         onChange={(e, value) =>  setFieldValue(`responsible.${row.id}`, value)}
                         />}
             </TableCell>  

 
             <TableCell align="right">{
              <Checkbox  id={`involved.${row.id}`} 
                         name={`involved.${row.id}`} 
                         checked={values.involved[`${row.id}`]}
                         onChange={(e, value) =>  setFieldValue(`involved.${row.id}`, value)}
                         />}
             </TableCell>  


             <TableCell align="right">{
              <Checkbox  id={`secretary.${row.id}`} 
                         name={`secretary.${row.id}`} 
                         checked={values.secretary[`${row.id}`]}
                         onChange={(e, value) =>  setFieldValue(`secretary.${row.id}`, value)}
                         />}
             </TableCell>  
              <TableCell align="right">{<Switch 
                         style={{margin: 0, paddingRight:0, width: "auto"}}
                         id={`email.${row.id}`} 
                         name={`email.${row.id}`} 
                         checked={values.email[`${row.id}`]}
                         value={values.email[`${row.id}`]}
                         onChange={(e, value) =>  setFieldValue(`email.${row.id}`, value)}/>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, marginTop: "0.5rem", marginRight: "0.5rem"}}>
       <ButtonSuccess
        variant="contained"
        color="primary"
        type="submit"
        success={false}
        loading={isSubmitting}> 
         Confirmar
       </ButtonSuccess>
      </Box>
    </TableContainer>
  </form>
  );
}
