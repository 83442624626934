import React from 'react';
import { UserContext } from './../../Context/UserContext';
import { Route, Navigate } from 'react-router-dom';
import {isAuthenticated} from "../../services/auth"

const ProtectedRoute = (props) => {
  const login = isAuthenticated()
  
  if (login) return <Route {...props}/>;
  else if (!login) return <Navigate to="/auth"/>;
  else return null;
};

export default ProtectedRoute;
