import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import {Container, Paper, Tabs, Tab, Box } from "@material-ui/core";

import TabGut from "./TabGut";
import Tab5W2H from "./Tab5W2H";
import { StatisticsRiskPanel } from "./StatisticsPanel";
import { ReviewPanel } from "./Review";

import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import { ViewProjectContext } from "..";

const HorizontalColoredTab = withStyles({
  indicator: {
    background: "#0078B2",
    zIndex: 1,
  },
})(Tabs);

const ColoredTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: "none",
      "&:hover": {
        opacity: 1,
      },
      "&$selected": {
        fontWeight: theme.typography.fontWeightMedium,
        color: "black",
        zIndex: 2,
      },
    },
    selected: {},
  })
)((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
     {value === index && children}  
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(2),
    border: `1px solid  ${theme.palette.divider}`,
    borderTop: 0,
    borderRadius: "0px 0px 10px 10px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
   // minHeight: 740,
  },
  rootHorizontal: {
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  horizontalTabDivider: {
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px 10px 0px 0px",
  },
}));

export default function RiskManagement() {
  const { infosData } = React.useContext(ViewProjectContext);
  const { name } = infosData
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => setValue(newValue);
  
  const getActiveTab = React.useCallback((value) => { 
    switch (value) {
      case 0: return <TabPanel value={value} index={0}> <TabGut     /> </TabPanel>;
     // case 1: return <TabPanel value={value} index={1}> <ReviewPanel/> </TabPanel>;
      case 1: return <TabPanel value={value} index={1}> <Tab5W2H    /> </TabPanel>;
      //case 3: return <TabPanel value={value} index={3}> <StatisticsRiskPanel/> </TabPanel>;
      default: return null;
    }
  }, [value]);

  return (
    <Container className={classes.root} maxWidth="xl">
      <CurrentPageInfo primary="Gestão de risco" secondary={name}/>
      <Box className={classes.rootHorizontal}>
        <HorizontalColoredTab
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Risk Tabs"
          className={classes.horizontalTabDivider}>
          <ColoredTab label="Priorização"   {...a11yProps(0)} />
          {/* <ColoredTab label="Análise"       {...a11yProps(1)} /> */}
          <ColoredTab label="Plano de ação" {...a11yProps(1)} />
          {/* <ColoredTab label="Estatísticas"  {...a11yProps(3)} /> */}
        </HorizontalColoredTab>
      </Box>
      <Paper className={classes.paper} elevation={0}>
       {getActiveTab(value)}
      </Paper>
    </Container>
  );
}
