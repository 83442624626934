import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import api from "../../../services/Api";
import { UserContext } from "../../../Context/UserContext";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";

//user/alterpassword.php

export const POST_ADM_REFRESH_USER_PASSWORD = (body) => {
  return {
    url: "user/alterpassword.php",
    method: "post",
    data: body,
  };
};

const initialValues = {
  newPassword: "",
  confirmNewPassword: "",
};

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("Obrigatório")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "A senha deve conter pelo menos 8 caracteres, uma maiúscula, um número e um caractere especial"
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "A senhas não correspondem!")
    .required("Obrigatório"),
});

const NewPassword = ({ open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = React.useContext(UserContext);

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      id: userInfo.id,
      password: values.newPassword,
    };
    api(POST_ADM_REFRESH_USER_PASSWORD(buffer))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          enqueueSnackbar("Senha alterada !!!", { variant: "success" });
          setSubmitting(false);
          setOpen(false);
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Error ao alterar senha!!!`, { variant: "error" });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        enqueueSnackbar(`Error ao alterar senha ${e.message} !!!`, {
          variant: "error",
        });
      });
  };
  return (
    <DefaultModal open={open} handleClose={()=>setOpen(false)} title="Alterar senha">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms setOpen={setOpen} {...props} />}
      </Formik>
    </DefaultModal>
  );
};

export default NewPassword;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "grid",
      // width: 400,
      [theme.breakpoints.up("xs")]: { width: 250, padding: theme.spacing(0.5, 1, 0, 1), },
      [theme.breakpoints.up("sm")]: { width: 300, padding: theme.spacing(0.5, 1, 0, 1), },
      [theme.breakpoints.up("md")]: { width: 300, padding: theme.spacing(0.5, 1, 0, 1), },
      [theme.breakpoints.up("lg")]: { width: 300, padding: theme.spacing(0.5, 1, 0, 1), },
      [theme.breakpoints.up("xl")]: { width: 350, padding: theme.spacing(0.5, 1, 0, 1), },
    },
  })
);

const Forms = (props) => {
  const classes = useStyles();
  const [show, setShow] = React.useState({
    atual: false,
    new: false,
    confirm: false,
  });
  const {
    values: { newPassword, confirmNewPassword },
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="none"
            size="small"
            fullWidth
            id="newPassword"
            type={show.new ? "text" : "password"}
            label="Nova senha"
            name="newPassword"
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="nova senha"
                    onClick={() =>
                      setShow((value) => ({...value, new: !value.new,}))
                    }
                  >
                    {show.new ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={newPassword}
            error={touched.newPassword && Boolean(errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="none"
            size="small"
            fullWidth
            name="confirmNewPassword"
            label="Confirme a nova senha"
            type={show.confirm ? "text" : "password"}
            id="confirmNewPassword"
            inputProps={{ autoComplete: "off" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="confirmNewPassword"
                    onClick={() =>
                      setShow((value) => ({
                        ...value,
                        confirm: !value.confirm,
                      }))
                    }
                  >
                    {show.confirm ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            autoComplete="off"
            onChange={handleChange}
            value={confirmNewPassword}
            error={touched.confirmNewPassword && Boolean(errors.confirmNewPassword)}
            helperText={touched.confirmNewPassword && errors.confirmNewPassword}
          />
        </Grid>
        </Grid>
        </DefaultModalContent>

       <DefaultModalActions style={{marginRight: "0.5rem"}}>
            <ButtonSuccess
              style={{width: 100}}
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              disabled={!dirty || isSubmitting}
            >
              Alterar
            </ButtonSuccess>
      </DefaultModalActions>
   
    </form>
  );
};
