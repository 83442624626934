import React from "react";

import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Checkbox,
  FormGroup,
  makeStyles, 
  createStyles,
  Box,
  Drawer
} from "@material-ui/core";

import {
  GET_AXIS,
  GET_GROUP,
  GET_RESPONSIBLESECRETARY,
  GET_SECRETARY,
} from "../../services/options";
import { SelectApi } from "../../Components/SelectsApi";
import { SelectCustom } from "../../Components/SelectsCustom";
import { Formik } from "formik";

const initial ={
  name: "",
  group: null,
  axis: null,
  responsible: null,
  responsibleSecretary: null,
  status: null,
  type: {
    routine: false,
    governmentPlan: false,
  },
}
 
export default function FilterProject({drawerOpen, setDrawerOpen, search, setSearch }) {
  const [init, setInit] = React.useState(initial)
 
  const submit = async (values, { setSubmitting }) => {

    const buffer ={
      name:        values.name, 
      group:       values.group !== null?values.group?.id:0,
      axis:        values.axis  !== null?values.axis?.id:0,

      status              : values.status === null?null:values.status.id,
      responsible         : values.responsible === null?0:values.responsible.id,
      responsibleSecretary: values.responsibleSecretary === null?0:values.responsibleSecretary.id,
      
      type:   values.type}
      setInit(values)
      setSearch(buffer)
      setSubmitting(false);
      setDrawerOpen(false);
  }

  return (
    <Drawer
      open={drawerOpen}
      anchor="right"
      onClose={() => setDrawerOpen(!drawerOpen)}
    >
      <Formik
        initialValues={init}
        //validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => <Forms setInit={setInit} {...props} />}
      </Formik>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
     // width: 450,
      margin: "20px auto",
    //  padding: theme.spacing(2, 4, 2, 4),
      [theme.breakpoints.up("xs")]: {width: 250, padding: theme.spacing(0.5, 1, 0, 1),},  
      [theme.breakpoints.up("sm")]: {width: 360, padding: theme.spacing(0.5, 1.5, 0, 1.5),},   
      [theme.breakpoints.up("md")]: {width: 400, padding: theme.spacing(0.5, 2, 0, 2),},  
      [theme.breakpoints.up("lg")]: {width: 450, padding: theme.spacing(0.5, 2.5, 0, 2.5),},  
      [theme.breakpoints.up("xl")]: {width: 500, padding: theme.spacing(0.5, 3, 0, 3),},  
    },

    submitButton: {
      marginTop: theme.spacing(3),
      minWidth: "12rem",
    },
  })
);

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    handleReset,
    setInit,
    resetForm,
    dirty,
  } = props;

  const resetValues = async () =>{
  await setInit(initial)
  await resetForm({values:initial})
  await handleSubmit()
  }

  React.useEffect(() => {
    if (!values.type.governmentPlan) {
      setFieldValue("group", null);
      setFieldValue("axis", null);
    }
  }, [values.type.governmentPlan]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container className={classes.main}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h5">Filtrar projetos por: </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            id="name"
            label="Projeto"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
          />
        </Grid>

        <Grid item xs={6} style={{ marginTop: "20px" }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo de projeto</FormLabel>
            <FormGroup>
              <FormControlLabel
                label="Rotina"
                control={
                  <Checkbox
                    id="type.routine"
                    name="type.routine"
                    checked={values.type.routine}
                    onChange={handleChange}
                  />
                }
              />
              <FormControlLabel
                label="Plano de governo"
                control={
                  <Checkbox
                    id="type.governmentPlan"
                    name="type.governmentPlan"
                    checked={values.type.governmentPlan}
                    onChange={handleChange}
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6} style={{ display: "grid", marginTop: 2 }}>
          <Grid item xs={12} zeroMinWidth>
            <SelectApi
              fullWidth
              disabled={Boolean(!values.type.governmentPlan)}
              id="group"
              name="group"
              onChange={(e, value) => {
                if (values.group?.id !== value?.id) setFieldValue("axis", null);
                setFieldValue("group", value);
              }}
              value={values.group}
              config={GET_GROUP}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                margin: "normal",
                variant: "outlined",
                label: "Portifólio",
              }}
            />
          </Grid>

          <Grid item xs={12} zeroMinWidth>
            <SelectApi
              fullWidth
              disabled={Boolean(
                values.group === null || !values.type.governmentPlan
              )}
              //       multiple={true}
              //      limitTags={1}
              id="axis"
              name="axis"
              onChange={(e, value) => setFieldValue("axis", value)}
              value={values.axis}
              config={GET_AXIS(values.group?.id)}
              textFieldProps={{
                label: "Programa",
                fullWidth: true,
                size: "small",
                margin: "normal",
                variant: "outlined",
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) => {
              setFieldValue("responsibleSecretary", value);
            }}
            value={values.responsibleSecretary}
            config={GET_SECRETARY()}
            //  multiple={true}
            //  limitTags={1}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            onChange={(event, value) => {
              setFieldValue("responsible", value);
            }}
            value={values.responsible}
            config={GET_RESPONSIBLESECRETARY(0)}
            //  multiple={true}
            // limitTags={3}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Responsável",
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ maxWidth: "50%" }}>
          <SelectCustom
            id="status"
            name="status"
            onChange={(e, value) => {
              setFieldValue("status", value);
            }}
            value={values.status}
            //   multiple={true}
            //  limitTags={1}
            options={[
              { id: 0, name: "Não iniciado" },
              { id: 1, name: "Em andamento" },
              { id: 2, name: "Em atraso" },
              { id: 7, name: "Suspenso" },
              { id: 3, name: "Cancelado" },
              { id: 4, name: "Concluído" },
              { id: 5, name: "Em andamento com atraso" },
              { id: 6, name: "Concluído com atraso" },

            ]}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Situação",
            }}
          />
        </Grid>

        <Grid item xs={12}>
        <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, marginTop: "1rem"}}>
            <Button
              style={{ marginRight: "1rem", width: 100 }}
              variant="contained"
              color="primary"
              onClick={resetValues}
              disabled={isSubmitting}
            >
              Reiniciar
            </Button>

            <Button
             style={{width: 100 }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Filtrar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
