import React from 'react'
import * as Icons from "@material-ui/icons";
import { DialogActions,   List, ListItem, Typography, Box, ListItemIcon } from '@material-ui/core'
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from "../../../../Components/DefaultModal"
import { ViewTextEditor } from '../../../../Components/Editor';

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
   // alignContent: "center",
  //  alignItems: "center",
    padding: 0,
    margin:"0px auto",
    [theme.breakpoints.up("xs")]: { minWidth: 380 },
    [theme.breakpoints.up("sm")]: { minWidth: 400},
    [theme.breakpoints.up("md")]: { minWidth: 400 },
    [theme.breakpoints.up("lg")]: { minWidth: 400 },
    [theme.breakpoints.up("xl")]: { minWidth: 450 },
  },
})
);
 
const ViewModal = ({data, open, handleClose}) => {
  const classes = useStyles();

  const dataInfo = [
    {
      icon: <Icons.Person />,
      text: "Usuário: ",
      data: data?.name,
    },
    {
      icon: <Icons.AccountBalance />,
      text: "Secretaria: ",
      data: data?.secretary,
    },
    {
      icon: <Icons.Event />,
      text: "Data: ",
      data: data?.date,
    },
    {
      icon: <Icons.CheckCircle />,
      text: "Atividade: ",
      data: data?.action,
    },
    {
      icon: <Icons.Book/>,
      text: "Descrição: ",
      data: data?.justification ,
    },
  ]


  return  (
  <DefaultModal open={open} handleClose={handleClose} title="Atividade" maxWidth="md">
    <DefaultModalContent dividers className={classes.root}> 
    <List dense>
        {dataInfo && dataInfo.filter(r=> r.data !== null).map((value) => (
          
          <ListItem key={value.text}>
           <ListItemIcon>{value.icon}</ListItemIcon>
            <Box style={{display: "flex", alignItems: "center", alignContent: "center", wrap: "nowrap"}}>
            
             <Typography variant="h6" component="span" style={{ fontSize: 16 }}>
              {value.text}
             </Typography>
                <Box style={{ margin: "0px 0px 0px 10px", }}>
                 <ViewTextEditor value={value.data}/>  
                </Box>
             </Box>
          </ListItem>
        ))}
      </List>
    </DefaultModalContent>
  </DefaultModal>  
  )
}

export default ViewModal
