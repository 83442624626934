import React from "react";

import {
  Grid,
  createStyles,
  makeStyles, 
} from "@material-ui/core";
 
import useAxios from "../../../../Context/Hooks/useAxios";
import ReadTable from "./Table";
import DefaultModal, { DefaultModalContent } from "../../../../Components/DefaultModal";
 
const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    minHeight: 500,
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
  },
}) 
);
 
//user/accessLog.php?id=ID DO USUARIO
export const GET_USERFORM_INFO = (id) => {
  return {
    method: "get",
    url: `user/accessLog.php?id=${id}`,
  };
};

export const ViewLog = ({open, setOpen, userId}) => {
  const classes = useStyles();
  const { buffer, loading, error, request } = useAxios();

  const getData = async () => {
    await request(GET_USERFORM_INFO(userId))
  };

  React.useEffect(() => {
    if (userId !== null && open) getData();
  }, [open]);

  return (
    <>
      <DefaultModal
        loading={loading}
        open={open}
        handleClose={()=>setOpen(false)}
        title={`Histórico do usuário`}>
      <DefaultModalContent>     
      <Grid container className={classes.root}>
       <Grid item xs={12}>
       <ReadTable           
         data={buffer}
         error={error}
         load={loading}/>
        </Grid>
       </Grid>
      </DefaultModalContent>
      </DefaultModal>
    </>
  );
};

 