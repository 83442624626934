import React from "react";
import { createStyles, makeStyles, Grid, Paper } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: theme.spacing(2, 0, 0, 0),
      border: `2px solid  ${theme.palette.divider}`,
      borderRadius: "0.6rem",
      boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    },
    title: {
      maxHeight: 40,
      padding: "0.5rem 0px",
      margin: 0,
    },
    graphic: {
      [theme.breakpoints.up("xs")]: { width: "100%", height: 250 },
      [theme.breakpoints.up("sm")]: { width: "100%", height: 300 },
      [theme.breakpoints.up("md")]: { width: "100%", height: 300 },
      [theme.breakpoints.up("lg")]: { width: "100%", height: 300 },
      [theme.breakpoints.up("xl")]: { width: "100%", height: 350 },
      margingTop: "0.5rem",
    },
    viewgraphic: {
      [theme.breakpoints.up("xs")]: { width: "100%", height: 100 },
      [theme.breakpoints.up("sm")]: { width: "100%", height: 150 },
      [theme.breakpoints.up("md")]: { width: "100%", height: 150 },
      [theme.breakpoints.up("lg")]: { width: "100%", height: 150 },
      [theme.breakpoints.up("xl")]: { width: "100%", height: 180 },
      margingTop: "0.5rem",
    },
  })
);

const GET_INFO = (id) => {
  return {
    method: "get",
    url: `dashboard/physicalProgress.php?id=${id}`,
  };
};

function ViewControlChart({ predictedValue, executeValue, month }) {
  const series = [
    { name: "Planejado", data: predictedValue },
    { name: "Realizado", data: executeValue },
  ];

  const chartOptionsBrush = {
    chart: {
      id: "PhychartControl",
      brush: {
        target: "PhychartArea",
        enabled: true
      },
      selection: {
        enabled: true,
        xaxis: {
          min:  1,
          max:  month.length,
      }
      }
    },
    colors: ["#ff3330","#1976D2"],
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.91,
        opacityTo: 0.1
      }
    },
    xaxis: {
      type: "category",
      categories: month,
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 2,
      labels:{
        formatter: (value) => `${value}%`,
      } 
    }
  };


  return <ReactApexChart
  options={chartOptionsBrush}
  series={series}
  type="line"
  height="100%"
  width="100%"
/>  
}

function ViewChart({ predictedValue, executeValue, month }) {
  const series = [
    { name: "Planejado", data: predictedValue },
    { name: "Realizado", data: executeValue },
  ];

  const options = {
    chart: {
      id: "PhychartArea",
      //height: 400,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#ff3330","#1976D2"], //, "#545454"
    dataLabels: {
      enabled: false,
      formatter: (value) => `${value}%`,
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: month,
      title: { text: "Mês" },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels:{
        formatter: (value) => `${value}%`,
      }
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };
  return (
    <ReactApexChart
          options={options}
          series={series}
          type="line"
          height="100%"
          width="100%"
        />   
  );
}

const init = {
  executeValue: [],
  predictedValue: [],
  month: [],
};

export default function ModalPhy() {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = React.useState(init);

  const updateData = async () => {
    await api(GET_INFO(id))
      .then((ret) => {
        const { data } = ret;
        if (data === null) return;
        const executeValue = data.map((ret) => parseFloat(Number(ret.realisadoPercent).toFixed(0)));
        const predictedValue = data.map((ret) => parseFloat(Number(ret.previstoPercent).toFixed(0)));  
        const month = data.map((ret) => ret.mes);
        setData({ executeValue, predictedValue, month });
      })
      .catch((e) => {
        setData(init);
      });
  };

  React.useEffect(() => {
    updateData();
  }, []);

 return (
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> Avanço físico </Typography>
   <Divider />
  </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart
   predictedValue={data.predictedValue}
   executeValue={data.executeValue}
   month={data.month}/>
  </Grid>
  <Grid item xs={12} className={classes.viewgraphic}>
   <ViewControlChart
    predictedValue={data.predictedValue}
    executeValue={data.executeValue}
    month={data.month}/>
  </Grid>
 </Grid>
 );
}
