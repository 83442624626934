import React from "react";
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export function SelectUrgencyCustom (props){
  const options = [
    {id: 1, name: "1. Pode esperar"},
    {id: 2, name: "2. Pouco urgente"},
    {id: 3, name: "3. Urgente, merce atenção no curto prazo"},
    {id: 4, name: "4. Muito urgente" },
    {id: 5, name: "5. Necessidade de ação imediata"},
  ];
return SelectCustom({options, ...props}) 
}

export function SelectTrendCustom (props){
  const options = [
    {id: 1, name: "1. Não irá mudar"},
    {id: 2, name: "2. Irá piorar a longo prazo"},
    {id: 3, name: "3. Irá piorar a médio prazo"},
    {id: 4, name: "4. Irá piorar a curto prazo"},
    {id: 5, name: "5. Irá piorar rapidamente"},
  ];
return SelectCustom({options, ...props}) 
}

export function SelectGravityCustom (props){
  const options = [
    {id: 1, name: "1. Sem gravidade"},
    {id: 2, name: "2. Pouco grave"},
    {id: 3, name: "3. Grave"},
    {id: 4, name: "4. Muito grave"},
    {id: 5, name: "5. Extremamente grave"},
  ];
return SelectCustom({options, ...props}) 
}

export function SelectPriorityCustom (props){
    const options = [
        {id: 1, name: 'Baixa' },
        {id: 2, name: 'Média' },
        {id: 3, name: 'Alta'  },
    ];
 return SelectCustom({options, ...props}) 
}

export function SelectStatusTask (props){
  const options = [
      {id: 0, name: 'A fazer' },
      {id: 1, name: 'Em progresso' },
      {id: 2, name: 'Concluído' },
  ];

const nullabId = 0
 
return SelectCustom({options, nullabId, ...props}) 
}

export function SelectSituaion5W2H (props){
  const options = [
      {id: '0', name: 'Pendente'},
      {id: '1', name: 'Atrasado'},
      {id: '2', name: 'Cancelada'},
      {id: '3', name: 'Concluída'},
  ];
return SelectCustom({options, ...props}) 
}
 
export function SelectCustom({textFieldProps, ...props }) {
    const {multiple, limitTags, error, helperText, disabled, options, getOptionDisabled, nullabId, className, style, ...field } = props;
    const { onChange, id, name, value } = field
  return (
   <Autocomplete
    className={className}
    onChange={onChange}
    id={id}
    name={name}
    value={value}
    disabled={disabled}
    options={options}
    multiple={multiple}
    limitTags={limitTags}
    
    getOptionDisabled={(option) => option === options[nullabId]}
    loadingText='Carregando...'
    noOptionsText="Sem opções"
    clearText="Limpar"
    openText="Abrir"
    closeText="Fechar"
    autoHighlight
    uncontrolled="true"
    getOptionSelected={(option, value) => option.id === value.id}
    getOptionLabel={(option) => option.name ? option.name: ''}
    renderInput={(params) =>  
    <TextField
        {...params}
        {...textFieldProps}
        helperText={helperText}
        error={error}
        style={style}
      />} 
    />
  );
}

SelectCustom.defaultProps = {
  nullMessage: "Sem opções",
  textFieldProps:{
    fullWidth: true,
    size: "small",
    margin: "normal",
    variant: "outlined",
  }
}

SelectCustom.propTypes = {
  onChange: PropTypes.func,
};


/*
Prioridade (etapa)
0 - baixa
1 - média
2 - alta

Situação - (etapa)
0 - No prazo
1 - em atraso
2 - concluido

Status (etapa)
0 - a fazer
1 - em progresso
2 - concluido

Situação - (5W2H)
0 - pendente
1 - atrasado
2 - cancelado
3 - concluído

Status (Projeto)
0 - não iniciado
1 - em andamento
2 - em atraso
3 - cancelado
4 - concluído
*/