import React from "react";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
} from "@material-ui/core";

import { TextEditor } from "../../../Components/Editor";
import { GET_GROUP, POST_UPDATE_AXIS } from "../../../services/options";
import { SelectApi } from "../../../Components/SelectsApi";
import { useSnackbar } from "notistack";
import api from "../../../services/Api";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 520, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
 // description: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
  group: Yup.object().shape().required("Obrigatório").nullable(),
});

export const GET_GROUPFORM_INFO = (id) => {
  return { 
   method: 'get',
   url: `axis/description.php?id=${id}`,
  }
 }

export default function NewEdit({id, open, setOpen, updateData}) {
    const [info, setInfo] = React.useState(null)
    const { enqueueSnackbar } = useSnackbar();

    const getData = async()=>{
    await api(GET_GROUPFORM_INFO(id)).then(r=>{
        const buffer = r.data[0]
        setInfo({
          id: buffer.id,
          name: buffer.name,
          group: buffer.group,
          description: buffer.description,
          status: Number(buffer.status),
        })
      }).catch(e=>{
        enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
      })
    }
   
    const submit = async (values, { setSubmitting }) => {
      const buffer = {
        id: id,
        name: values.name,
        groupId: values.group.id,
        description: values.description,
        status: values.status}

        await api(POST_UPDATE_AXIS(buffer)).then(r=>{
          const {data} = r;
          if(data.status ==="success"){
            updateData()
            setSubmitting(false)
            Close()
            enqueueSnackbar("Programa atualizado !!!", { variant: "success" });
          }else{
            setSubmitting(false)
            enqueueSnackbar(`Erro ao atualizar programa`, { variant: "Error" });  
          }
        }).catch(e=>{
          setSubmitting(false)
          enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" });
        })
   };

    const Close =() =>{
       setOpen(false)
    }

  React.useEffect(()=>{
  if (open)  getData()
   },[open]) 
  return (
   <>
    <DefaultModal
    open={open} 
    loading={info === null}
    handleClose={Close} 
    title="Editar programa">
      <Formik
        initialValues={info}
        validationSchema={validationSchema}
        onSubmit={submit}>
        {(props) => <Forms {...props} />}
      </Formik>
    </DefaultModal> 
   </>   
  );
}

const Forms = (props) => {
  const classes = useStyles();
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = props;

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers >
       <Grid container className={classes.root} spacing={3}> 
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            fullWidth
            disabled={values.routine === "true"}
            id="group"
            name="group"
            onChange={(e, value) => {
              setFieldValue("group", value || null);
            }}
            value={values.group}
            config={GET_GROUP}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Portifólio",
            }}
            error={touched.group && Boolean(errors.group)}
            helperText={touched.group && errors.group}
          />
        </Grid>

        <Grid item xs={12}>
          <TextEditor
            id="description"
            name="description"
            label="Descrição"
            onChange={(event) => {
              setFieldValue("description", convertToHTML(event.getCurrentContent()));
            }}
            value={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
              <FormLabel component="legend">Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status"
                id="status"
                value={values.status}
                onChange={(e, value) => {
                  setFieldValue("status", Number(value))}
                }
              >
             <Box style={{display: "flex"}}>  
              <FormControlLabel value={1} control={<Radio />} label="Ativo" />
              <FormControlLabel value={0} control={<Radio />} label="Inativo" />
             </Box>
              </RadioGroup>
            </FormControl>
            </Grid>
       </Grid>     
    </DefaultModalContent>
    <DefaultModalActions style={{margin: "0.5rem"}}>   
          <ButtonSuccess
             style={{width: 110}}
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             loading={isSubmitting}
            > Salvar
            </ButtonSuccess>
    </DefaultModalActions>
    </form>
  );
};
