import React from "react";
import { useParams } from "react-router-dom";
import { Box, createStyles, Grid, makeStyles} from "@material-ui/core";
import Table from "./Table";
import useAxios from "../../../../Context/Hooks/useAxios";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    list: {
      width: "auto",
      margin: "0px auto",
    },
    showInfo: {
      width: "auto",
    },
  })
);

//projects/acitivities.php?id=ID
//https://back.draftmanagementproject.com/api/project/activities.php?id=139
export const GET_DATA = (id) => {
  return { 
   method: 'get',
   url: `project/activities.php?id=${id}`,
  }
 } 

export default function ActivityPanel() {
  const {id} = useParams()
  const {buffer, loading, error, request} = useAxios();
 
  const updateData = async () =>{
    await request(GET_DATA(id))
  }
 
   React.useEffect(()=>{
     updateData()
   },[id])
 
  return (
    <Grid item xs={12}>
     <Table data={buffer} error={error} load={loading}/>
    </Grid>
  );
}
