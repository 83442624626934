import React from "react";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, Grid, Typography, Divider, Paper } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";
import ViewTaskList from "../ViewTaskList";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  title:{
    maxHeight: 40,
    padding: "0.5rem 0px",
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 300},
    [theme.breakpoints.up("md")]: {width: "100%", height: 350},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 350},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 400},
    paddingTop: "1rem",
  }
})
);

const GET_INFO = (id) => {
  return {
    method: "get",
    url: `graphic/projectSecretary.php?id=${id}`,
  };
};
 
function ViewChart({series, setView}){
  const options = {
    chart:{
      events: {
        dataPointSelection: (event, chartContext, config) => { 
          setView({
            name: "",
            responsible: null,
            responsibleSecretary: null,
            status: null,
            situation: config.dataPointIndex+1,
            priority: null,
          })
          }
      },
    },
    labels: ["No prazo", "Em atraso", "Concluído", "Cancelado", "Suspenso" ],//"Projeto cancelado" "#CCCC"
    colors: ["#2E93fA", "#FFA500", "#66DA26", "#ff3330", "#e65c00"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          // labels: {
          //   show: true,
          //   name: {
          //     fontSize: "25px",
          //     fontWeight: 550,
          //   },
          //   value: {
          //     fontSize: "25px",
          //     fontWeight: 550,
          //     formatter: function (val) {
          //       return val;
          //     }
          //   },
            // total: {
            //   show: true,
            //   label: "Total",
            //   formatter: function (w) {
            //     let series = w.config.series;
            //     const result = series.reduce(function add(sum, currValue) {
            //       return sum + currValue;
            //     }, 0);
            //     return result;
            //   }
            // }
         // }
        }
      }
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
    },
  };
  return <ReactApexChart options={options} series={series} type="donut" width={"100%"} height={"100%"}
/>
}

export default function ModalProgress() {
  const classes = useStyles();
  const { id } = useParams();
  const [series, setSeries] = React.useState([]);
  const [view, setView] = React.useState(null)

  const updateData = async () => {
   await api(GET_INFO(id)).then((ret) => {
      const {data} = ret
      if (data === null) return
      const concluidos = data.reduce((curr, index) => curr + parseFloat(index.dados.concluidos,2), 0)
      const noprazo    = data.reduce((curr, index) => curr + parseFloat(index.dados.noprazo,2), 0)
      const fora       = data.reduce((curr, index) => curr + parseFloat(index.dados.fora,2), 0)
      const cancelados = data.reduce((curr, index) => curr + parseFloat(index.dados.cancelados,2), 0)

      setSeries([Number(noprazo), Number(fora), Number(concluidos), Number(cancelados)]);
    }).catch(e=>{
      setSeries([])
    })
  };

  const handleClose =()=> setView(null)
  
  React.useEffect(() => {
    updateData();
  }, []);

 return (
 <>
  <ViewTaskList search={view} handleClose={handleClose} title="Situação"/>
  <Grid container className={classes.root}>
   <Grid item xs={12} className={classes.title}>
    <Typography gutterBottom variant="h6" align="center"> Situação </Typography>
    <Divider/>
   </Grid>
   <Grid item xs={12} className={classes.graphic}>
    <ViewChart series={series} setView={setView}/>
   </Grid>
  </Grid>
 </>
 );
}
