import React from "react";
import {Routes, Route,  useParams} from "react-router-dom";
 
import {
  MenuBook,
  Assignment,
  ReportProblem} from '@material-ui/icons';

import { LayoutContext } from "./../../Components/Layout/LayoutMaster";
import MainTask from "./Tasks";
import Resume from "./Resume"
import Calendar from "./Calendar"
import Riskmanagement from "./Riskmanagement"
import api from "./../../services/Api";
import { GET_RQSTINFO_PROJECT } from "./../../services/options";
import { UserContext } from "../../Context/UserContext";

const initial = {name: "", isCanceled: false, isFinish: false, isBlock: false, projectInitDate: null, projectDeadLine: null, projectPredictedValue: null}
const projectInfos ={
  name: "",
  initDate: null,
  deadLine: null,
  predictedValue: null,
  view: true,
  situation:{
    canceled: false,
    stoped: false,
    finish: false,
  },
  permissions:{
    project:{
      edit  : false,
      delete: false,
      pause : false,
      resume: false,
      open  : false,
    },
    task: {
      create: false,
      edit  : false,
      delete: false,
      pause : false,
      resume: false,
      open  : false,
    },
    gut:{
      create: false,
      edit: false,
      delete: false,
    },
    risk:{
      create: false,
      edit  : false,
      delete: false,
      pause : false,
      resume: false,
      open  : false,
    }

  }
}

export const ViewProjectContext = React.createContext();

const View = () => {
  const { id } = useParams();
  const {privilege} = React.useContext(UserContext)
  const {setShowLayout, setListMenu,} = React.useContext(LayoutContext);
  const [infosData, setInfosData] = React.useState(initial)

  const updateInfoProject = React.useCallback(async () => {
    await api(GET_RQSTINFO_PROJECT(id)).then(resp=>
       {
         const {name, canceled, status, stoped, initDate, deadLine, predictedValue} = resp.data;
 

         const isCanceled =  (canceled === "1") || (stoped === "1")
         const isFinish   =  (status === "4" || status === "6")
         const isBlock    =   isCanceled && ((status === "4" || status === "6") && (!privilege.administrator && !privilege.collaboratorNv1) )
         
         setInfosData({name: name, isCanceled, isFinish, isBlock, projectInitDate: initDate, projectDeadLine: deadLine, projectPredictedValue: predictedValue}) 
      }
     ).catch(e => setInfosData(initial))
 },[id, infosData]);
  
  const menu = [
         { name: 'Resumo',          to: `/projects/view/${id}/resume/`,         icon: <MenuBook/> },
         { name: 'Etapas',          to: `/projects/view/${id}/tasks/`,          icon: <Assignment/> },
         { name: 'Gestão de Risco', to: `/projects/view/${id}/riskmanagement/`, icon: <ReportProblem/> },
         ];

 React.useEffect(()=>{
       if (infosData.name === '') updateInfoProject()
       setShowLayout(true)
       setListMenu(menu)
       }, [id])      
       
  return (
    <ViewProjectContext.Provider value={{infosData, id, updateInfoProject}}>
      <Routes>
        <Route path="tasks/"          element={<MainTask      />} />
        <Route path="resume/"         element={<Resume        />} />
        <Route path="calendar/"       element={<Calendar      />} />
        <Route path="riskmanagement/" element={<Riskmanagement/>} />
      </Routes>
    </ViewProjectContext.Provider>
  );
};

export default View;
