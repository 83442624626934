import React from "react";
import {
  Button,
  Grid,
  Container,
  createStyles,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

import Table from "./Table";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import useAxios from "../../../Context/Hooks/useAxios";
import FilterOldProject from "../../../Components/Screens/FilterOldProject";
import { Search } from "@material-ui/icons";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "block",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(1, 0, 1, 1),
      borderRadius: 5,
    },
    button:{
      
    
    }
  })
);

const initialValues = {
  name: "",
  responsibleSecretary: null,
};

export const GET_TABLE_PROJECT = (body) =>{
  return { 
   url: `projects_old/search.php`,
   method: 'post',
     data: body,
   }
}  

export default function DataBaseProjects() {
  const classes = useStyles();
  const {buffer, loading, error, request} = useAxios();
  const [search, setSearch] = React.useState(initialValues);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
 
  const updateData = async (page, rowPerPage) => {
    await request(GET_TABLE_PROJECT(search))
  };

  React.useEffect(() => {
       updateData(0, 10);
  }, [search]);
  return (
    <Container className={classes.root} maxWidth="xl">
      <FilterOldProject drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} search={search}  setSearch={setSearch}/>
      <Grid item xs={12}>
        <CurrentPageInfo primary="Base de dados" />
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={8}>
         <Button variant="contained" color="primary" className={classes.button} onClick={()=> setDrawerOpen(!drawerOpen)}>
           <Typography >Filtro</Typography>
           <Search style={{marginLeft: 25}}/>
          </Button>
        </Grid>
      </Paper>
      <Grid item xs={12} style={{ paddingTop: 5 }}>
         <Table data={buffer} error={error} load={loading}/> 
      </Grid>
    </Container>
  );
}