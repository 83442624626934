import React from "react";
import CustomTable from "../../../Components/CustomTable";
import { ViewUser } from "./ViewUser";

export default function ReadTable({ data, updateData, error, load }) {
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(false);

  const handleOpen = (id)=>{
    setRowId(id)
    setOpen(true)
  }

  const handleClose =()=>{
    setRowId(null)
    setOpen(false)
  }
   
  const columns = [
    { name: "id", options: { display: "false" } },
    { name: "login", label: "Usuário" },
    { name: "name" , label: "Nome" },
    { name: "email", label: "E-mail" },
    { name: "secretary", label: "Secretaria" },
    { name: "accessLevel", label: "Nível de acesso" },
    { name: "status", label: "Situação" , options: { filter: true, sort: true,
      customBodyRender: (value) => {return value ==='1'? 'Ativo':'Inativo' },
     }
   },
    { name: "lastAccess", label: "Último acesso", options: { filter: true, sort: true,
      customBodyRender: (value) =>  value === '0000-00-00 00:00:00'? 'Sem acesso':value },
    },
  ];

  const options ={
    search: true,
    print: true,
    viewColumns: true,
    elevation: 0,
    onRowClick: (rowData) => handleOpen(rowData[0])
  }

  return (
  <>
   {open && <ViewUser id={rowId} open={open} setOpen={handleClose} updateData={updateData}/>}
   <CustomTable data={data} columns={columns} opt={options} error={error} load={load}/>
  </>
  );
}
