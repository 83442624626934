import React from 'react'
import * as Icons from "@material-ui/icons";
import { List, ListItem, Typography,  ListItemIcon, ListItemText, Grid} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import DefaultModal, { DefaultModalContent } from '../../../../Components/DefaultModal';
import { useSnackbar } from 'notistack';
import { GET_RQST_GUT } from '../../../../services/options';
import api from '../../../../services/Api';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { UserContext } from '../../../../Context/UserContext';
import ConfirmDelete from './ConfirmDelete';
import EditGUT from './Edit';
import { ViewProjectContext } from '../..';

const useStyles = makeStyles((theme) =>
createStyles({
  root:{
    margin: 0,
    padding: 0,
    minHeight: "17rem",
  },
  main: {
    padding: "0rem 0.5rem 1rem 0.5rem",
    margin:  "0px",
    [theme.breakpoints.up("xs")]: { minWidth: 380, maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { minWidth: 380, maxWidth: 400 },
    [theme.breakpoints.up("md")]: { minWidth: 400, maxWidth: 450 },  
    [theme.breakpoints.up("lg")]: { minWidth: 400, maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { minWidth: 400, maxWidth: 550 },
  },
  speedDial:{
    //marginLeft:"-1.5rem",
    transform: 'scale(0.825)',
  }
})
);

export const ViewModal = ({id, open, close, handleUpdate}) => {
  const classes = useStyles();
  const { privilege  } = React.useContext(UserContext);
  const {infosData} = React.useContext(ViewProjectContext);
  const {isCanceled} = infosData
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null)
  const [select, setSelect] = React.useState(null)
  const [openView, setOpenView] = React.useState(false)
  const [openDial, setOpenDial] = React.useState(false);

  const updateData = async()=>{
    await api(GET_RQST_GUT(id)).then(r=>{
       const {data} = r
       setData({
         risk: data.risk,
         gravity: data.gutGravity.name,
         urgency: data.gutUrgency.name,
         trend: data.gutTrend.name,
         gut  : data.gut,
         average: data.average,
       })
     }).catch(e=>{
       enqueueSnackbar(`Erro ${e.message}`, { variant: "Error" })
     })
   }

   React.useEffect(()=>{
     if(open) updateData()
    },[open])

    const dataGUT = [
      {
        icon: <Icons.Warning />,
        text: "Gravidade: ",
        data: data?.gravity,
      },
      {
        icon: <Icons.Warning />,
        text: "Urgência: ",
        data: data?.urgency,
      },
      {
        icon: <Icons.Warning />,
        text: "Tendência: ",
        data: data?.trend,
      },
      {
        icon: <Icons.ShowChart/>,
        text: "Nível do problema: ",
        data: data?.average,
      },
      {
        icon: <Icons.Feedback />,
        text: "GUT: ",
        data: data?.gut,
      },
    ]


    const actions = [
      { icon: <Icons.Edit />, 
        name: 'Atualizar GUT',
        option: 0, 
        //hide:     isCanceledTask || isPauseTask || isCanceledProject, 
        disabled: privilege.cityHall || isCanceled},  
  
      { icon: <Icons.Cancel/>, 
        name: 'Apagar GUT',
        option: 1, 
       // hide:     isCanceledTask || isCanceledProject, 
        disabled: !privilege.administrator && !privilege.collaboratorNv1},    
      ]

    const handleOpenView =(id)=>{
      setSelect(id)
      setOpenView(true)
    }
  
    const handleCloseView =()=>{
      setOpenView(false)
      setSelect(null)
    }
    
    const handlActionsOpen = (e) => {
      if(e.type === "mouseenter" || e.type === "click")
      setOpenDial(true);
    };
  
    const handlActionsClose = (e) => {//mouseleave
      if(e.type === "mouseleave")
      setOpenDial(false);
    };

    const updateAll =()=>{
      handleUpdate()
      updateData()
    }

    const handleClose =()=>{
      handleCloseView()
      close()
    }

    const getSelectContent = React.useCallback((select) => { 
      switch (select) {
        case 0: return <EditGUT       gutId={id} updateData={updateAll} open={openView} setOpen={handleCloseView}/>
        case 1: return <ConfirmDelete gutId={id} updateData={handleUpdate} open={openView} setOpen={handleCloseView} handleClose={handleClose}/>
        default: return null;
      }
    }, [select]);
  return (
  <>
   {getSelectContent(select)}
    <DefaultModal loading={data === null} open={open} handleClose={close} title={data?.risk} maxWidth="xs">
     <DefaultModalContent dividers className={classes.root}>
     <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"className={classes.main}>
      
     <Grid item xs={12}>
      <SpeedDial
        ariaLabel="SpeedDialClick"
        className={classes.speedDial}
        icon={ <Icons.Settings  />  }
        direction="right"
        FabProps={{size:"small" }}
        onClose={handlActionsClose}
        onOpen={handlActionsOpen}
        open={openDial} 
        >
        {actions.filter(r=> !r.hide).map((action) => (
         <SpeedDialAction
          tooltipPlacement="top"
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          disabled={action.disabled}
          hidden={action.disabled}
          onClick={()=>handleOpenView(action.option)}/>
        ))}
       </SpeedDial>  
     </Grid>

     <Grid item xs={12}>
      <List dense disablePadding>
        {data  && dataGUT.filter(r=> r.data !== null).map((value) => (
          <ListItem key={value.text}>
           <ListItemIcon >{value.icon}</ListItemIcon>
           <ListItemText
           style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
           primary={<Typography variant="h6" component="span">{value.text}</Typography>}
           secondary={
            <Typography
              component="span"
              style={{marginLeft: "0.5rem" }}
              color="textSecondary"
            >
            {value.data} 
            </Typography>
          }/>
          </ListItem>
        ))}
      </List>
      </Grid>  
     </Grid>  
     </DefaultModalContent>
    </DefaultModal>
  </>
  )
}
