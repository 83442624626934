import React from "react";
import CustomTable from "../../../Components/CustomTable";
import { ShowProjectStatus} from "../../../Components/ShowInfos";
import { ShowPercent } from "../../../Components/ShowInfos";
import { FormatDate, FormatRealNumber, truncarText } from "../../../Components/Helper/FormatValues";
import ViewMyProject from "../../../Components/Screens/ViewMyProject";

export default function ReadTable({data, error, load}) {
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(true);

  const handleOpen = (id)=>{
    setRowId(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setRowId(null)
    setOpen(false)
  }
  const options = {
    onRowClick: (rowData, rowState) => handleOpen(rowData[0]),
  };
  return (
    <>
      {rowId && <ViewMyProject id={rowId} open={open} close={handleClose}/>}
      <CustomTable data={data} columns={columns} opt={options} error={error} load={load} elevation={0}/>
    </>
  );
}

const columns = [
  { name: "projectId", options: { display: "false" } },
  { name: "projectName",
    label: "Projeto",
    options: {filter: true, sort: true, 
      customBodyRender: (value) =>  truncarText(value, 75)
    },
  },
  { name: "projectResponsibleSecretary",
    label: "Secretaria",
    options: { filter: true, sort: true, width: 100, },
  },
  { name: "progress",
    label: "Progresso",
    options: { filter: true, sort: true,
     customBodyRender: (value) => <ShowPercent value={Number(value)}/>
  }
  },
  {
    name: "status",
    label: "Status",
    options: { filter: true, sort: true,
      customBodyRender: (value) => 
      <ShowProjectStatus type={Number(value.type)} value={Number(value.value)}/>
  }
  },
  { name: "predictedValue",
    label: "Valor Previsto",
    options: {filter: true, sort: true,
      customBodyRender: (value) => FormatRealNumber(value)
    },
  },
  { name: "executeValue",
    label: "Valor Executado",
    options: { filter: true, sort: true, 
      customBodyRender: (value) => FormatRealNumber(value) 
    },
  },
  { name: "initDate",
    label: "Data inicial",
    options: { filter: true, sort: true, 
      customBodyRender: (value) => FormatDate(value) 
    },
  },
  { name: "deadLine",
    label: "Data limite",
    options: { filter: true, sort: true, 
      customBodyRender: (value) => FormatDate(value) 
    },
  },
];