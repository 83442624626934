import { Avatar, Grid, Box, Paper, Typography, createStyles,  makeStyles, } from "@material-ui/core";
import React from "react";
import { UserContext } from "../../../Context/UserContext";

const useStyles = makeStyles((theme) =>
createStyles({
  root: { 
   alignContent: "center",
   justifyContent: "center",
   margin: "0px auto",
   alignItems: "center", 
   textAlign: "center", 
   padding: "1rem"
  },
  user: {
    padding: '1rem', 
    marginRight:'1.2rem',
    [theme.breakpoints.up("xs")]: { width: 80,  padding: theme.spacing(0.5, 0.7, 0.5, 0.7),},  
    [theme.breakpoints.up("sm")]: { width: 90,  padding: theme.spacing(0.5, 1, 0.5, 1),},   
    [theme.breakpoints.up("md")]: { width: 110, padding: theme.spacing(0.5, 1, 0.5, 1),},  
    [theme.breakpoints.up("lg")]: { width: 115, padding: theme.spacing(0.5, 1, 0.5, 1),},  
    [theme.breakpoints.up("xl")]: { width: 125, padding: theme.spacing(0.5, 1, 0.5, 1),},  
  },
  info:{
 
  },
  userName:{
    opacity: 1,
    letterSpacing: '0px',
    padding: "1rem 0px 0.1rem 0px",
    [theme.breakpoints.up("xs")]: { fontSize: "1.20rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.20rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.25rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.3rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.4rem"},  
  },
  userSecretary:{
    letterSpacing: '0px',
    [theme.breakpoints.up("xs")]: { fontSize: "1.0rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.1rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.15rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.2rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.25rem"},  
  },
  userLevel:{
    letterSpacing: '0px',
    [theme.breakpoints.up("xs")]: { fontSize: "1.0rem"},   
    [theme.breakpoints.up("sm")]: { fontSize: "1.0rem"},     
    [theme.breakpoints.up("md")]: { fontSize: "1.0rem"},  
    [theme.breakpoints.up("lg")]: { fontSize: "1.0rem"},  
    [theme.breakpoints.up("xl")]: { fontSize: "1.0rem"},  
  },
  avatar:{
    margin: "0px auto",
    [theme.breakpoints.up("xs")]: { width: theme.spacing(7), height: theme.spacing(7),},   
    [theme.breakpoints.up("sm")]: { width: theme.spacing(7), height: theme.spacing(7),},   
    [theme.breakpoints.up("md")]: { width: theme.spacing(8), height: theme.spacing(8),},   
    [theme.breakpoints.up("lg")]: { width: theme.spacing(8), height: theme.spacing(8),},    
    [theme.breakpoints.up("xl")]: { width: theme.spacing(10), height: theme.spacing(10),},   
  },
  avatarLetra:{
   fontWeight:"bold",
   [theme.breakpoints.up("xs")]: { fontSize: "2.0rem"},   
   [theme.breakpoints.up("sm")]: { fontSize: "2.5rem"},     
   [theme.breakpoints.up("md")]: { fontSize: "2.5rem"},  
   [theme.breakpoints.up("lg")]: { fontSize: "3.0rem"},  
   [theme.breakpoints.up("xl")]: { fontSize: "3.0rem"},  
 
  }
})
);

const HeaderProfile = () => {
  const classes = useStyles();
  const {userInfo} = React.useContext(UserContext);
  return (
  <Box className={classes.root}>
   <Avatar className={classes.avatar}> 
    <Typography component="span" variant="h6" className={classes.avatarLetra}> {userInfo?.name[0]} </Typography>
   </Avatar>
   <Box style={{display: "grid"}}>
    <Typography component="span" variant="h6" color="textPrimary"          className={classes.userName}> {userInfo?.name}</Typography>
    <Typography component="span" variant="subtitle1" color="textSecondary" className={classes.userSecretary}> {userInfo?.secretary}</Typography>
    <Typography component="span" variant="subtitle2" color="textSecondary" className={classes.userLevel}> {userInfo?.accessLevel}</Typography>
   </Box>
  </Box>
  );
};

export default HeaderProfile;
