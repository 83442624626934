import React from "react";
import { useParams } from "react-router-dom";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";
 
import {
    Grid,
    TextField,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
  } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { SelectApi } from "./../../../../Components/SelectsApi";
import { TextEditor } from "./../../../../Components/Editor";
import { GET_AXIS, GET_GROUP, GET_SECRETARY, GET_RESPONSIBLESECRETARY, GET_RQSTINFO_PROJECT } from "../../../../services/options";

import { NumberFormatCustom } from "./../../../../Components/Helper/FormatValues";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import ConfirmEdit from "./ConfirmEdit";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";
import { CustomDatePicker } from "../../../../Components/CustomDatePicker";
 
const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "flex",
    margin: 0,
  },
  main:{
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Obrigatório")
      .min(6, "O nome deve ter pelo menos 6 caracteres"),

    // resume: Yup.string()
    // .required("Obrigatório")
    // .min(6, "O nome deve ter pelo menos 6 caracteres"),
    // description: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
  
    routine: Yup.number().required("Obrigatório"),
   // status : Yup.number().required("Obrigatório"),
  
    group: Yup.object()
      .when("routine", {
        is: 0,
        then: Yup.object().required("Obrigatório").nullable(),
      })
      .nullable(),
  
    axis: Yup.object()
      .when("routine", {
        is: 0,
        then: Yup.object().required("Obrigatório").nullable(),
      })
      .nullable(),
  
    initDate: Yup.date().required("Obrigatório").nullable("Data inválida").typeError("Data inválida"),
    deadLine: Yup.date().min(
      Yup.ref('initDate'),
      "A data final não pode ser anterior à data de inícial"
    ).required("Obrigatório").nullable("Data inválida").typeError("Data inválida"),

    predictedValue: Yup.string().required("Obrigatório"),
    responsible: Yup.object().shape().required("Obrigatório").nullable(),
    responsibleSecretary: Yup.object().shape().required("Obrigatório").nullable(),
  });

export default function EditProject({open, setOpen, updateData}) {
 const [select, setSelect] = React.useState(null)
 const [openView, setOpenView] = React.useState(false)

 const [editData, setEditData] = React.useState(null)
 const [init, setInit] = React.useState(null)
 const { enqueueSnackbar } = useSnackbar();
 const { id } = useParams();

    const rqstInfo = async ()=>{
      await api(GET_RQSTINFO_PROJECT(id)).then((resp) => {
        const { data } = resp;
        setInit(
         {projectId:      id,
          name:           data.name,
          resume:         data.resume,
          predictedValue: data.predictedValue,
          executeValue:   data.executeValue === null?'':data.executeValue,
          routine:        data.axis.name !== null?0:1,
          group:          data.group.name === null? null :data.group,
          axis:           data.axis.name === null? null : data.axis,
          initDate:       data.initDate,
          deadLine:       data.deadLine,
          description:    data.description,
          responsible:    data.responsible,
          responsibleSecretary: data.responsibleSecretary,
          canceled:       data.canceled === '1'? true: false,
          stoped  :       data.stoped   === '1'? true: false,
          justification:  data.justification,
        }
        )
      }).catch(e=>{
        enqueueSnackbar(`Erro`, { variant: "Error" })
      })
    }

    const submit = async(values, { setSubmitting }) => {
      setEditData({...values, canceled: 0, stoped: 0, justification: ""})
      handleOpenView(0)
    }

    const handleClose = ()=>{
      updateData()
      setInit(null)
      setOpen(false);
    }

    React.useEffect(()=>{
      if(open === true ) rqstInfo()
    },[open])

    const handleOpenView =(id)=>{
      setSelect(id)
      setOpenView(true)
    }
  
    const handleCloseView =()=>{
      setOpenView(false)
      setSelect(null)
    }

    function getSelectContent() {
      switch (select) {
        case 0: return <ConfirmEdit open={openView} setOpen={handleCloseView} updateData={handleClose}  editData={editData}/> 
        default: return null;
      }
    }
 
  return (
    <>
     {getSelectContent()} 
     <DefaultModal 
      open={open} 
      handleClose={()=>setOpen(false)} 
      title="Editar Projeto" 
      scroll="body"
      loading={init === null}>
      <Formik
       setOpen={setOpen}
       initialValues={init}
       validationSchema={validationSchema}
       onSubmit={submit}>
       {(props) => <Forms {...props}/>}
      </Formik>
     </DefaultModal>  
  </>  
  );
}

const Forms = (props) => {
    const classes = useStyles();
    const {
      values,
      errors,
      touched,
      handleSubmit,
      handleChange,
      isSubmitting,
      setFieldValue,
      handleReset,
      dirty,
    } = props;

    React.useEffect(() => {
      const setInitValue = (id) => {
        setFieldValue(id, null);
      };
      if (values.routine === 1) {
        setInitValue("group");
        setInitValue("axis");
      }
    }, [values?.routine]);
  
    const clear = () =>{
      handleReset()
      setFieldValue('description', "<p></p>");
    }

    return (
      <form onSubmit={handleSubmit} noValidate>
        <DefaultModalContent dividers className={classes.root}>
         <Grid container spacing={3} className={classes.main} > 
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              autoFocus
              variant="outlined"
              size="small"
              id="name"
              label="Projeto"
              name="name"
              inputProps={{ autoComplete: 'off' }} 
              onChange={handleChange}
              value={values.name}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
  
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={5}
              variant="outlined"
              size="small"
              id="resume"
              label="Resumo"
              name="resume"
              inputProps={{ autoComplete: 'off' }} 
              onChange={handleChange}
              value={values.resume}
              error={touched.resume && Boolean(errors.resume)}
              helperText={touched.resume && errors.resume}
            />
          </Grid> */}
  
          <Grid item xs={12} style={{display: "flex"}}>
          <Grid item xs={4} >
          <FormControl component="fieldset">
              <FormLabel component="legend">Projeto de rotina?</FormLabel>
              <RadioGroup
                aria-label="routine"
                name="routine"
                id="routine"
                value={values.routine}
                onChange={(e, value) => {
                  setFieldValue("routine", Number(value))}
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
            </Grid>
  
            <Grid item xs={8} style={{ display: "grid" }}>
              <Grid item xs={12}>
                <SelectApi
                  fullWidth
                  disabled={Boolean(values.routine === 1)}
                  id="group"
                  name="group"
                  onChange={(e, value) => {
                    setFieldValue("group", value || null) 
                    setFieldValue("axis", null)}
                  }
                  value={values.group}
                  config={GET_GROUP}
                  textFieldProps={{
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                    label: "Portifólio",
                  }}
                  error={touched.group && Boolean(errors.group)}
                  helperText={touched.group && errors.group}
                />
              </Grid>
  
              <Grid item xs={12}>
                <SelectApi
                  fullWidth
                  disabled={Boolean(values.group === null | values.routine === 1)}
                  id="axis"
                  name="axis"
                  onChange={(e, value) => setFieldValue("axis", value || null)}
                  value={values.axis}
                  config={GET_AXIS(values.group?.id)}
                  textFieldProps={{
                    label: "Programa",
                    fullWidth: true,
                    size: "small",
                    margin: "normal",
                    variant: "outlined",
                  }}
                  error={touched.axis && Boolean(errors.axis)}
                  helperText={touched.axis && errors.axis}
                />
              </Grid>
            </Grid>
            </Grid>
   
            <Grid item xs={5}  >
            <CustomDatePicker
              id="initDate"
              name="initDate"
              label="Data inicial"
              maxDate={values.deadLine || undefined}
              onChange={(value) =>{
              setFieldValue("initDate",  value || null)}}
              value={values.initDate}
              error={touched.initDate && Boolean(errors.initDate)}
              helperText={touched.initDate && errors.initDate}
             />
            </Grid>

            <Grid item xs={7}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="predictedValue"
              label="Valor previsto"
              name="predictedValue"
              inputProps={{ autoComplete: 'off' }} 
              InputProps={{
                autoComplete: 'off',
                inputComponent: NumberFormatCustom,
              }}
              onChange={handleChange}
              value={values.predictedValue}
              error={touched.predictedValue && Boolean(errors.predictedValue)}
              helperText={touched.predictedValue && errors.predictedValue}
            />
          </Grid>
  
            <Grid item xs={5}  >
             <CustomDatePicker
              
              id="deadLine"
              name="deadLine"
              label="Data limite"
         
              minDate={values.initDate || undefined}
              onChange={(value) =>{
              setFieldValue("deadLine",  value || null)}}
              value={values.deadLine}
              error={touched.deadLine && Boolean(errors.deadLine)}
              helperText={touched.deadLine && errors.deadLine}
             />
            </Grid>
    
          <Grid item xs={12} style={{marginTop: "px", paddingTop:"0px"}}>
            <TextEditor
              id="description"
              name="description"
              label="Objetivo"
              onChange={(event) => {
                setFieldValue("description", convertToHTML(event.getCurrentContent()));
              }}
              value={values.description}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
            />
          </Grid>
  
          <Grid item xs={12}>
            <SelectApi
              id="responsibleSecretary"
              name="responsibleSecretary"
              onChange={(event, value) =>
              {  setFieldValue("responsibleSecretary", value)
                 setFieldValue("responsible", null)}
              }
              value={values.responsibleSecretary}
              config={GET_SECRETARY()}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                variant: "outlined",
                label: "Secretaria responsável",
              }}
              error={touched.responsibleSecretary && Boolean(errors.responsibleSecretary)}
              helperText={touched.responsibleSecretary && errors.responsibleSecretary}
            />
          </Grid>
  
          <Grid item xs={12}>
            <SelectApi
              id="responsible"
              name="responsible"
              disabled={values.responsibleSecretary === null}
              onChange={(event, value) =>{
                (value?.id !== null || value === null ? setFieldValue("responsible", value || null):
                setFieldValue("responsible", null))
              }}
              value={values.responsible}
              config={GET_RESPONSIBLESECRETARY(values.responsibleSecretary?.id)}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                variant: "outlined",
                label: "Responsável",
              }}
              error={touched.responsible && Boolean(errors.responsible)}
              helperText={touched.responsible && errors.responsible}
            />
          </Grid>

          </Grid>
          </DefaultModalContent>
         <DefaultModalActions style={{marginRight:"1.2rem"}}>
          <ButtonSuccess
             style={{ width: 110}}
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             disabled={!dirty}
             loading={isSubmitting}
            > Salvar
            </ButtonSuccess>
        </DefaultModalActions>
      </form>
    );
  };

 