import React from "react";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, Grid, Paper } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";

import { FormatRealNumber } from "../../../../../Components/Helper/FormatValues";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  title:{
    maxHeight: 40,
    padding: "0.5rem 0px",
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 300},
    [theme.breakpoints.up("md")]: {width: "100%", height: 350},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 350},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 400},
    paddingTop:"1rem",
  }
})
);


const GET_INFO = (id) => {
  return {
    method: "get",
    url: `graphic/projectValue.php?id=${id}`,
  };
};

const getPercentWidth = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}

function ViewChart({predictedValue, executeValue, taskName}){

  function calcSizeBar(){
   return getPercentWidth(900, taskName.length <=20? taskName.length:15, 45)

    if(taskName.length >= 0 && taskName.length<= 2){
      return "15%"
    }else if(taskName.length >= 3 && taskName.length<= 5){
      return "25%"
    }else if(taskName.length >= 6 && taskName.length<= 8){
      return "35%"
    }else{return "45%"}
  }

  const series = [
    {name: "Valor previsto",
     data: predictedValue,
     color: "#0CA7FE"
    },
    {name: "Valor executado",
     data: executeValue,
     color: "#005382"
    },
  ]

 const options = {
  // chart:{
  //   dropShadow: {
  //     enabled: true,
  //     top: 0,
  //     left: 0,
  //     blur: 1,
  //     opacity: 0.5
  //   },
  // },
  grid: {
    show: true,
    borderColor: "#CCC",
    strokeDashArray: "3,4",
    position: "back",
    xaxis: {
      lines: {
        show: true
      }
    },
  },
    plotOptions: {

      bar: {
        columnWidth: calcSizeBar(),
        borderRadius: 5,
        horizontal: false,
        distributed: false,
    
      },
      
      dataLabels: {
        enabled: true,
        hideOverflowingLabels: true,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    dataLabels: {
      enabled: false,
      hideOverflowingLabels: true,
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0,
        //gradientToColors: ["#207AAC", "#09344D"],
        stops: [0, 100]
      }
    },
    
    tooltip: {
      y: {
        formatter: (val)=> FormatRealNumber(val) 
      }
    },
    xaxis: {
      type: 'category',
      categories: taskName,
      labels:{
        show: true,
        rotate: -25,
        trim: true,
        minHeight: 0,
        maxHeight: 60,
        style:{
         fontSize: "10px"
        }
      }
    },
    
    yaxis: {
      axisBorder: {
        show: true
      },
      
      axisTicks: {
        show: true
      },
      labels: {
        show: true,
        formatter: (val)=> FormatRealNumber(val) 
      }
    },
  }
  return <ReactApexChart options={options} series={series} type="bar" height="100%" width="100%"/>
}

const init = {
  executeValue:[],
  predictedValue: [],
  taskName:[],
}

export default function ModalFinances() {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = React.useState(init)
 
  const updateData = async () => {
   await api(GET_INFO(id)).then((ret) => {
      const {data} = ret
      if (data === null) return
      const executeValue   = data.map((ret) => Number(ret.executeValue));
      const predictedValue = data.map((ret) => Number(ret.predictedValue));
      const taskName       = data.map((ret) => ret.taskName);

      setData({executeValue, predictedValue, taskName})
    }).catch(e=>{
        setData(init)
    });
  };

  React.useEffect(() => {
    updateData();
  }, []);

 return (
  <Grid container className={classes.root}>
   <Grid item xs={12} className={classes.title}>
    <Typography gutterBottom variant="h6" align="center"> Finanças </Typography>
    <Divider/>
   </Grid>
   <Grid item xs={12} className={classes.graphic}>
    <ViewChart 
     predictedValue={data.predictedValue} 
     executeValue={data.executeValue} 
     taskName={data.taskName}/>
   </Grid>
  </Grid>
  );
}
