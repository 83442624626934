import React from "react"
import DefaultModal from "../../../../Components/DefaultModal";
import FormLoadingComponent from "../../../../Components/FormLoading";
import FormGUT from "../../../../Components/Screens/FormGUT";
 
export default function NewGUT({ submit, rowData, open, setOpen, title, loading }) {
  return (
    <DefaultModal open={open} handleClose={()=> setOpen(false)} title={title}>
    {!loading ? <FormGUT submit={submit} initialValues={rowData}/>:<FormLoadingComponent/>}
    </DefaultModal>
  );
}

NewGUT.defaultProps = {
  loading: false,
}