import React from "react";
import { useNavigate } from "react-router-dom";
import { TabProject } from "./TabProject";
import { TabGut } from "./TabGut";
import   TabTask   from "./TabTask";

import {
  Container,
  createStyles,
  Stepper,
  Step,
  StepLabel,
  Paper, 
  StepConnector,
} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as Icons from "@material-ui/icons";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import { UserContext } from "../../../Context/UserContext";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,#1D91DA 0%,#00BFFF 50%,#00BFFF 100%)',
        borderRadius: "1rem",
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
      'linear-gradient( 136deg, #00A36C 0%, #34A56F 50%, #50C878 100%)',
      borderRadius: "1rem",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: "1rem",
  },
})(StepConnector);

const useStyles = makeStyles((theme) =>
  createStyles({
  root: {
   width: 'auto',
   marginLeft: 'auto',
   marginRight: 'auto',
   
  },
  stepper:{
    border: `1px solid  ${theme.palette.divider}`,
    borderBottom: 0,
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 0px 0px rgba(0, 0, 0, 0.08)",
  },
  paper: {
   border: `1px solid  ${theme.palette.divider}`,
   borderTop: 0,
   borderRadius: "0px 0px 10px 10px",
   boxShadow: "0 0px 0px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
   marginTop: theme.spacing(0),
   marginBottom: theme.spacing(2),
   padding: theme.spacing(2),
   minHeight: 720,
  },
  buttons: {
   display: 'flex',
   justifyContent: 'flex-end',
  },
  button: {
   marginTop: theme.spacing(3),
   marginLeft: theme.spacing(1),
  },
  instructions: {
   marginTop: theme.spacing(1),
   marginBottom: theme.spacing(1),
  },
  appBarBottom: {
   top: "auto",
   bottom: 0,
  },
  })
);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, #1D91DA 0%, #1D91DA 50%, #00BFFF 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, #00A36C 0%, #34A56F 50%, #50C878 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <Icons.WebAsset />,
    2: <Icons.Assignment />,
    3: <Icons.ReportProblem />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const initialProjectValues = {
  name: "",
  resume: "",
  predictedValue: "",
  routine: 1,
  group: null,
  axis: null,
  initDate: null,
  deadLine: null,
  description: "<p></p>",
  responsible: null,
  responsibleSecretary: null,
};

export const CreateProjectContext = React.createContext();

export default function NewProject() {
  const classes = useStyles();
  const navigate = useNavigate()
  const { privilege } = React.useContext(UserContext);
  const [taskValue, setTaskValue]       = React.useState([]);
  const [gutValue, setGutValue]         = React.useState([]);
  const [projectValue, setProjectValue] = React.useState(initialProjectValues);
 
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Projeto", "Etapas", "GUT"];

  const handleNext  = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack  = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleReset = () => setActiveStep(0);

  function getStepContent(activeStep) {
    switch (activeStep) {
      case 0: return <TabProject/>;
      case 1: return <TabTask/>;
      case 2: return <TabGut/>;
      default: return null;
    }
  }

  React.useEffect(()=>{
   if(privilege.cityHall) return navigate('/projects')
  },[])

  return (
    <CreateProjectContext.Provider
    value={{handleNext, handleBack, handleReset, 
            taskValue, setTaskValue, 
            gutValue, setGutValue, 
            projectValue, setProjectValue}}
    >
    <Container className={classes.root} maxWidth="lg">
      <CurrentPageInfo primary="Novo projeto"/>
      <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />} className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Paper className={classes.paper} elevation={0}>
        {getStepContent(activeStep)} 
      </Paper>
    </Container>
    </CreateProjectContext.Provider>
  );
}