import React from "react";
import CustomTable from "../../../Components/CustomTable";
import View from "./View";

export default function ReadTable({data, error, load}) {
  const [rowId, setRowId] = React.useState(null)
  const options = {
    onRowClick: (rowData, rowState) => setRowId(rowData[0]),
  };

  return (
    <>
     {rowId !== null ? <View rowId={rowId} setRowId={setRowId} />:null}
     <CustomTable data={data} columns={columns} opt={options} error={error} load={load}/>
    </>
  );
}

const columns = [
  { name: "projectId", options: { display: "false" } },
  { name: "projectName",
    label: "Projeto",
    options: {filter: true, sort: true, },
  },
  { name: "projectResponsibleSecretary",
    label: "Secretaria",
    options: { filter: true, sort: true, width: 100, },
  },
  { name: "projectResponsible",
    label: "Responsável",
    options: { filter: true, sort: true, },
  },
  {
    name: "status",
    label: "Status",
    options: { filter: true, sort: true,
  }
  },
  { name: "predictedValue",
    label: "Valor Previsto",
    options: {filter: true, sort: true,
      customBodyRender: (value) => value !== ""?`R$ ${value}`: "Não definido"
      //customBodyRender: (value) => FormatRealNumber(Number(value))
    },
  },
  { name: "initDate",
    label: "Data inicial",
    options: { filter: true, sort: true, 
      customBodyRender: (value) => value !== ""?value: "Não definida"
      // customBodyRender: (value) => FormatDate(value, "Não definido"),
    },
  },
  { name: "deadLine",
    label: "Data limite",
    options: { filter: true, sort: true, 
       customBodyRender: (value) => value !== ""?value: "Não definida"
      // customBodyRender: (value) => FormatDate(value, "Não definido"),
    },
  },
];