import React from 'react'
import { Paper, LinearProgress, Grid, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import DialogViewTask from '../../../../Components/Screens/ViewTask';
import { CardKanban } from './Custom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root:{
    paddingTop: "1rem",
    [theme.breakpoints.up("xs")]: { minHeight: 550, },
    [theme.breakpoints.up("sm")]: { minHeight: 550, },
    [theme.breakpoints.up("md")]: { minHeight: 550, },
    [theme.breakpoints.up("lg")]: { minHeight: 550, },
    [theme.breakpoints.up("xl")]: { minHeight: 650, },
  },
  line: {
     
    [theme.breakpoints.up("xs")]: { maxWidth: 300, },
    [theme.breakpoints.up("sm")]: { maxWidth: 300, },
    [theme.breakpoints.up("md")]: { maxWidth: 330, },
    [theme.breakpoints.up("lg")]: { maxWidth: 330, },
    [theme.breakpoints.up("xl")]: { maxWidth: 380, },
  },
  card: {
   backgroundColor: "#1D91DA",
   overflowY: "auto",
   minHeight: "100%",
   [theme.breakpoints.up("xs")]: { maxHeight: 550, },
   [theme.breakpoints.up("sm")]: { maxHeight: 550, },
   [theme.breakpoints.up("md")]: { maxHeight: 550, },
   [theme.breakpoints.up("lg")]: { maxHeight: 550, },
   [theme.breakpoints.up("xl")]: { maxHeight: 700, },
  },
}));
 
function Kanban({list, updateData, load}) {
  const classes = useStyles();
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(true);

  const handleCardClick = (cardId) => {
     setRowId(cardId)
     setOpen(true)
  }

  const handleClose = ()=>{
    setRowId(null)
    setOpen(false)
  }

 return ( 
 <>
  {rowId && <DialogViewTask id={rowId} open={open} close={handleClose} updateData={updateData}/>}
  {load && <LinearProgress style={{zIndex:100, marginTop:-4}}/>}
 <Paper elevation={2} className={classes.root}>
 <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
  <Grid item xs={4} className={classes.line}>
   <Card className={classes.card}>
    <CardHeader style={{padding: "0.5rem 1rem 0rem 1rem"}} title={
     <Typography variant="h6" gutterBottom style={{color: "white", textAlign: "center", fontSize: "1rem"}}>
      A fazer
    </Typography>}/>
   
    <CardContent style={{  display: "grid", justifyContent: "center", padding: "0rem 0.5rem" }}>
     {list.filter(r=> r.statusId === "1").map(ret=>CardKanban({...ret, handleCardClick}))}
    </CardContent>
   </Card>
  </Grid>

  <Grid item xs={4} className={classes.line}>
   <Card className={classes.card}>
    <CardHeader style={{padding: "0.5rem 1rem 0rem 1rem"}} title={
     <Typography variant="h6" gutterBottom style={{color: "white", textAlign: "center", fontSize: "1rem"}}>
      Em progresso
    </Typography>}/>
  
    <CardContent style={{  display: "grid", justifyContent: "center", padding: "0rem 0.5rem" }}>
     {list.filter(r=> r.statusId === "2").map(ret=>CardKanban({...ret, handleCardClick}))}
    </CardContent>
   </Card>
  </Grid>

  <Grid item xs={4} className={classes.line}>
   <Card className={classes.card}>
    <CardHeader style={{padding: "0.5rem 1rem 0rem 1rem"}} title={
     <Typography variant="h6" gutterBottom style={{color: "white", textAlign: "center", fontSize: "1rem"}}>
      Concluídos
    </Typography>}/>
 
    <CardContent style={{  display: "grid", justifyContent: "center", padding: "0rem 0.5rem" }}>
     {list.filter(r=> r.statusId === "3").map(ret=>CardKanban({...ret, handleCardClick}))}
    </CardContent>
   </Card>
  </Grid>
  </Grid>
 </Paper>
 </>
  );
}

export default Kanban

