import React from "react";
 
import {
    createStyles,
    makeStyles,
    Container,
    Paper,
  } from "@material-ui/core";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import { ViewProjectContext } from "..";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "block",
      justifyContent: "center",
      alignItems: "center",    
    },
    paper:{
      padding: theme.spacing(1)
    }
  })
);

export default function Calendar() {
  const {infosData} = React.useContext(ViewProjectContext);
  const {name, canceled} = infosData
 const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="xl">
      <CurrentPageInfo title={`Calendário - ${name}`}/>
      <Paper className={classes.paper} elevation={3}>
 
      </Paper>
    </Container>
  );
}
 