import React from "react";
import { useParams } from "react-router-dom";

import { createStyles, makeStyles, Grid, Typography, Paper, Divider} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import api from "../../../../../services/Api";
import { truncarText } from "../../../../../Components/Helper/FormatValues";
import ViewTaskList from "../ViewTaskList";

export const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    margin: theme.spacing(2, 0, 0, 0),
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
  },
  title:{
    maxHeight: 40,
    padding: "0.5rem 0px",
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 300},
    [theme.breakpoints.up("md")]: {width: "100%", height: 350},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 350},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 400},
    paddingTop: "1rem",
  }
})
);

const GET_INFO = (id) => {
  return {
    method: "get",
    url: `graphic/projectSecretary.php?id=${id}`,
  //url: `dashboard/secretaryProgress.php`,
  };
};

const getPercentWidth = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}

 function ViewChart({data, setView}){
   const {concluidos, noprazo, fora, cancelados, secretarias, Ids} = data
  function calcSizeBar(){
    return getPercentWidth(710, secretarias.length, 45)
  }

   const series =
   [
   {name: 'No prazo'   , data: noprazo}, 
   {name: 'Em atraso'  , data: fora},
   {name: 'Concluídas' , data: concluidos},
   {name: 'Canceladas' , data: cancelados},
   ]

  const options ={
    grid: {
      show: true,
      borderColor: "#CCC",
      strokeDashArray: "3,4",
      position: "back",
      xaxis: {
        lines: {
          show: true
        }
      },
    },
    colors: ["#2E93fA", "#FFA500", "#66DA26", "#ff3330", "#e65c00"],
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          setView({
            name: "",
            responsible: null,
            responsibleSecretary: Ids[config.dataPointIndex],
            status: null,
            situation: config.seriesIndex+1,
            priority: null,
          })
          }
      },
    },
    labels: secretarias.map(r=> truncarText(r, 25)),
    plotOptions: {
      bar: {
        columnWidth: calcSizeBar(), 
        borderRadius: 5,
        horizontal: false,
       // distributed: false,
      },
    },
    xaxis: {
      type: 'category',
      categories: secretarias ,
      labels:{
        show: true,
        rotate: -25,
        trim: true,
        minHeight: 0,
        maxHeight: 60,
        style:{
         fontSize: "10px"
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      floating: true,
      offsetY: 0,  
    },
    fill: {
      opacity: 1
    }
  }
  return <ReactApexChart options={options} series={series} type="bar" width="100%" height="100%"/>
 }

const init = {
  concluidos: [],
  noprazo:[],
  fora:[],
  secretarias:[],
  Ids: [],
}

export default function ModalSecretary() {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = React.useState(init)
  const [view, setView] = React.useState(null)

  const handleClose =()=> setView(null)
  
 
  const updateData = async () => {
   await api(GET_INFO(id)).then((ret) => {
      const {data} = ret
      if (data === null) return

    const concluidos = data.map(r=> r.dados.concluidos)
    const noprazo    = data.map(r=> r.dados.noprazo)
    const fora       = data.map(r=> r.dados.fora)
    const cancelados = data.map(r=> r.dados.cancelados)

    const Ids = data.map(r=> Number(r.id)) 

    const secretarias = data.map(r=>
       `${r.name} - Total: ${Number(r.dados.concluidos)+
                             Number(r.dados.cancelados)+
                             Number(r.dados.noprazo)+
                             Number(r.dados.fora)}`)

    setData({concluidos, noprazo, fora, cancelados, secretarias, Ids})
  });
};

React.useEffect(() => {
  updateData();
}, []);

 return (
 <>   
  <ViewTaskList search={view} handleClose={handleClose} title="Secretarias"/>
  <Grid container className={classes.root}>
   <Grid item xs={12} className={classes.title}>
    <Typography gutterBottom variant="h6" align="center"> Secretarias </Typography>
    <Divider/>
   </Grid>
   <Grid item xs={12} className={classes.graphic}>
    <ViewChart data={data} setView={setView}/>
   </Grid>
  </Grid>
 </> 
 );
}
