import React from "react";
import * as Icons from "@material-ui/icons";
import { Grid, createStyles, makeStyles, Box, Fab, Tooltip, List, ListItem, ListItemIcon, Typography, ListItemText } from "@material-ui/core";
import DefaultModal, {
  DefaultModalContent,
} from "../../../Components/DefaultModal";
 
import api from "../../../services/Api";
import { ViewTextEditor } from "../../../Components/Editor";
import ConfirmDelete from "./ConfirmDelete";
import EditSecretary from "./Edit";
import { UserContext } from "../../../Context/UserContext";
 

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    padding: "1rem 0px 1rem 0px",
    margin: 0,
  },
  main:{
     minHeight: 250,
    [theme.breakpoints.up("xs")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},  
  }
})
);

export const GET_SECRETARYFORM_INFO = (id) => {
  return {
    method: "get",
    url: `secretary/description.php?id=${id}`,
  };
};

export const ViewModal = ({ id, open, setOpen, updateData }) => {
  const classes = useStyles();
  const { privilege } = React.useContext(UserContext);
  const [select, setSelect] = React.useState(null);
  const [openView, setOpenView] = React.useState(false);
  const [data, setData] = React.useState(null);

  const getData = async () => {
    await api(GET_SECRETARYFORM_INFO(id)).then((r) => {
      const buffer = r.data[0];
      setData([
        {
          icon: <Icons.AccountBalance/>,
          text: "Nome: ",
          data: buffer.name,
        },
        {
          icon: <Icons.Room />,
          text: "Endereço: ",
          data: buffer.address,
        },
        {
          icon: <Icons.Email/>,
          text: "Email: ",
          data: buffer.email,
        },
        {
          icon: <Icons.Phone />,
          text: "Telefone: ",
          data: buffer.phone,
        },
        
        {
          icon: <Icons.AccountBox/>,//
          text: "Responsável: ",
          data: buffer.responsible.name,
        },
        {
          icon: <Icons.CheckCircle />,
          text: "Status: ",
          data: buffer.status === "1" ? "Ativo" : "Inativo",
        },
        {
          icon: <Icons.Book/>,
          text: "Descrição: ",
          data: <ViewTextEditor value={buffer.description}/> ,
        },

      ]);
    });
  };

  const handleOpenView =(id)=>{
    setSelect(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setSelect(null)
  }
 
  const handleClose = () => {
    handleCloseView()
    setOpen();
  };

  const fullUpdate = ()=>{
    updateData()
    getData()
  }

  React.useEffect(() => {
    if (open) getData();
  }, [open]);

  const getSelectContent = React.useCallback((select) => { 
    switch (select) {
      case 0: return <ConfirmDelete id={id} open={openView} setOpen={handleCloseView} handleClose={handleClose} updateData={fullUpdate}/>
      case 1: return <EditSecretary id={id} open={openView} setOpen={handleCloseView} updateData={fullUpdate}/>
      default: return null;
    }
  }, [select]);

  return (
    <>
     {getSelectContent(select)}
      <DefaultModal
        loading={data === null}
        open={open}
        handleClose={setOpen}
        title={`Secretaria`}
        actionsTitle={
          <Box>
            <Tooltip placement="top" title="Apagar secretaria" aria-label="Apagar secretaria">
              <span>
                <Fab size="small" color="primary" aria-label="edit" style={{backgroundColor: "red"}}
                  disabled={!privilege.administrator}
                  onClick={()=>handleOpenView(0)}>
                  <Icons.Delete/>
                </Fab>
              </span>
            </Tooltip>
            <Tooltip placement="top" title="Editar secretaria" aria-label="Editar secretaria">
              <span>
                <Fab
                  style={{ marginLeft: "1rem" }}
                  size="small"
                  color="primary"
                  aria-label="edit"
                  disabled={!privilege.administrator}
                  onClick={()=>handleOpenView(1)}
                >
                  <Icons.Edit />
                </Fab>
              </span>
            </Tooltip>
          </Box>
        }
      >
        <DefaultModalContent dividers className={classes.main}>
         <Grid container className={classes.root}>
          <Grid item xs={12}>
           <List dense disablePadding>
           {data !== null && data.filter(r=> r.data !== null).map((value) => (
            <ListItem  alignItems="start" key={value.text}>
             <ListItemIcon >{value.icon}</ListItemIcon>
             <ListItemText style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
              primary={<Typography variant="h6" component="span">{value.text}</Typography>}
              secondary={
              <Typography
               component="span"
               style={{marginLeft: "0.5rem" }}
               color="textSecondary">
               {value.data} 
              </Typography>}/>
            </ListItem>))}
           </List>
          </Grid>
         </Grid>
        </DefaultModalContent>
      </DefaultModal>
    </>
  );
};
