import React from "react";
import { Typography, Button, DialogContentText} from "@material-ui/core";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";

export default function Confirm({value, handleNext, open, setOpen}) {
  return (
    <DefaultModal open={open} onClose={()=>setOpen(false)} title="Aviso">
      <DefaultModalContent>
      <DialogContentText style={{padding: "0.5rem"}}>
      <Typography component="h1" variant="subtitle1" gutterBottom>
        O valor total previsto das etapas ultrapassou o valor previsto do Projeto,
       </Typography>
       <Typography component="h2" variant="subtitle1" gutterBottom>
        deseja continuar assim mesmo?
       </Typography>
      </DialogContentText>
      </DefaultModalContent>
      <DefaultModalActions style={{marginRight:"0.5rem"}}>
        <Button variant="contained" color="primary" style={{marginRight: "0.5rem"}} onClick={()=>setOpen(false)}>
          Não
        </Button>
        <Button variant="contained" color="primary" autoFocus onClick={()=>handleNext()}>
          Sim 
        </Button>
      </DefaultModalActions>
    </DefaultModal>
  );
}
