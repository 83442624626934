import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Container,
  Tabs,
  Tab,
  Box,
  withStyles,
  createStyles,
} from "@material-ui/core";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";
import { Config } from "./Config";
import { PanelProject } from "./Config/PanelProject";
import { PanelUser } from "./Config/PanelUser";
 
const HorizontalColoredTab = withStyles({
  indicator: {
    background: "#0078B2",
    zIndex: 1,
  },
})(Tabs);

const ColoredTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: "none",
      "&:hover": {
        opacity: 1,
      },
      "&$selected": {
        fontWeight: theme.typography.fontWeightMedium,
        color: "black",
        zIndex: 2,
      },
    },
  })
)((props) => <Tab disableRipple {...props} />);


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
     {value === index && children}  
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    margin: "0px auto",
    padding: 10,
    borderRadius: 5,
    minHeight: 650,
  },
  rootHorizontal: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  horizontalTabDivider: {
   borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export default function Notification() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => setValue(newValue);

  const getActiveTab = React.useCallback((value) => { 
    switch (value) {
     case 0: return <TabPanel value={value} index={0}> <PanelProject/> </TabPanel>;
     case 3: return <TabPanel value={value} index={3}> <PanelUser/> </TabPanel>;
     default: return null;
    }
  }, []);

  return (
    <Container className={classes.root} maxWidth="xl">
      <CurrentPageInfo primary="Administrador" secondary="Notificações" />
      <Box className={classes.rootHorizontal}>
        <HorizontalColoredTab
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Resume project tabs"
          className={classes.horizontalTabDivider}>
          <ColoredTab label="Projeto" {...a11yProps(0)} />
          <ColoredTab label="Etapa"  {...a11yProps(1)} />
          <ColoredTab label="5W2H"    {...a11yProps(2)} />
          <ColoredTab label="Usuário" {...a11yProps(3)} />
        </HorizontalColoredTab>
      </Box>
      <Paper className={classes.paper} elevation={1}>
         {getActiveTab(value)} 
      </Paper>
    </Container>
  );
}
