import React from "react";
import { Paper, Grid, Divider, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LayoutContext } from '../../Components/Layout/LayoutMaster';
 
import HeaderProfile from "./Profile/HeaderProfile";
import { Menus } from "./Menu";
import UserProfile from "./Profile";
import MyProject from "./MyProject";
import MainTask from "./MyTask";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-evely",
    alignItems: "start",
  },
  menu:{
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "10px",
    boxShadow: "2px 2px 3px rgba(50, 50, 93, 0.11), 1px 2px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: "#FFFFFF",
    height: "100%", 
    padding: theme.spacing(0, 1, 0, 1),
  }, 
}));

const UserPainel = () => {
  const classes = useStyles();
  const [select, setSelect] = React.useState(0)
  const {setShowLayout, setListMenu, setOpen} = React.useContext(LayoutContext);
 
  React.useEffect(()=>{
    setShowLayout(true)
    setListMenu(undefined)
    setOpen(false) 
  },[])

  const getSelectContent = React.useCallback(( ) => { 
    switch (select) {
      case 0: return <UserProfile/>
      case 1: return <MyProject/>
      case 2: return <MainTask/>
      default: return null;
    }
  }, [select]);

  return (
  <>  
   <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2} >
     <Grid item xs={4} sm={4} md={2} lg={2} xl={2} >
      <Box className={classes.menu} >
       <HeaderProfile/>
       <Divider/>
       <Menus select={select} setSelect={setSelect}/>
      </Box> 
     </Grid> 

     <Grid item xs={8} sm={8} md={10} lg={10} xl={10} >
      {getSelectContent()}
     </Grid> 
 
   </Grid>
  </>
  )
}

export default UserPainel
