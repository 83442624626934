import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  createStyles,
  makeStyles,
  TextField,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";

import { NumberFormatCustom } from "../../Helper/FormatValues";
import api from "../../../services/Api";
import {
  GET_STATUS_TASK,
  POST_UPDATE_TASK,
  GET_RQSTFORMINFO_TASK,
} from "../../../services/options";
import { useSnackbar } from "notistack";
import { SelectApi } from "../../SelectsApi";
import { ButtonSuccess } from "../../ButtonSuccess";
import DefaultModal, {
  DefaultModalActions,
  DefaultModalContent,
} from "../../DefaultModal";
import ConfirmEditAtraso from "./ConfirmEditAtraso";
import { CustomDatePicker } from "../../CustomDatePicker";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("xs")]: {width: 300, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("sm")]: {width: 300, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("md")]: {width: 300, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("lg")]: {width: 300, padding: theme.spacing(0, 1, 0, 1),},
      [theme.breakpoints.up("xl")]: {width: 300, padding: theme.spacing(0, 1, 0, 1),},
    },
  })
);

const validationSchema = Yup.object({
  status: Yup.object()
    .shape({
      id: Yup.number().required(),
      name: Yup.string().required(),
    })
    .required("Obrigatório")
    .nullable(),
  executedDate: Yup.date().when("status.id", {
    is: 3,
    then: Yup.date().required("Obrigatório").nullable(),
  }),
  executedValue: Yup.number().when("status.id", {
    is: 3,
    then: Yup.number().required("Obrigatório").nullable(),
  }),
});

export default function DialogTaskUser({
  taskId,
  handleUpdate,
  dialogEdit,
  setDialogEdit,
  showConfirm,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [init, setInit] = React.useState(null);
  const [statusTask, setStatusTask] = React.useState({
    id: undefined,
    name: undefined,
  });

  const [editData, setEditData] = React.useState(null)
  const [openJustification, setopenJustification] = React.useState(false)

  const rqstInfo = async () => {
    await api(GET_RQSTFORMINFO_TASK(taskId))
      .then((resp) => {
        const data = resp.data[0];
        setInit({
          taskId: data.taskId,
          name: data.name,
          initDate: data.initDate,
          deadLine: data.deadLine,
          predictedValue: parseFloat(data.predictedValue, 2),
          description: data.description,
          responsibleSecretary: data.responsibleSecretary,
          responsible: data.responsible,
          priority: data.priority,
          executedDate: data.executeDate === "0000-00-00" ? "" : data.executeDate,
          executedValue: data.executeValue === "0.00" ? "0"    : parseFloat(data.executeValue, 2),
          situation: data.situation,
          status: data.status,
          auxStatus: data.status,
        });
        setStatusTask(data.status);
      })
      .catch((e) => {
        enqueueSnackbar(`Erro ao fazer requisição`, { variant: "error" });
      });
  };

  React.useEffect(() => {
    if (taskId !== null && dialogEdit === true) rqstInfo();
  }, [dialogEdit]);

  const submit = async (values, { setSubmitting }) => {
    const buffer = {
      taskId: values.taskId,
      name: values.name,
      initDate: values.initDate,
      deadLine: values.deadLine,
      predictedValue: !values.predictedValue ? 0 : values.predictedValue,
      description: values.description,
      responsibleSecretaryId: values.responsibleSecretary.id,
      responsibleId: values.responsible.id,
      priorityId: values.priority.id,
      executedDate: values.executedDate === undefined ? "0" : values.executedDate,
      executedValue: !values.executedValue ? 0 : values.executedValue,
      situation: values.situation,
      statusId: values.status.id,
      canceled: 0,
      stoped  : 0,
      justification: "",
    };

   if(showConfirm && buffer.statusId === "3"){
    setEditData(buffer)
    setopenJustification(true)
    setSubmitting(false)}
    else{
       await api(POST_UPDATE_TASK(buffer))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          handleUpdate();
          setSubmitting(false);
          handleClose();
          enqueueSnackbar("etapa atualizada !!!", { variant: "success" });
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Error ao atualizar etapa!!!`, { variant: "error" });
        }
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(`Error ao atualizar etapa ${error?.message} !!!`, {
          variant: "error",
        });
      });
    }
  };

  const handleClose = () => {
    setDialogEdit(false);
    setInit(null);
  };

  return (
   <> 
   {editData && <ConfirmEditAtraso 
                 open={openJustification} 
                 setOpen={setopenJustification} 
                 updateData={()=>{handleClose() 
                 handleUpdate()}} editData={editData}/>}
                 
    <DefaultModal
      open={dialogEdit}
      handleClose={handleClose}
      loading={init === null}
      title="Atualizar etapa"
    >
     <Formik
      initialValues={init}
      validationSchema={validationSchema}
      onSubmit={submit}>
      {(props) => (<Forms statusTask={statusTask} {...props} />)}
     </Formik>
    </DefaultModal>
   </> 
  );
}

const Forms = (props) => {
  const classes = useStyles()
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
    dirty,
    statusTask,
  } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
        <Grid container  className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <CustomDatePicker
              disabled={Boolean(
                Number(values.status?.id) < 3 ||
                  values.status === null ||
                  statusTask.id === "3"
              )}
              fullWidth
              id="executedDate"
              name="executedDate"
              label="Data de execução"
              onChange={(value) =>{
                setFieldValue("executedDate",  value || null )}}
              value={values.executedDate}
              error={touched.executedDate && Boolean(errors.executedDate)}
              helperText={touched.executedDate && errors.executedDate}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled={Boolean(
                Number(values.status?.id) < 3 ||
                  values.status === null ||
                  statusTask.id === "3"
              )}
              fullWidth
              variant="outlined"
              size="small"
              id="executedValue"
              name="executedValue"
              label="valor executado"
              InputProps={{
                autoComplete: "off",
                inputComponent: NumberFormatCustom,
              }}
              onChange={handleChange}
              value={values.executedValue}
              error={touched.executedValue && Boolean(errors.executedValue)}
              helperText={touched.executedValue && errors.executedValue}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectApi
              fullWidth
              disabled={Boolean(statusTask.id === "3")}
              id="status"
              name="status"
              onChange={(e, value) => {
                if (value?.id > values.status.id)
                  setFieldValue("status", value || null);

                if (Number(value?.id) <= 2) {
                  setFieldValue("executedValue", "");
                  setFieldValue("executedDate", "");
                }
              }}
              value={values.status}
              config={GET_STATUS_TASK()}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                variant: "outlined",
                label: "Status",
              }}
              error={touched.status && Boolean(errors.status)}
              helperText={touched.status && errors.status}
            />
          </Grid>
        </Grid>
      </DefaultModalContent>
      <DefaultModalActions style={{marginRight:"0.5rem"}}>
        <ButtonSuccess
          variant="contained"
          color="primary"
          type="submit"
          success={false}
          loading={isSubmitting}
          disabled={!dirty || isSubmitting}
        >
          Confirmar
        </ButtonSuccess>
      </DefaultModalActions>
    </form>
  );
};
