import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Container,
  Tabs,
  Tab,
  Box,
  withStyles,
  createStyles,
} from "@material-ui/core";

import StatisticsPanel from "./StatisticsPanel";
import ReportsPanel from "./Reports";
import CurrentPageInfo from "../../../Components/Helper/CurrentPageInfo";

const HorizontalColoredTab = withStyles({
  indicator: {
    background: "#0078B2",
    zIndex: 1,
  },
})(Tabs);

const ColoredTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: "none",
      "&:hover": {
        opacity: 1,
      },
      "&$selected": {
        fontWeight: theme.typography.fontWeightMedium,
        color: "black",
        zIndex: 2,
      },
    },
    selected: {},
  })
)((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`project-StatisticsPanel-${index}`}
      aria-labelledby={`project-StatisticsPanel-${index}`}
      {...other}
    >
      {value === index && (
        <> {children} </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `project-StatisticsPanel-${index}`,
    "aria-controls": `project-StatisticsPanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    border: `1px solid  ${theme.palette.divider}`,
    borderTop: 0,
    borderRadius: "0px 0px 10px 10px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    margin: "0px auto",
    padding: 10,
    minHeight: 650,
  },
  rootHorizontal: {
    borderRadius: "10px 10px 0px 0px",
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  horizontalTabDivider: {
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: "10px 10px 0px 0px",
   },
 }));

export default function ResumeProjects() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => setValue(newValue);

  function getActiveTab(value) {
    switch (value) {
     // case 0: return <TabPanel value={value} index={0}> <ActivityPanel />   </TabPanel>;
      case 0: return <TabPanel value={value} index={0}> <StatisticsPanel/>  </TabPanel>;
      case 1: return <TabPanel value={value} index={1}> <ReportsPanel/>     </TabPanel>;
      default: return null;
    }
  }

  return (
    <Container className={classes.root} maxWidth="xl">
      <CurrentPageInfo primary={`Resumo dos projetos`}/>
      <Box className={classes.rootHorizontal}>
        <HorizontalColoredTab
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Resume project tabs"
          className={classes.horizontalTabDivider}
        >
          {/* <ColoredTab label="Atividades"   {...a11yProps(0)} /> */}
          <ColoredTab label="Estatísticas" {...a11yProps(0)} />  
          <ColoredTab label="Relatórios"   {...a11yProps(1)} />
        </HorizontalColoredTab>
      </Box>
      <Paper className={classes.paper} elevation={0}>
       {getActiveTab(value)}
      </Paper>
    </Container>
  );
}
