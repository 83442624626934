import React from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { Fab, Tooltip} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/core";
import Table from "./Table";
import DialogGut from "./DialogGut";
import { CreateProjectContext } from "..";
import TabFinish from "../TabFinish";
import { ModalHelp } from "./Help";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "grid",
    },
  })
);

export const TabGut = () => {
  const { gutValue, setGutValue, handleBack } = React.useContext(CreateProjectContext);
  const [open, setOpen] = React.useState(false);
  const [help, setHelp] = React.useState(false);
  const [exit, setExit] = React.useState(false);

  const submit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setOpen(false);
    setGutValue([...gutValue, values]);
  };

  return (
    <Grid container spacing={1}>
      <DialogGut submit={submit} open={open} setOpen={setOpen} />
      <ModalHelp open={help} close={()=> setHelp(false)}/>
      <TabFinish open={exit} setOpen={setExit}/>
      
      <Grid item xs={12} style={{ paddingBottom: 10, display: "flex", justifyContent: "space-between" }}>
        <Tooltip placement="top" title="Novo GUT" aria-label="Novo GUT">
         <Fab size="small" color="primary" aria-label="Novo GUT" onClick={() => {setOpen(true)}}>
          <Icons.Add/>
         </Fab>
        </Tooltip>

        <Tooltip placement="top" title="Ajuda" aria-label="help">
          <span>
            <Fab
              size="small"
              color="primary"
              aria-label="Ajuda"
              onClick={() => setHelp(true)}
            >
              <Icons.Help/>
            </Fab>
          </span>
        </Tooltip>
        
      </Grid>

      <Grid item xs={12} style={{minHeight: 590}}>
        <Table data={gutValue} setData={setGutValue} />
      </Grid>

      <Grid item xs={12}>
       <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, marginTop: "1rem"}}>
        <Button 
        style={{ width: 120, marginRight: "1rem" }}
        variant="contained" 
        color="primary" 
        type="submit" 
        onClick={handleBack}>
         Voltar
        </Button>
        <Button
        style={{ width: 120,}}
        variant="contained" 
        color="primary" 
        type="submit" 
        disabled={!gutValue.length}
        onClick={() => {setExit(true)}}> 
        Finalizar
       </Button>
      </Box>
      </Grid>
    </Grid>
  );
};
