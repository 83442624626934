import React from "react";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, Grid, Typography, Divider } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "grid",
    boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    border: `2px solid  ${theme.palette.divider}`,
    borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: 5,
    margin: 0,
  },
  graphic:{
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 250},
    [theme.breakpoints.up("md")]: {width: "100%", height: 250},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 320},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 350},
  }
})
);

function ViewChart({series,}){
  const options = {
    chart:{
     
    },
    labels: ["No prazo", "Em atraso", "Concluído", "Cancelado", ],//"Projeto cancelado" "#CCCC"
    colors: ["#2E93fA", "#FFA500", "#66DA26", "#ff3330", ],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: "25px",
              fontWeight: 550,
            },
            value: {
              fontSize: "25px",
              fontWeight: 550,
              formatter: function (val) {
                return val;
              }
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                let series = w.config.series;
                const result = series.reduce(function add(sum, currValue) {
                  return sum + currValue;
                }, 0);
                return result;
              }
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
    },
  };
  return <ReactApexChart options={options} series={series} type="donut" width={"100%"} height={"100%"}
/>
}

export const Status = ({value}) => {
  const classes = useStyles();
  const { id } = useParams();
 
 return (
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> Status das ações </Typography>
   <Divider/>
  </Grid>
  <Grid item xs={12} className={classes.graphic}>
   <ViewChart series={value}/>
  </Grid>
 </Grid>
);
}

