import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  DialogContentText,
} from "@material-ui/core";

import { CreateProjectContext } from ".";
import api from "../../../services/Api";
import { POST_INSERT_PROJECT } from "../../../services/options";
import { useSnackbar } from "notistack";
import { ShowErrorCreateProject } from "../../../Components/ShowInfos";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../Components/DefaultModal";
import { ButtonSuccess } from "../../../Components/ButtonSuccess";

const TabFinish = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { taskValue, gutValue, projectValue, handleReset } = React.useContext(CreateProjectContext);
  const { enqueueSnackbar } = useSnackbar();
  const [load, setLoad] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleSubmit = async () => {
    setLoad(true);

    const buffer = {
      project: projectValue,
      gut: gutValue,
      task: taskValue,
    };

   await api(POST_INSERT_PROJECT(buffer))
      .then(r=> {
       setLoad(false);
     if(r.data.status === "error"){
        enqueueSnackbar(`Erro ao criar projeto`, { variant: "Error" });
        setError(r.data);
     }else if(r.data.status === "success"){
        setOpen(false);
        enqueueSnackbar("Projeto criado !!!", { variant: "success" });
        navigate(`/projects/view/${r.data.id}/resume/`);
      }
 
      })
      .catch((e) => {
        setLoad(false);
        setError(e.message);
        enqueueSnackbar(`Erro ao criar projeto ${e.message}`, {
          variant: "Error",
        });
      });
  };

  const handleExit = ()=>{
    setError(null);
    setOpen(false)
  }

  return (
    <DefaultModal
      open={open}
      handleClose={() => handleExit()}
      title={error === null ? "Todas etapas concluídas!" : "Erro ao criar projeto!"}
    >
      <DefaultModalContent dividers style={{width: 380, padding:"2rem"}}>
       <DialogContentText style={{margin: "1.5rem"}}>
     
            {error === null ? (
              <Typography >Deseja realmente criar o projeto?</Typography>
            ) : (
              <Box>
                {error?.projectError > 0?<ShowErrorCreateProject opt={0} value={error.projectError}/> :null}
                {error?.taskError    > 0?<ShowErrorCreateProject opt={1} value={error.taskError}/> :null}
                {error?.gutError     > 0?<ShowErrorCreateProject opt={2} value={error.gutError}/> :null}
              </Box>
            )}
        </DialogContentText>
      </DefaultModalContent>

      <DefaultModalActions style={{marginRight: "0.5rem"}}>
        <Button
          style={{minWidth: 110, marginRight: "0.5rem", backgroundColor: "#a9d4ff" }}
          size="small"
          variant="contained"
          onClick={handleReset}
        >
          Revisar
        </Button>
        <ButtonSuccess
         style={{ minWidth: 130 }}
         variant="contained"
         color="primary"
         size="small"
         success={false}
         disabled={load}
         loading={load}
         onClick={()=>handleSubmit()}
        > Criar projeto
        </ButtonSuccess>
      </DefaultModalActions>
    </DefaultModal>
  );
};

export default TabFinish;
