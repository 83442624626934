import React from 'react';
import { useParams } from "react-router-dom";
import * as Icons from "@material-ui/icons";
import {Fab, List, Typography, Box, Grid, Paper, Avatar, makeStyles, Tooltip, TextField, ListItem, ListItemText, ListItemAvatar, ListItemIcon, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { ViewTextEditor,  } from '../../../../Components/Editor';
import { useSnackbar } from 'notistack';
import NewComment from './NewComment'; 
import {EditComment} from "./EditComment";
import useAxios from '../../../../Context/Hooks/useAxios';
import { FormatDate } from '../../../../Components/Helper/FormatValues';
import { UserContext } from '../../../../Context/UserContext';
import ConfirmDelete from './Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    margin: "0.5rem auto",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("sm")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("lg")]: {width: 550, padding: theme.spacing(0, 1, 0, 1),},
    [theme.breakpoints.up("xl")]: {width: 600, padding: theme.spacing(0, 1, 0, 1),},
  },
  main:{
    border: `1px solid  ${theme.palette.divider}`,
    borderRadius: "10px",
    boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    alignContent: "flex-start",
    alignItems  : "flex-start",
  },
  list: {
    minHeight: "530px",
    maxHeight: "530px",
    // [theme.breakpoints.up("xs")]: {minHeight: 530, maxHeight: 530},
    // [theme.breakpoints.up("sm")]: {minHeight: 530, maxHeight: 530},
    // [theme.breakpoints.up("md")]: {minHeight: 530, maxHeight: 530},
    // [theme.breakpoints.up("lg")]: {minHeight: 530, maxHeight: 530},
    // [theme.breakpoints.up("xl")]: {minHeight: 530, maxHeight: 530},
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
}));

const ItemList=(props)=>{
  const [subMenu, setSubMenu] = React.useState(null);
  const {id, name, date, secretary, accessLevel, msg, handleSelect} = props;
  return(
    <Paper elevation={2} style={{margin: "0rem 1rem 1rem 1rem", borderRadius: "0.6rem"}}>
     <ListItem alignItems="flex-start">
      <ListItemAvatar> 
        <Avatar> 
         <Typography variant="h6"> {name && name[0]} </Typography>
        </Avatar> 
      </ListItemAvatar>
       <ListItemText
       primary={
      <Box style={{justifyContent: "space-between", display:"flex"}}>
      <Box>
       <Typography variant="body1" style={{fontWeight: "bold"}}>{name}</Typography>
       <Typography variant="subtitle2"> {`${secretary} - ${accessLevel}`} </Typography>
      </Box>  

      <Tooltip placement="top" title="opções" aria-label="opções">     
       <Fab size="small" style={{transform: 'scale(0.6)', minWidth:"2.6rem"}} 
       onClick={(event)=>setSubMenu(event.currentTarget)}
       >
        <Icons.MoreVert/>
       </Fab>
      </Tooltip>
      <Menu
        id={`menu-comment-${id}`}
        anchorEl={subMenu}
        keepMounted
        disableAutoFocusItem
        open={Boolean(subMenu)}
        onClose={()=>setSubMenu(null)}
      >
        <MenuItem onClick={()=>{
          handleSelect({...props, action:"edit"})
          setSubMenu(null)
          }}>Editar comentário
          </MenuItem>
        <MenuItem onClick={()=>{
          handleSelect({...props, action:"delete"})
          setSubMenu(null)
          }}>Apagar comentário</MenuItem>
      </Menu>
      </Box>}
      secondary={
       <>
        <Box style={{minHeight:"30px",maxHeight:"120px", overflow: "auto", width:"100%", marginTop: "0.5rem"}}>
         <ViewTextEditor value={msg}/>  
        </Box>
        <Box>
         <Typography variant="caption"> {date && FormatDate(date)} </Typography>  
        </Box>
       </>
       }
      />
    </ListItem>
    </Paper>
  )
}
 
const NoItem =({loading})=>{
  return(
  <ListItem button>
   <ListItemText
    primary={       
    <Typography
      component="span"
      variant="subtitle1"
      style={{ display: "flex", textAlign:"center", justifyContent:"center",}}
      color="textPrimary"
    >
    {loading ? <CircularProgress/>: "Sem comentários !"}
    </Typography>}/>
   </ListItem>
   )
}

export const GET_LIST_COMMENT = (id) =>{
  return { 
    method: 'get',
    url: `project/observationlist.php?id=${id}`,
  }
}

export function ObservationPanel(){
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, request} = useAxios();
  const [data, setData] = React.useState([])
  const {userInfo, privilege} = React.useContext(UserContext);
  
  const [select  , setSelect] = React.useState(null);
  const [info    , setInfo] = React.useState(null);
  const [openView, setOpenView] = React.useState(false);

  const handleSelect =(props)=>{
    const {id, msg, action, userid} = props;
    if(action === "delete" && (privilege.administrator || userid === userInfo.id)){
      setInfo({id, msg})
      handleOpenView(1)//setou pra abrir o modal de deletar
      return
   }

    if(action === "edit" && (privilege.administrator || userid === userInfo.id)){
       setInfo({id, msg})
       handleOpenView(2)//setou pra abrir o modal de editar  
       return
    }
   enqueueSnackbar(`Não tem permissão !!!`, { variant: "warning" }); 
  }

  const handleUpdate = React.useCallback(async () => {
    await request(GET_LIST_COMMENT(id)).then((r) => {
        const { data } = r;
        setData(data);
      })
      .catch((e) => {
        setData([]);
      });
  }, [id]);

  React.useEffect(()=>{
   handleUpdate()
  },[])

  const handleOpenView =(id)=>{
    setSelect(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setInfo(null)
    setSelect(null)
  }

  const getSelectContent = React.useCallback((select) => { 
    switch (select) {
      case 0: return <NewComment open={openView} setOpen={handleCloseView} handleUpdate={handleUpdate}/> 
      case 1: return (info && <ConfirmDelete id={info.id} open={openView} setOpen={handleCloseView} handleUpdate={handleUpdate}/> )
      case 2: return (info && <EditComment   id={info.id} msg={info.msg} open={openView} setOpen={handleCloseView} handleUpdate={handleUpdate}/> )
      default: return null;
    }
  }, [select, info]);
  
return(
 <>
 {getSelectContent(select)}
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.main}>
  <ListItem alignItems="center" style={{ margin: "0.5rem 0px 0.5rem 0px",}}>
    <ListItemIcon> 
     <Avatar > 
      <Typography variant="h6">
       {userInfo?.name[0]}
      </Typography>
     </Avatar>
    </ListItemIcon>
    <ListItemText
     primary={
      <TextField  
       fullWidth
       variant="outlined"
      // margin="normal"
       size="small"
       id="observation"
       name="observation"
       defaultValue="Faça um comentário"
       value="Faça um comentário"
       onClick={()=>handleOpenView(0)}/>} 
     /> 
   </ListItem>
  <List dense className={classes.list}>
  {data !== null && data.length > 0 ? (
    data.map((opt) => (
     <ItemList
      key={opt.id}
      id={opt.id} 
      name={opt.name}
      date={opt.date}
      userid={opt.userid}
      secretary={opt.secretary}
      accessLevel={opt.accessLevel}
      msg={opt.msg}
      handleSelect={handleSelect}/>))
      ) : <NoItem loading={loading}/>}  
  </List>
  </Grid>
 </Grid>
 </>
)
}