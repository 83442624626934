import React from "react";
import CustomTable from "../../../Components/CustomTable";
import { ViewModal } from "./View";
 

export default function ReadTable({ data, updateData, error, load}) {
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(false);

  const handleOpen = (id)=>{
    setRowId(id)
    setOpen(true)
  }

  const handleClose =()=>{
    setRowId(null)
    setOpen(false)
  }
 
const columns = [
  { name: "id", options: { display: "false" } },
  { name: "name", label: "Nome" },
];

const options ={
  search: true,
  print: true,
  viewColumns: true,
  elevation: 0,
  onRowClick: (rowData, rowState) => handleOpen(rowData[0])
}

 return (
  <>
  {open && 
   <ViewModal id={rowId} open={open} setOpen={handleClose} updateData={updateData}/>}
   <CustomTable data={data} columns={columns} opt={options} error={error} load={load}/>
  </>);
}
