import React, { useEffect } from "react";
import * as Icons from "@material-ui/icons";

import {
  createStyles,
  makeStyles,
  LinearProgress,
  Grid,
  Divider,
  Typography,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button
} from "@material-ui/core";
 
import { ShowPriority, ShowSituation } from "../../ShowInfos";
import { FormatDate, FormatRealNumber } from "../../Helper/FormatValues";
import { GET_RQSTINFO_TASK } from "./../../../services/options";
import { UserContext } from "../../../Context/UserContext";
import api from "./../../../services/Api";
import DialogTaskUser from "./EditUser";
import DialogTaskAdmin from "./EditAdmin";
import { ViewTextEditor } from "../../Editor";
import DefaultModal, { DefaultModalContent } from "../../DefaultModal";
import DialogTaskSend from "./TaskSend";
import ReOpenTask from "./ReOpen";
import ResumeTask from "./Resume";
import CancelTask from "./Cancel";
import PauseTask from "./Pause";
import { SpeedDial, SpeedDialAction, SpeedDialIcon  } from "@material-ui/lab";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "grid",
      margin: 0,
      paddingTop: 0, 
    },
    main:{
      [theme.breakpoints.up("xs")]: { width: 390, },   
      [theme.breakpoints.up("sm")]: { width: 490, },    
      [theme.breakpoints.up("md")]: { width: 670, },  
      [theme.breakpoints.up("lg")]: { width: 700, },  
      [theme.breakpoints.up("xl")]: { width: 930, },   
    },
    speedDial: {
      
      "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
        bottom: 0,
        right: 0
      },
      "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
        top: 0,
        left: 0
      }
    },
  })
);



 
export default function DialogViewTask({id, open, close, updateData}) {
  
  const classes = useStyles();
  const { privilege, userInfo } = React.useContext(UserContext);
  const [select, setSelect] = React.useState(null)
  const [openView, setOpenView] = React.useState(false)
  const [openDial, setOpenDial] = React.useState(false);
 
  const [data, setData] = React.useState(null);

  const isCanceledTask    = data?.canceled || data?.stopedProject;
  const isNotEdit         = data?.canceledProject || data?.stopedProject;
  const isPauseTask       = data?.stoped;
  const isAtrasado        = data?.situation ===  "1"
  const isConcluido       = data?.situation ===  "2"


  const getData = React.useCallback(async () => {
    await api(GET_RQSTINFO_TASK(id)).then((resp) => {
        const { data } = resp;
        setData({
          name: data.name,
          responsibleSecretary: data.responsibleSecretary,
          responsible: data.responsible,
          priority: data.priority,
          situation: data.situation,
          status: data.status,
          predictedValue: data.predictedValue,
          executedValue: data.executeValue,
          estimatedTime: data.estimatedTime,
          description: data.description,
          initDate: data.initDate,
          executedDate: data.executeDate,
          deadLine: data.deadLine,
          created: data.createDate,
          updated: data.alterDate,
          //canceled: data.situation === "3" || data.situation === "4" ?true:false,
          canceled:       data.canceled === "1" ? true : false,
          stoped:         data.stoped === "1" ? true : false,

          stopedProject  : data.stopedProject === "1" ?true:false,
          canceledProject: data.canceledProject === "1"?true:false,
          justification  : data.justification,
          projectId: data.projectId,
          projectName: data.projectName,
        });
      })
      .catch((error) => {
        setData(null);
        return false;
      });
  }, [data]);

  React.useEffect(() => {
    if (open) {
      getData()  
      console.log('Esse é o getdata:  ', (getData(id)));
    }
  }, [open]);

  const handleClose = () => {
    close()
  };

  const handleOpenView =(id)=>{
    setSelect(id)
    setOpenView(true)
  }

  const handleCloseView =()=>{
    setOpenView(false)
    setSelect(null)
  }

  const handleUpdate = () => {
    getData();
    updateData();
  };

  const handlActionsOpen = (e) => {
    if(e.type === "mouseenter" || e.type === "click")
    setOpenDial(true);
  };

  const handlActionsClose = (e) => {//mouseleave
    if(e.type === "mouseleave")
    setOpenDial(false);
  };

  const getSelectContent = React.useCallback((select) => { 
    switch (select) {
      case 0: return <DialogTaskUser  taskId={id} handleUpdate={handleUpdate} dialogEdit={openView} setDialogEdit={handleCloseView} showConfirm={isAtrasado}/>;
      case 1: return <DialogTaskAdmin taskId={id} handleUpdate={handleUpdate} dialogEdit={openView} setDialogEdit={handleCloseView} />
      case 2: return <DialogTaskSend  taskId={id} handleUpdate={handleUpdate} open={openView} setOpen={handleCloseView}/>
      case 3: return <ReOpenTask      taskId={id} open={openView} setOpen={handleCloseView} updateData={handleUpdate} />
      case 4: return <ResumeTask      taskId={id} open={openView} setOpen={handleCloseView} updateData={handleUpdate} />
      case 5: return <CancelTask      taskId={id} open={openView} setOpen={handleCloseView} updateData={handleUpdate} />
      case 6: return <PauseTask       taskId={id} open={openView} setOpen={handleCloseView} updateData={handleUpdate} />
      default: return null;
    }
  }, [select]);

  const actions = [
    { icon: <Icons.Edit />, 
      name: 'Atualizar etapa',
      option: 0, 
      hide:     isCanceledTask || isPauseTask || isNotEdit, 
      disabled: privilege.cityHall || (userInfo.secretary !== data?.responsibleSecretary && !privilege.administrator)},

    { icon: <Icons.Settings />, 
      name: 'Administrador',
      option: 1, 
      hide:     isCanceledTask || isNotEdit, 
      disabled: !privilege.administrator && !privilege.collaboratorNv1},  

    { icon: <Icons.PlayCircleFilled />, 
      name: 'Continuar etapa',
      option:   3, 
      hide:     !isCanceledTask , 
      disabled: privilege.cityHall || privilege.collaboratorNv2},    

    { icon: <Icons.PlayCircleFilled />, 
      name: 'Reabrir etapa',
      option: 4, 
      hide:    !isPauseTask , 
      disabled: privilege.cityHall || privilege.collaboratorNv2},   
      
    { icon: <Icons.Cancel/>, 
      name: 'Cancelar etapa',
      option: 5, 
      hide    : isCanceledTask || isPauseTask || isNotEdit, 
      disabled: privilege.cityHall || privilege.collaboratorNv2},
      
    { icon: <Icons.PauseCircleFilled/>, 
      name: 'Suspender etapa',
      option: 6, 
      hide    : isCanceledTask || isPauseTask || isNotEdit || isConcluido, 
      disabled: privilege.cityHall || privilege.collaboratorNv2},    
    ]

  
  return (
   <>
  {getSelectContent(select)}
  {data !== null ?
   <DefaultModal open={open}  handleClose={handleClose} title={`Projeto: ${data?.projectName}`}  maxWidth="md" xs={12}  >
    <DefaultModalContent dividers className={classes.root}> 
     <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.main} spacing={1}>
      <Grid item xs={6} style={{ display:"flex", margin: 0, padding:0 }}>
        <span>
         <SpeedDial
          ariaLabel="SpeedDialClick"
          className={classes.speedDial}
          icon={<SpeedDialIcon icon={<Icons.Settings/>}/>}
          direction="right"
          FabProps={{size: "small", disabled: isNotEdit }}
          onClose={handlActionsClose}
          onOpen={handlActionsOpen}
          open={openDial} 
        >
        {actions.filter(r=> !r.hide).map((action) => (
         <SpeedDialAction
          tooltipPlacement="top"
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          disabled={action.disabled}
          hidden={action.disabled}
          onClick={()=>handleOpenView(action.option)}/>
        ))}
     
        </SpeedDial>
       </span>  
        </Grid>
        <Grid item xs={6} style={{ display:"flex", margin: 0, padding:0, justifyContent: "right"}}>
          <span style={{ justifyContent: "flex-end"}}>
            <Button style={{ background: "#1D91DA", border: "none", color: "#fff", padding: "0.5rem", borderRadius: "1.5rem", margin: "1rem"}}>
              <a href={`/projects/view/${data?.projectId}/resume`}>Ir ao projeto</a>
            </Button>
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <CardDescription description={data.description} />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}  xl={7}>
          <ShowInfos data={data} />
        </Grid>
      </Grid>
    </DefaultModalContent>    
   </DefaultModal>:<LinearProgress style={{zIndex:100, marginTop:-4}}/>}
 </>
  );
}

const CardDescription = ({ description }) => {
  return (
    <Card style={{ width: "100%", minHeight: 200, maxHeight: 400,  }}>
      <Typography variant="h6" style={{ margin: "5px 0px 5px 10px" }}>
        Descrição
      </Typography>
      <Divider />
      <ViewTextEditor value={description} isTheme/>
    </Card>
  );
};

const ShowInfos = ({ data }) => {
  const {
    responsibleSecretary,
    responsible,
    situation,
    predictedValue,
    executedValue,
    executedDate,
    estimatedTime,
    initDate,
    deadLine,
    priority,
    status,
    created,
    updated,
  } = data;
  const dias = Math.abs(estimatedTime)
  const dataInfo = [
    {
      icon: <Icons.Person />,
      text: "Responsável: ",
      data: responsible,
    },
    {
      icon: <Icons.AccountBalance />,
      text: "Secretaria responsável: ",
      data: responsibleSecretary,
    },
    {
      icon: <Icons.LocalAtm />,
      text: "Valor previsto: ",
      data: FormatRealNumber(predictedValue),
    },
    {
      icon: <Icons.AttachMoney />,
      text: "Valor executado: ",
      data: FormatRealNumber(executedValue),
    },
    {
      icon: <Icons.Event />,
      text: "Data inicial: ",
      data: FormatDate(initDate, "Não definida"),
    },
    {
      icon: <Icons.EventBusy />,
      text: "Data limite: ",
      data: FormatDate(deadLine, "Não definida"),
    },
    {
      icon: <Icons.EventAvailable />,
      text: "Data de execução: ",
      data: FormatDate(executedDate, "Não definida"),
    },
    {
      icon: <Icons.Update />,
      text: "Tempo estimado: ",
      data: estimatedTime < 0 ? dias <= 1 ? `${dias} dia`:`${dias} dias`:null,
    },
    {
      icon: <Icons.CheckCircle />,
      text: "Status: ",
      data: status ,
    },
    {
      icon: <Icons.Help />,
      text: "Situação: ",
      data:
        situation !== null ? (
          <ShowSituation value={Number(situation)} />
        ) : (
          <ShowSituation value={0} />
        ),
    },
    {
      icon: <Icons.PriorityHigh />,
      text: "Prioridade: ",
      data:
        priority !== null ? (
          <ShowPriority value={priority} />
        ) : (
          <ShowPriority value={"Baixa"} />
        ),
    },
  ];

  const timeInfo = [
    {
      icon: <Icons.EventAvailable />,
      text: "Criado: ",
      data: FormatDate(created, "Não definido"),
    },
    {
      icon: <Icons.DateRange />,
      text: "Atualizado: ",
      data: FormatDate(updated, "Não definido"),
    },
  ];

  return (
    <>
      <List dense disablePadding>
        {dataInfo.filter(r=> r.data !== null).map((value) => (
       <ListItem  alignItems="start" key={value.text}>
       <ListItemIcon >{value.icon}</ListItemIcon>
       <ListItemText
       style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
       primary={<Typography variant="h6" component="span">{value.text}</Typography>}
       secondary={
        <Typography
          component="span"
         // variant="body2"
          style={{marginLeft: "0.5rem" }}
          color="textSecondary"
        >
        {value.data} 
        </Typography>
      }/>
      </ListItem>
    ))}
  </List>
       <Divider style={{margin: "0.5rem 0px 0.5rem 0px"}}  variant="inset" />
       <List dense disablePadding>
        {timeInfo.map((value) => (
          <ListItem  alignItems="start" key={value.text}>
          <ListItemIcon >{value.icon}</ListItemIcon>
          <ListItemText
          style={{alignItems: "start", textAlign:"start", margin: 0, padding: 0}}
          primary={<Typography variant="h6" component="span">{value.text}</Typography>}
          secondary={
           <Typography
             component="span"
            // variant="body2"
             style={{marginLeft: "0.5rem" }}
             color="textSecondary"
           >
           {value.data} 
           </Typography>
         }/>
         </ListItem>
       ))}
     </List>
    </>
  );
};

DialogViewTask.defaultProps = {
  updateData: () => {},
};
