import React from "react"
import DefaultModal from "../../../../Components/DefaultModal";
import FormGUT from "../../../../Components/Screens/FormGUT";
 
export default function DialogGut({ submit, rowData, open, setOpen, title }) {
  return (
    <DefaultModal open={open} handleClose={()=>setOpen(false)} title={title ? title: "Novo GUT"}>
      <FormGUT submit={submit} initialValues={rowData}/>
    </DefaultModal>
  );
}
