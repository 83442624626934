import React from "react";
import { Typography, Button, TextField, DialogContentText} from "@material-ui/core";
import { CreateProjectContext } from "..";
import { useSnackbar } from "notistack";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from "../../../../Components/DefaultModal";

export default function DuplicTask({index, open, setOpen}) {
    const {taskValue, setTaskValue} = React.useContext(CreateProjectContext);
    const { enqueueSnackbar } = useSnackbar();
    const [qnt, setQnt] = React.useState(1)
    const value = taskValue[index]

    function handleDup(){
        const itens = taskValue.map((value) => value);
        for (let i = 0; i < qnt; i++) {
            itens.push({...value, name: `cópia ${i+1} - ${value.name}`})
          }
          setTaskValue(itens)
          setOpen(false)
          enqueueSnackbar("Etapa duplicada !!!", { variant: "success" });
    }

    function handleChange(e){
        if(e.target.value >0) setQnt(e.target.value)
    }
 
  return (
    <DefaultModal open={open} onClose={()=>setOpen(false)} title="Deseja duplicar a etapa?">
    <DefaultModalContent dividers style={{padding: "1rem", width: "350px", alignItems: "center", alignContent: "center", textAlign: "center"}}> 
      <DialogContentText style={{padding: "0.1rem"}}> 
      <Typography>
      Selecione a quantidade de etapas que receberá a cópia desta etapa
      </Typography>  
      </DialogContentText>  
       <TextField
            style={{marginTop: '1rem', marginBottom: "1rem",maxWidth: "35%"}}       
            type='number'     
            variant="outlined"
            margin="normal"
            size="small"
            id="name"
            label="Quantidade"
            name="name"
            autoFocus
            inputProps={{ autoComplete: 'off' }} 
            onChange={handleChange}
            value={qnt}
          />
      </DefaultModalContent>

      <DefaultModalActions style={{marginRight: "0.5rem"}}>
        <Button variant="contained" color="primary" style={{marginRight: "0.5rem"}} onClick={()=>setOpen(false)}>
          Não
        </Button>
        <Button variant="contained" color="primary" onClick={()=>handleDup()}>
          Sim 
        </Button>
      </DefaultModalActions>
    </DefaultModal>
  );
}
