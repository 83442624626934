import React from "react";
import { convertToHTML } from 'draft-convert';
import { Formik } from "formik";
import * as Yup from "yup";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../Components/DefaultModal'
import { makeStyles, createStyles } from "@material-ui/core/styles";
import * as Icons from "@material-ui/icons";
import { TextEditor } from "../../../../Components/Editor";
import { Fab } from "@material-ui/core";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
})
);

const validationSchema = Yup.object().shape({
  msg: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
});
 
export const POST_UPDATE_OBSERVATION = (body) =>{
  return {
    url: "project/observationupdate.php",
    method: 'post',
    data: body,
  };
}

export const EditComment = ({id, msg, open, setOpen, handleUpdate}) => {
 
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
    const buffer ={
     id : values.id,
     msg: values.msg,
    }
    
    await api(POST_UPDATE_OBSERVATION(buffer))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          handleUpdate();
          setOpen(false);
          enqueueSnackbar("Comentário editado !!!", { variant: "success" });
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Erro ao editar !!!`, { variant: "Error" });
        }
      })
      .catch((r) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ao editar !!!`, { variant: "Error" });
      });
  };

  return (
   <DefaultModal
   open={open}
   handleClose={setOpen}
   title="Editar comentário">
   <Formik
    initialValues={{id, msg}}
    validationSchema={validationSchema}
    onSubmit={submit}>
     {(props) => <Forms {...props}/>}
   </Formik>
   </DefaultModal>
  )
}


const Forms = (props) => {
  const classes = useStyles();
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers className={classes.root}>
        <TextEditor
          id="msg"
          name="msg"
          label="Faça um comentário"
          onChange={(event) => {setFieldValue("msg", convertToHTML(event.getCurrentContent()))}}
          value={values.msg}
          error={touched.msg && Boolean(errors.msg)}
          helperText={touched.msg && errors.msg}
        />
      </DefaultModalContent>
      <DefaultModalActions >
       <Fab variant="extended" size="small" color="primary" aria-label="add" style={{padding: "0.6rem", width:"100%"}} 
        type="submit" 
        disabled={isSubmitting}>
        <Icons.Send style={{marginRight: "0.5rem"}}/> 
         Enviar
       </Fab>
      </DefaultModalActions>
    </form>
  );
};