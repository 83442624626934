import React from "react";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, Grid, Paper, Divider, Typography } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import api from "../../../../../services/Api";

export const useStyles = makeStyles((theme) =>
createStyles({
  root: {
   display: "grid",
   margin: theme.spacing(2, 0, 0, 0),
   boxShadow: "0px 1px 2px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
   border: `2px solid  ${theme.palette.divider}`,
   borderRadius: "0.6rem",
  },
  title:{
    maxHeight: 40,
    padding: "0.5rem 0px",
    margin: 0,
  },
  graphic:{
   
    [theme.breakpoints.up("xs")]: {width: "100%", height: 250},
    [theme.breakpoints.up("sm")]: {width: "100%", height: 300},
    [theme.breakpoints.up("md")]: {width: "100%", height: 350},
    [theme.breakpoints.up("lg")]: {width: "100%", height: 350},
    [theme.breakpoints.up("xl")]: {width: "100%", height: 400},
  }
})
);

const getPercentWidth = (w, n, x) => {
  const percentWidth = Math.round((x / (w / n)) * 100)
  return `${percentWidth}%`
}
 
//https://back.draftmanagementproject.com/api/graphic/projectTask.php?id=111
const GET_INFO = (id) => {
  return {
    method: "get",
    url: `graphic/projectTask.php?id=${id}`,
  };
};

function ViewChart({buffer, sizeBar}) {
 const series =[
  { 
    data: buffer,
  },
]

const locales = [{
  "name": "pt-BR",
  "options": {
    "months": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    "shortMonths": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    "days": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    "shortDays": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sext", "Sáb"],
    "toolbar": {
        "exportToSVG": "Download SVG",
        "exportToPNG": "Download PNG",
        "menu": "Menu",
        "selection": "Seleção",
        "selectionZoom": "Selection Zoom",
        "zoomIn": "Mais Zoom",
        "zoomOut": "Menos zoom",
        "pan": "Panning",
        "reset": "Reiniciar Zoom"
    }
  }
}]
 
const options = {
  chart: {
    type: 'rangeBar',
    locales: locales,
    defaultLocale: "pt-BR",
    height: 'auto',
    width: "100%",
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      barHeight: sizeBar,
      columnWidth: "10%",
      distributed: true,
      horizontal: true
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function(val) {
      var a = moment(val[0])
      var b = moment(val[1])
      var diff = b.diff(a, 'days')
      return diff + (diff > 1 ? ' dias' : ' dia')
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.25,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [50, 0, 100, 100]
    }
  },
  xaxis: {
    type: 'datetime'
  },
  legend: {
    position: 'top'
  }
}

return <ReactApexChart options={options} series={series} type="rangeBar" height="100%" width="100%"/>
}

const init ={
  buffer: [],
  sizeBar: "16%"
}

export default function ModalTask() {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = React.useState(init)
 
  const updateData = async () => {
   await api(GET_INFO(id)).then((ret) => {
      const {data} = ret
      if (data === null) return

      const buffer = data.map((elem) => ({
        x: elem.taskName,
        y: [
          new Date(elem.initDate).getTime(),
          new Date(elem.deadLine).getTime(),
        ],
      }));
      const sizeBar =  getPercentWidth(380, buffer.length <= 15?buffer.length:5, 40) //buffer.length >= 3?"45%": "20%"
      setData({buffer, sizeBar})
    });
  };

  React.useEffect(() => {
    updateData();
  }, []);

 return (
 <Grid container className={classes.root}>
  <Grid item xs={12} className={classes.title}>
   <Typography gutterBottom variant="h6" align="center"> Etapas </Typography>
   <Divider/>
  </Grid> 
  <Grid item xs={12} className={classes.graphic} >
   <ViewChart buffer={data.buffer} sizeBar={data.sizeBar}/>
  </Grid>
 </Grid>    
 );
}
