import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import * as Icons from "@material-ui/icons";
import { Button, Tooltip } from "@material-ui/core";

const StyledChip = withStyles((theme) => ({
  root: {
    display: 'inline-flex',
    position: "relative",
    border: 0,
    borderRadius: 3,
    
    boxShadow: "0 3px 5px 2px #CCC",
    color: "#222", 
    padding: theme.spacing(0, 2, 0, 1),
  },
  label: {
    marginLeft: "1rem",
    justifyContent:"start",
    textAlign:"start",
    alignItems:"start",
    alignContent: "start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icon:{
    position: "absolute",
    left: "0.2rem",
    color: "#222", 
  }
}))(Chip);

export const ShowPriority = ({ value }) => {
  switch (value) {
    case "Baixa":
      return (
        <StyledChip
          style={{
            background: `linear-gradient(45deg, #a0e56b 30%, #c1ff52 90%)`,
            fontWeight: 550,
          }}
          label="Baixa"
          icon={<Icons.ArrowDownward />}
          size="small"
          variant="outlined"
        />
      );
    case "Média":
      return (
        <StyledChip
          style={{
            background: `linear-gradient(45deg, #fff770  30%, #FF8E53 90%)`,
            fontWeight: 550,
          }}
          label="Média"
          icon={<Icons.ArrowDownward />}
          size="small"
          variant="outlined"
        />
      );
    case "Alta":
      return (
        <StyledChip
          style={{
            background: `linear-gradient(45deg, #FF8E53  30%, #ff3330 90%)`,
            fontWeight: 550,
          }}
          label="Alta"
          icon={<Icons.ArrowUpward />}
          size="small"
          variant="outlined"
        />
      );
    default:
      return null;
  }
};

export const ShowErrorCreateProject = ({ opt, value }) => {
  switch (Number(opt)) {
    case 0:
      return (
        <StyledChip
          style={{ backgroundColor: "#ff3330", padding: 5 }}
          icon={<Icons.MenuBook />}
          label={`Projeto: ${value}`}
          size="small"
          variant="outlined"
        />
      );
    case 1:
      return (
        <StyledChip
          style={{ backgroundColor: "#ff3330", padding: 5 }}
          icon={<Icons.Assignment />}
          label={`Etapas: ${value}`}
          size="small"
          variant="outlined"
        />
      );
    case 2:
      return (
        <StyledChip
          style={{ backgroundColor: "#ff3330", padding: 5 }}
          icon={<Icons.ReportProblem />}
          label={`GUT: ${value}`}
          size="small"
          variant="outlined"
        />
      );
    default:
      return null;
  }
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}


//Concluído, Em andamento, Em atraso, Não iniciado, Cancelado,
export const ShowProjectStatus = ({type, value}) => {
  function msgToTask() {
    if (value > 0) {
      if (value === 1) {
        return `${value} Etapa em atraso`;
      } else {
        return `${value} Etapas em atraso`;
      }
    } else return "";

  }

  switch (type) {
    case 0:
      return (
        <BootstrapTooltip
          placement="top"
          title={msgToTask()}
          aria-label="view status task"
        >
          <StyledChip
            style={{ backgroundColor: "#CDCDCD", fontWeight: 550, minWidth: 145 }}
            icon={<Icons.Schedule/>}
            label="Não iniciado"
            size="small"
            variant="outlined"
          />
        </BootstrapTooltip>
      );
    case 1:
      return (
        <BootstrapTooltip
          placement="top"
          title={msgToTask()}
          aria-label="view status task"
        >
          <StyledChip
            style={{ backgroundColor: value === 0 ? '#a9d4ff': "#FFA500", fontWeight: 550, minWidth: 145, maxWidth: 145,}}
            icon={<Icons.Schedule />}
            label={value === 0 ? "Em andamento" : "Em andamento com atraso"}
            size="small"
            variant="outlined"
          />
        </BootstrapTooltip>
      );
    case 2:
      return (
        <BootstrapTooltip
          placement="top"
          title={msgToTask()}
          aria-label="view status task"
        >
          <StyledChip
            style={{ backgroundColor: "#FFA500", fontWeight: 550, minWidth: 145 }}
            icon={<Icons.Schedule />}
            label="Em atraso"
            size="small"
            variant="outlined"
          />
        </BootstrapTooltip>
      );
    case 3:
      return (
        <BootstrapTooltip
          placement="top"
          title={msgToTask()}
          aria-label="view status task"
        >
          <StyledChip
            style={{ backgroundColor: "#ff3330", fontWeight: 550, minWidth: 145 }}
            icon={<Icons.Warning />}
            size="small"
            label="Cancelado"
            variant="outlined"
          />
        </BootstrapTooltip>
      );
    case 4:
      return (
        <BootstrapTooltip
          placement="top"
          title={msgToTask()}
          aria-label="view status task"
        >
          <StyledChip
            style={{ backgroundColor: "#7af781", fontWeight: 550, minWidth: 145 }}
            icon={<Icons.Check />}
            size="small"
            label="Concluído"
            variant="outlined"
          />
        </BootstrapTooltip>
      );
    case 5:
      return (
        <BootstrapTooltip
          placement="top"
          title={msgToTask()}
          aria-label="view status task"
        >
          <StyledChip
            style={{ backgroundColor: "#FFA500", fontWeight: 550, minWidth: 145, maxWidth: 145 }}
            icon={<Icons.Check />}
            size="small"
            label="Em andamento com atraso"
            variant="outlined"
          />
        </BootstrapTooltip>
      );
      case 6:
        return (
          <BootstrapTooltip
            placement="top"
            title={msgToTask()}
            aria-label="view status task"
          >
            <StyledChip
              style={{ backgroundColor: "#00802B", fontWeight: 550, minWidth: 145 }}
              icon={<Icons.Warning />}
              size="small"
              label="Concluído com atraso"
              variant="outlined"
            />
          </BootstrapTooltip>
        );
   case 7:
          return (
            <BootstrapTooltip
              placement="top"
              title={msgToTask()}
              aria-label="view status task"
            >
              <StyledChip
                style={{backgroundColor: "#e65c00", fontWeight: 550, minWidth: 145 }}
                icon={<Icons.Warning />}
                size="small"
                label="Suspenso"
                variant="outlined"
              />
            </BootstrapTooltip>
          );
    default:
      return null;
  }
};

// Status Etapas
export const ShowStatus = ({ value }) => {

  function msgTask() {
    if (value > 0) {
      if (value === 1) {
        return `${value} Etapa em atraso`;
      } else {
        return `${value} Etapas em atraso`;
      }
    } else return "";   
  }

  switch (Number(value)) {
    case 0:
      return (
        <StyledChip
          style={{ backgroundColor: "#a9d4ff", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
          icon={<Icons.Schedule />}
          label="No prazo"
          size="small"
          variant="outlined"
        />
      );
    case 1: 
      return (
        <BootstrapTooltip
          placement="top"
          title={msgTask()}
          aria-label="view status task"
        >
          <StyledChip
            style={{backgroundColor: "#a9d4ff", fontWeight: 550, minWidth: 145 }}
            icon={<Icons.Schedule />}
            size="small"
            label="A fazer"
            variant="outlined"
          />
        </BootstrapTooltip>
      ); 
    case 2:
      return (
        <StyledChip
            style={{ backgroundColor: "#20a1f3", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
            icon={<Icons.Warning />}
            size="small"
            label="Em progresso"
            variant="outlined"
        />
      );
    case 3:
      return (
        <StyledChip
          style={{ backgroundColor: "#7af781", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
          icon={<Icons.Check />}
          size="small"
          label="Concluído"
          variant="outlined"
        />
      );
      case 4:
        return (
          <StyledChip
            style={{ backgroundColor: "#00802b", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
            icon={<Icons.Warning />}
            size="small"
            label="Concluído com atraso"
            variant="outlined"
          />
          
        );
      case 5:
        return (
          <StyledChip
            style={{ backgroundColor: "#e65c00", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
            icon={<Icons.Warning />}
            size="small"
            label="Suspenso"
            variant="outlined"
          />
        );
    default:
      return null;
  }
}


// etapas
export const ShowSituation = ({ value }) => {

  switch (Number(value)) {
    case 0:
      return (
        <StyledChip
          style={{ backgroundColor: "#a9d4ff", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
          icon={<Icons.Schedule />}
          label="No prazo"
          size="small"
          variant="outlined"
        />
      );
    case 1:
      return (
        <StyledChip
          style={{ backgroundColor: "#FFA500", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
          icon={<Icons.Warning />}
          size="small"
          label="Em atraso"
          variant="outlined"
        />
      );
    case 2:
      return (
        <StyledChip
          style={{ backgroundColor: "#7af781", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
          icon={<Icons.Check />}
          size="small"
          label="Concluído"
          variant="outlined"
        />
      );
    case 3:
      return (
        <StyledChip
          style={{ backgroundColor: "#ff3330", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
          icon={<Icons.Warning />}
          size="small"
          label="Cancelado"
          variant="outlined"
        />
      );
      case 4:
        return (
          <StyledChip
            style={{ backgroundColor: "#00802b", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
            icon={<Icons.Warning />}
            size="small"
            label="Concluído com atraso"
            variant="outlined"
          />
        );
      case 5:
        return (
          <StyledChip
            style={{ backgroundColor: "#ffec07", fontWeight: 550, minWidth: 115, maxWidth: 150 }}
            icon={<Icons.Warning />}
            size="small"
            label="Suspenso" //em progresso
            variant="outlined"
          />
        );
    default:
      return null;
  }
};

export function ShowLevelGut({name, level}){
  const returnColor =()=>{
  switch (level) {
    case 1: return "#00802b";
    case 2: return "#a9d4ff";
    case 3: return "#FFA500";
    case 4: return "#e65c00";
    case 5: return "#ff3330";
    default: return '#CCC';
  }
}


  return (
    <StyledChip
      style={{ backgroundColor: returnColor(), fontWeight: 550, minWidth: 115, maxWidth: 200, }}
      icon={<Icons.Warning />}
      size="small"
      label={name}
      variant="outlined"
    />
  );
  
}

const StyledButton = withStyles((theme) => ({
  root: {
    border: 0,
    borderRadius: 8,
    boxShadow: "0px 2px 3px 2px #CCC",
    color: "black",
    padding: theme.spacing(0, 1.5, 0, 1),
  },
}))(Button);



export function ShowPercent({ value }) {
  const returnColor = (value) => {
    if (value >= 0 && value <= 45) return "#ff3330";
    if (value >= 46 && value <= 60) return "#FFA500";
    if (value >= 61 && value <= 75) return "#7af781";
    if (value >= 76 && value <= 100) return "#1976D2";
  };
  // 00 - 45% : vermelho
  // 46 - 60% : amarelo
  // 61 - 75% : verde
  // 76 - 100% : azul
  return (
    <StyledButton
      fullWidth
      size="small"
      style={{
        background: `linear-gradient(90deg, ${returnColor(value)} ${value}%, #FFF 00%)`,
        maxHeight: 25,
        maxWidth: 145,
        alignContent: "center",
        textAlign: "center",
        fontWeight: 550,
      }}
    >
      {value !== null ? parseFloat(Number(value).toFixed(2)) : 0}%
    </StyledButton>
  );
}

ShowPercent.propTypes = {
  value: PropTypes.number.isRequired,
};

ShowPercent.defaultProps = {
  value: 0,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ShowMessage({ variant, message, open, setOpen }) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={variant}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

ShowMessage.defaultProps = {
  variant: "sucess",
  open: false,
};
