import React from "react";

import {
  Grid,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  GET_PRIORITY_TASK,
  GET_RESPONSIBLESECRETARY,
  GET_SECRETARY,
  GET_STATUS_TASK,
} from "../../../../services/options";
import { SelectApi } from "../../../../Components/SelectsApi";
import { ButtonSuccess } from "../../../../Components/ButtonSuccess";
import { CustomDatePicker } from "../../../../Components/CustomDatePicker";
import { SelectCustom } from "../../../../Components/SelectsCustom";

const useStyles = makeStyles((theme) =>
  createStyles({
   main: {
      [theme.breakpoints.up("xs")]: {width: 320, padding: theme.spacing(1, 2), },
      [theme.breakpoints.up("sm")]: {width: 410, padding: theme.spacing(1, 2), },
      [theme.breakpoints.up("md")]: {width: 500, padding: theme.spacing(2, 4), },
      [theme.breakpoints.up("lg")]: {width: 520, padding: theme.spacing(2, 4), },
      [theme.breakpoints.up("xl")]: {width: 550, padding: theme.spacing(2, 4), },
      margin: "1rem auto",
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "10px",
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
      alignItems: "center",
      alignContent: "center"
    },
  })
);

const Form = (props) => {
  const classes = useStyles();
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    setFieldValue,
  } = props;
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container className={classes.main} spacing={0}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h5" component="h1">
            Filtrar etapas por
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            size="small"
            id="name"
            label="Nome"
            name="name"
            autoFocus
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={values.name}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsibleSecretary"
            name="responsibleSecretary"
            onChange={(event, value) => {
              setFieldValue("responsibleSecretary", value);
            }}
            value={values.responsibleSecretary}
            config={GET_SECRETARY()}
            //multiple={true}
            //limitTags={1}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Secretaria responsável",
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectApi
            id="responsible"
            name="responsible"
            onChange={(event, value) => {
              setFieldValue("responsible", value);
            }}
            value={values.responsible}
            config={GET_RESPONSIBLESECRETARY(0)}
            //multiple={true}
            //limitTags={1}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Responsável",
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ display: "inline-flex", alignItems: "end" }}>
          <Grid item xs={5}>
           <CustomDatePicker
            fullWidth
            id="initDate"
            name="initDate"
            label="Início"
            margin="normal"
            maxDate={values.deadLine || undefined}
            onChange={(value) =>{
            setFieldValue("initDate",  value || null )}}
            value={values.initDate}
           />
          </Grid>

          <Grid item xs={2} style={{textAlign: "center", }}>
          <Typography variant="h6" gutterBottom>
            Até
          </Typography>
          </Grid>

          <Grid item xs={5}>
           <CustomDatePicker
            fullWidth
            id="deadLine"
            name="deadLine"
            label="Fim"
            margin="normal"
            minDate={values.initDate|| undefined}
            onChange={(value) =>{
            setFieldValue("deadLine",  value || null )}}
            value={values.deadLine}
           />
          </Grid>
        </Grid>
        <Grid item xs={12} >
        <Box style={{maxWidth:"40%"}}>    
         <SelectApi
            fullWidth
            id="status"
            name="status"
            onChange={(e, value) => setFieldValue("status", value || null)}
            value={values.status}
            config={GET_STATUS_TASK()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Status",
            }}
          />
         </Box>
        </Grid>

        <Grid item xs={12} style={{ maxWidth: "40%" }}>
          <SelectCustom
            id="situation"
            name="situation"
            onChange={(e, value) => {
              setFieldValue("situation", value);
            }}
            value={values.situation}
            //   multiple={true}
            //  limitTags={1}
            options={[
              { id: 1, name: "No prazo" },
              { id: 2, name: "Em atraso" },
              { id: 3, name: "Concluído" },
              { id: 4, name: "Cancelado" },
              { id: 5, name: "Suspenso" },
             // { id: 4, name: "Projeto cancelado" },
            ]}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Situação",
            }}
          />
        </Grid>

        <Grid item xs={12}>
        <Box style={{maxWidth: "40%"}}>
         <SelectApi 
            id="priority"
            name="priority"
            label="Prioridade"
            onChange={(e, value) => {
              setFieldValue("priority", value || null);
            }}
            value={values.priority}
            config={GET_PRIORITY_TASK()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              margin: "normal",
              variant: "outlined",
              label: "Prioridade",
            }}
          />
          </Box>
        </Grid>

        {/* <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Detalhar</FormLabel>
            <FormGroup >
              <Grid container   direction="row"
  justify="flex-start"
  alignItems="flex-start"
>
<Grid item  style={{display: 'grid'}}>
              <FormControlLabel
               label="Status"
                control={<Checkbox
                         checked={values.detail.status}
                         onChange={handleChange}
                         id="detail.status"
                         name="detail.status"/>}
              />
              <FormControlLabel
                label="Finanças"
                control={<Checkbox
                          id="detail.finances"
                          name="detail.finances"
                          checked={values.detail.finances}
                          onChange={handleChange}/>}       
              />

              <FormControlLabel
                label="Avanço físico"
                control={<Checkbox
                          id="detail.physicalProgress"
                          name="detail.physicalProgress"
                          checked={values.detail.physicalProgress}
                          onChange={handleChange}/>} 
              />

              <FormControlLabel
                label="Progresso"
                control={<Checkbox
                          id="detail.progress"
                          name="detail.progress"
                          checked={values.detail.progress}
                          onChange={handleChange}/>} 
              />
              </Grid>

              <Grid item   style={{display: 'grid'}}>
              <FormControlLabel
                label="Atividades"
                control={<Checkbox
                          id="detail.activity"
                          name="detail.activity"
                          checked={values.detail.activity}
                          onChange={handleChange}/>} 
              />
              </Grid>

              </Grid>
            
        
            </FormGroup>
          </FormControl>
        </Grid> */}

        <Grid item xs={12}>
        <Box style={{display: "inline-flex", alignItems: "center", float:"right", padding:0, margin: 0}}>
            {/* <Button
              style={{ marginRight: "0.5rem"}}
              variant="contained"
              color="primary"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}
            >
              Reiniciar
            </Button> */}

            <ButtonSuccess
             variant="contained"
             color="primary"
             type="submit"
             success={false}
             loading={isSubmitting}
            >
              Visualizar
            </ButtonSuccess>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
