import React from 'react';

import {List, ListItem, ListItemIcon, Tooltip, makeStyles, Icon, Paper, ListItemText, Box, Typography } from '@material-ui/core';
import {
  AccountCircle,
  MenuBook,
  FolderOpen,
} from '@material-ui/icons';

 
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  selectedPage: {
    boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "5px",
    borderLeft: "5px solid #1D91DA",
    minWidth: "100%"
  },
  page: {
    borderLeft: "5px solid #0000",
    minWidth: "100%"
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const listMenu = [
    { label: 'Perfil',   to: 0 ,  icon: <AccountCircle/> },
    { label: 'Projetos', to: 1 ,  icon: <MenuBook/> },
    { label: 'Etapas',  to: 2 ,  icon: <FolderOpen/> },
 ];

export const Menus = ({select, setSelect}) => {
  const classes = useStyles();
  return (
  <Box className={classes.root}> 
   <List style={{width: "100%"}}>
   {listMenu.map((item) => (
    <ListItem
     index={item.to}
     onClick={()=> setSelect(item.to)}
     style={{ paddingBottom:"1rem", paddingTop:'1rem', width: "100%"}}
     button
     component="a"
     className={select === item.to ? classes.selectedPage : classes.page}>
      <ListItemIcon >
       <Icon color={select === item.to ?"primary":"inherit"} >
        {item.icon}
       </Icon>
      </ListItemIcon>
     <ListItemText primary={<Typography component="span" style={{fontWeight:"bold"}}  color="textPrimary">{item.label}</Typography>}  />
    </ListItem>
    ))}
   </List>
  </Box>    
  );
};

 

 


