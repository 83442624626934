import React from "react";

import {
  Grid,
  Container,
  createStyles,
  makeStyles,
  Paper,
  Button,
  Typography
} from "@material-ui/core";

import Table from "./Table";
import FilterTask from "../../../Components/Screens/FilterTask";
import { Search } from "@material-ui/icons";
import useAxios from "../../../Context/Hooks/useAxios";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
    },
    search:{
      padding: theme.spacing(1),  
      backgroundColor: '#FFFFFF', 
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "5px",
      boxShadow: "1px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
    },
    table: {
      marginTop: "0.5rem",
      padding: theme.spacing(1),  
      backgroundColor: '#FFFFFF', 
      border: `1px solid  ${theme.palette.divider}`,
      borderRadius: "10px",
      boxShadow: "0px 2px 3px rgba(50, 50, 93, 0.11), 0px 2px 3px rgba(0, 0, 0, 0.08)",
      [theme.breakpoints.up("xs")]: { minHeight: 735,  },    
      [theme.breakpoints.up("sm")]: { minHeight: 735,  },    
      [theme.breakpoints.up("md")]: { minHeight: 735,  },   
      [theme.breakpoints.up("lg")]: { minHeight: 735,  },  
      [theme.breakpoints.up("xl")]: { minHeight: 735,  },
    },
  })
);

const initial = {
  name: "",
  responsible: null,
  responsibleSecretary: null,
  status: null,
  priority: null,
};

export const GET_USER_TASKS = (body) =>{
  return { 
   url: `task/searchUser.php`,
   method: 'post',
     data: body,
   }
  }  

export default function MainTask() {
  const classes = useStyles();
  const [search, setSearch] = React.useState(initial);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const {buffer, loading, error, request} = useAxios();
  const [viewType, setViewType] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();


  const updateData = async () =>{
   await request(GET_USER_TASKS(search))

   if(error) enqueueSnackbar("Erro ao atualizar lista", { variant: "Error" })
  }

  React.useEffect(()=>{
    const update = updateData()
    return update
  
    },[search, request])
    
 
  return (
 <>  
 <FilterTask drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} search={search} setSearch={setSearch}/>
 <Grid container className={classes.root} maxWidth="xl">
   {/* <Grid item xs={12}>
   <CurrentPageInfo primary={"Projetos"}/>
   </Grid>  */}
   <Grid item xs={12} className={classes.search}>
    <Button variant="contained" color="primary" onClick={()=> setDrawerOpen(!drawerOpen)}>
     <Typography >Filtro</Typography>
     <Search style={{marginLeft: 25}}/>
    </Button> 
   </Grid> 
   <Grid item xs={12} className={classes.table}>
    <Table data={buffer} error={error} load={loading}/>
   </Grid>
  </Grid>
 </>  
 );
}