import React from "react";
import { Routes, Route } from 'react-router-dom';
import { LayoutContext } from "../../Components/Layout/LayoutMaster";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import NewPassword from "./NewPassword";
 
const Login = () => {
    const {setShowLayout} = React.useContext(LayoutContext);

    React.useEffect(()=>{
      setShowLayout(false)
    },[])

    return (
      <Routes>
        <Route path="/"                element={<LoginForm/>}/> 
        <Route path="/forgotpassword"  element={<ForgotPassword/>}/>   
        <Route path="/newpassword/:id" element={<NewPassword/>}/>  
      </Routes>
  );
};

export default Login;
