import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  createStyles,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";

import { GET_GRAVITY, GET_TREND, GET_URGENCY } from "../../services/options";
import { SelectApi } from "../SelectsApi";
import { ButtonSuccess } from "../ButtonSuccess";
import { DefaultModalActions, DefaultModalContent } from "../DefaultModal";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {width: 350, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("sm")]: {width: 380, padding: theme.spacing(0, 1, 0, 1),},   
    [theme.breakpoints.up("md")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("lg")]: {width: 400, padding: theme.spacing(0, 1, 0, 1),},  
    [theme.breakpoints.up("xl")]: {width: 450, padding: theme.spacing(0, 1, 0, 1),},  
  },
})
);

const validationSchema = Yup.object({
  risk: Yup.string()
    .required("Obrigatório")
    .min(6, "O campo deve ter pelo menos 6 caracteres")
    .max(100, "Limite máximo de 100 caracteres"),
  gravity: Yup.object().shape().required("Obrigatório").nullable(),
  urgency: Yup.object().shape().required("Obrigatório").nullable(),
  trend: Yup.object().shape().required("Obrigatório").nullable(),
  gut: Yup.number().required(),
  average: Yup.number().optional()
 // media: 
});

export default function FormGUT({ submit, initialValues }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {(props) => <Form {...props} />}
    </Formik>
  );
}

const Form = (props) => {
  const classes = useStyles();
  const {
    values: { risk, gravity, urgency, trend, gut, average = 0 },
    touched,
    errors,
    dirty,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = props;

  React.useEffect(() => {
    const start = Number(gravity?.id) * Number(urgency?.id) * Number(trend?.id);
    const calc  = Math.round((Number(gravity?.id) + Number(urgency?.id) + Number(trend?.id)) / 3);

    setFieldValue("gut", start || 0);
    setFieldValue("average", calc || 0);
  }, [gravity, urgency, trend]); // (G) x (U) x (T)

  return (
    <form onSubmit={handleSubmit} noValidate>
      <DefaultModalContent dividers>
         <Grid container className={classes.root} spacing={3}> 

         <Grid item xs={12}>
          <TextField
            fullWidth
            autoFocus
            multiline
            maxRows={5}
            variant="outlined"
            size="small"
            id="risk"
            label="Risco"
            name="risk"
            inputProps={{ autoComplete: "off" }}
            onChange={handleChange}
            value={risk}
            error={touched.risk && Boolean(errors.risk)}
            helperText={touched.risk && errors.risk}
          />
          </Grid>

          <Grid item xs={12}>
          <SelectApi
            id="gravity"
            name="gravity"
            onChange={(e, value) => setFieldValue("gravity", value || null)}
            value={gravity}
            config={GET_GRAVITY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Gravidade",
            }}
            error={touched.gravity && Boolean(errors.gravity)}
            helperText={touched.gravity && errors.gravity}
          />
          </Grid>

          <Grid item xs={12}>
          <SelectApi
            id="urgency"
            name="urgency"
            onChange={(e, value) => setFieldValue("urgency", value || null)}
            value={urgency}
            config={GET_URGENCY()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Urgência",
            }}
            error={touched.urgency && Boolean(errors.urgency)}
            helperText={touched.urgency && errors.urgency}
          />
          </Grid>

          <Grid item xs={12}>
          <SelectApi
            id="trend"
            name="trend"
            onChange={(e, value) => setFieldValue("trend", value || null)}
            value={trend}
            config={GET_TREND()}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              variant: "outlined",
              label: "Tendência",
            }}
            error={touched.trend && Boolean(errors.trend)}
            helperText={touched.trend && errors.trend}
          />
          </Grid>

          {/* <Grid item xs={12} style={{display: "grid"}}>
           <Typography >
             {`Pontuação (GUT): ${gut}`}
           </Typography>

           <Typography>
             {`Nível do problema: ${average}`}
           </Typography>

         </Grid> */}
          
        </Grid>
        </DefaultModalContent>

        <DefaultModalActions style={{marginRight: "0.5rem"}}>
            {/* <Button
              style={{ width: 110, marginRight: "1rem" }}
              variant="contained"
              color="primary"
              onClick={handleReset}
              disabled={!dirty || isSubmitting}> 
              Reiniciar
            </Button> */}
            <ButtonSuccess
              style={{ width: 110 }}
              variant="contained"
              color="primary"
              type="submit"
              success={false}
              disabled={!dirty}
              loading={isSubmitting}>
              Confirmar
            </ButtonSuccess>
      </DefaultModalActions>
    
    </form>
  );
};

FormGUT.defaultProps = {
  submit: (value) => {

    return true;
  },

  initialValues: {
    id: 0,
    risk: "",
    gravity: null,
    urgency: null,
    trend: null,
    gut: 0,
  },
};
