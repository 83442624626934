import React, { useEffect } from 'react'

import CustomTable from "../../../Components/CustomTable";
import { FormatDate, FormatRealNumber } from '../../../Components/Helper/FormatValues';
import { ShowSituation, ShowProjectStatus, ShowStatus } from '../../../Components/ShowInfos';
import DialogViewTask from '../../../Components/Screens/ViewTask';
import axios from 'axios';
import { GET_TABLE_PROJECT } from '../../../services/options';

export default function ReadTable({data, updateData, error, load, className}) {
  const [rowId, setRowId] = React.useState(null)
  const [open, setOpen] = React.useState(true); 
    
  const handleOpen = (id)=>{
    setRowId(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setOpen(false)
    setRowId(null)
  }
 
  const options = {onRowClick: (rowData, rowState) => handleOpen(rowData[0])};

  console.log('Esse é o dataTable:' + JSON.stringify(data));

    useEffect(() =>{
      GET_TABLE_PROJECT();

      // axios.get('https://dev.draftmanagementproject.com/api/task/description.php')
      // .then(function(response){
      //   console.log('Deu certo! ', response)
      // }).catch(function(error){
      //   console.log('Deu erro! ', error)
      // })
    })


  return (
    
  <>
   {rowId && <DialogViewTask id={rowId} open={open} close={handleClose} updateData={updateData}/> }
    <CustomTable data={data} columns={columns} opt={options} error={error} load={load}/>
  </>
    )
  }
 
  const columns = [
    { name: "taskId", options: { display: "false" } },
    { name: "name", label: "Etapa", options: { filter: true, sort: true } },
    { name: "responsibleSecretary", label: "Secretaria", options: { filter: true, sort: true } },
    { name: "responsible", label: "Responsável", options: { filter: true, sort: true } },

    // { name: "priority", label: "Prioridade", options: { filter: true, sort: true,
    //   customBodyRender: (value) => <ShowPriority value={value}/>}
    // },
    {
      name: "statusId", label: "Status", options: { filter: true, sort: true,
      customBodyRender: (value) => <ShowStatus value={value} />}
    },
    { name: "situation", label: "Situação", options: { filter: true, sort: true,
      customBodyRender: (value) => <ShowSituation value={value}/>}
    },
  {
    name: "predictedValue",
    label: "Valor Previsto",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => FormatRealNumber(value)
    },
  },
  {
    name: "executeValue",
    label: "Valor Executado",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => FormatRealNumber(value)
    },
  },
    { name: "initDate", label: "Data inicial", 
      options: { filter: true, sort: true, customBodyRender: (value) => FormatDate(value,'Não definida')}},
    { name: "deadLine", label: "Data prevista", 
      options: { filter: true, sort: true, customBodyRender: (value) => FormatDate(value,'Não definida')}},
    { name: "executeDate", label: "Data de execução", 
      options: { filter: true, sort: true, customBodyRender: (value) => FormatDate(value,'Não definida')}},
  ];
  
  