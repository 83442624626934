import React from "react";
import { useParams } from "react-router-dom";
import { convertToHTML } from 'draft-convert';
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import DefaultModal, { DefaultModalActions, DefaultModalContent } from '../../../../Components/DefaultModal'
import { makeStyles, createStyles } from "@material-ui/core/styles";
import * as Icons from "@material-ui/icons";
import { TextEditor } from "../../../../Components/Editor";
import { Fab } from "@material-ui/core";
import api from "../../../../services/Api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) =>
createStyles({
  root: {
    [theme.breakpoints.up("xs")]: { maxWidth: 380 },
    [theme.breakpoints.up("sm")]: { maxWidth: 450 },
    [theme.breakpoints.up("md")]: { maxWidth: 500 },
    [theme.breakpoints.up("lg")]: { maxWidth: 500 },
    [theme.breakpoints.up("xl")]: { maxWidth: 550 },
  },
})
);

const validationSchema = Yup.object().shape({
  msg: Yup.string().required("Obrigatório").min(8, "Obrigatório"),
});

const initValue = {
  msg: "<p></p>"
}

export const POST_UPDATE_PROJECT = (body) =>{
  return {
    url: "project/observationinsert.php",
    method: 'post',
    data: body,
  };
}

const NewComment = ({open, setOpen, handleUpdate}) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const submit = async (values, { setSubmitting }) => {
    const buffer ={
     projectId : id,
     msg: values.msg,
    }
    
    await api(POST_UPDATE_PROJECT(buffer))
      .then((r) => {
        const { data } = r;
        if (data.status === "success") {
          handleUpdate();
          setOpen(false);
          enqueueSnackbar("Comentário realizado !!!", { variant: "success" });
        } else {
          setSubmitting(false);
          enqueueSnackbar(`Erro ao comentar !!!`, { variant: "Error" });
        }
      })
      .catch((r) => {
        setSubmitting(false);
        enqueueSnackbar(`Erro ao comentar !!!`, { variant: "Error" });
      });
  };

  return (
   <DefaultModal
   open={open}
   handleClose={setOpen}
   title="Novo comentário">
   <Formik
    initialValues={initValue}
    validationSchema={validationSchema}
    onSubmit={submit}>
     {(props) => <Forms {...props}/>}
   </Formik>
   </DefaultModal>
  )
}

const Forms = (props) => {
 const classes = useStyles();
 const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } = props;
 return (
 <form onSubmit={handleSubmit} noValidate>
  <DefaultModalContent dividers className={classes.root}>
   <TextEditor
    id="msg"
    name="msg"
    label="Faça um comentário"
    onChange={(event) => {setFieldValue("msg", convertToHTML(event.getCurrentContent()))}}
    value={values.msg}
    error={touched.msg && Boolean(errors.msg)}
    helperText={touched.msg && errors.msg}
   />
  </DefaultModalContent>
  <DefaultModalActions >
    <Fab variant="extended" 
         size="small" 
         color="primary" 
         aria-label="add" 
         style={{padding: "0.6rem", width:"100%"}} 
         type="submit" 
         disabled={isSubmitting}>
        <Icons.Send style={{marginRight: "0.5rem"}}/> 
         Enviar
    </Fab>
  </DefaultModalActions>
 </form>
  );
};

// const NewText=()=>{
//   const { enqueueSnackbar } = useSnackbar();
//   const {handleSubmit, setFieldValue, values, touched, errors, isSubmitting } =  useFormik({
//     initialValues: initValue,
//     validationSchema: validationSchema,
//     onSubmit: (values, { setSubmitting}) => {
//        enqueueSnackbar("Observação enviada !!!", { variant: "success" });
//        setSubmitting(false);
//        //resetForm();
//        //handleReset()
    
//       },
//     });
//   return(
//     <Paper elevation={3} style={{margin:"1rem", width: "95.5%"}}>
//      <form noValidate onSubmit={handleSubmit}>
//      <ListItem alignItems="flex-start">
//       <ListItemAvatar> 
//        <Avatar/> 
//       </ListItemAvatar>
//       <ListItemText
//        primary={
//        <TextEditor
//         id="observation"
//         name="observation"
//         label="Observação"
//         onChange={(event) => {
//           setFieldValue("observation", convertToHTML(event.getCurrentContent()));
//         }}
//         value={values.observation}
//         error={touched.observation && Boolean(errors.observation)}
//         helperText={touched.observation && errors.observation}
//         elevation={0}
//         stylePaper={{margin: 0, padding: 0, minHeight: 130}}
//       />}
//        secondary={
//        <Box style={{display: 'block', float: 'right', width:"120px", marginTop: "0.5rem"}}> 
//         <Fab variant="extended" size="small" color="primary" aria-label="add" style={{padding: "0.6rem", width:"100%"}} 
//              type="submit" 
//              disabled={isSubmitting}>
//          <Icons.Send style={{marginRight: "0.5rem", }}/> Enviar
//         </Fab>
//        </Box>}
//      />
//     </ListItem>
//     </form>
//   </Paper>
//   )
// }
export default NewComment
