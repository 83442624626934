import * as React from "react";

import { Notifications, WebAsset, Assignment, Inbox } from "@material-ui/icons";

import {
  Badge,
  IconButton,
  ListItem,
  Menu,
  withStyles,
  Divider,
  ListItemText,
  Typography,
  List,
  ListSubheader,
  Fab,
  ListItemIcon,
  CircularProgress,
  Link
} from "@material-ui/core";

import useAxios from "../../../Context/Hooks/useAxios";
import DialogViewTask from "../ViewTask";


export const GET_TASK_LIST = () => {
  return { 
   method: 'post',
   url: "task/taskLate.php",
  }
 }

 
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    PaperProps={{
      style: {
        maxHeight: `calc(100vh - 5rem)`,
        maxWidth: "27rem",
        minWidth: "23rem",
        marginTop: "0.35rem",
      },
    }}
    {...props}
  />
));

const CustomIcon = ({ type }) => {
  switch (Number(type)) {
    case 0:
      return <Assignment />;
    case 1:
      return <WebAsset />;
    case 2:
      return <Inbox />;
    default:
      return null;
  }
};

const CustomItem = ({type, id, projectId, projectName, title, msg, handleSelect}) => {

  return (
    <>
      <ListItem
        button
        alignItems="flex-start"
        style={{padding: "2rem", borderRadius: 15, background: '#ddd', color: '#333'}}
        onClick={() => handleSelect({type, id, projectId, projectName})}
      >
        <ListItemIcon>
         <Fab size="small"><CustomIcon type={type} /></Fab>
        </ListItemIcon>
        <ListItemText
          primary={<Typography style={{fontWeight: "bold"}}>Projeto: {projectId} - {title}</Typography>}
          secondary={
            <Typography
              component="span"
              variant="body2"
              style={{ display: "inline", width: 100 }}
              color="textSecondary"
            >
              Projeto: {projectName} &nbsp; 
              Etapa: {msg}
            </Typography>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li"/>
    </>
  );
};

export function MenuNotification({ notification }) {


  const { buffer, loading, error, request } = useAxios();

  const [openMsg, setOpenMsg] = React.useState(false)
  const [select, setSelect] = React.useState({type: null, id: null})

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const handleSelect =(select)=>{
    setSelect(select)
    setOpenMsg(true)
   }

   const handleCloseMsg = ()=>{
    setOpenMsg(false)
    setSelect({type: null, id: null})
   }
  
   function getSelectContent() {
    switch (select.type) {
      // c ase 0: return (
      //   <Link href={select.projectId}>{select.projectName}</Link>
      // )
      case 0: return <DialogViewTask id={select.id} open={openMsg} close={handleCloseMsg} updateData={updateData}/>;
      default: return null;
    }
  }

  const updateData = async () => {
    await request(GET_TASK_LIST()).then(r=>{
    })

  };
 
  React.useEffect(()=>{
   if (open) updateData()
  },[open])

  return (
    <div>
      <IconButton
        aria-label="notifications"
        id="long-button-notifications"
        aria-controls="long-notifications"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color={open ?"primary":"inherit"}>
       <Badge badgeContent={notification} color="secondary">
        <Notifications/>
       </Badge>
      </IconButton>
      <StyledMenu
        id="long-notifications"
        MenuListProps={{"aria-labelledby": "notifications"}}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
          {/* Onde altera as notificações */}
        <Typography style={{fontWeight: "bold", marginLeft: "1rem"}} variant="h6">Notificações</Typography>  
        <List dense>
        {getSelectContent()}
        {buffer !== null && buffer.length > 0 ? (
          buffer.map((opt) => (
            
            <CustomItem
              type={0}
              id={opt.taskId}
              title={"Etapa em atraso"}
              projectId={opt.projectId}
              projectName={opt.projectName}
              msg={`${opt.name} - ${opt.deadLine}` }
              handleSelect={handleSelect} 
            />
          ))
        ) : 
        (<ListItem
          button
          alignItems="center"
          style={{width: '23rem'}}>
         <ListItemIcon>
           {loading && <CircularProgress/>}
         </ListItemIcon>
         <ListItemText
          primary={       
          <Typography
            component="span"
            variant="body2"
            style={{ display: "inline", width: 100 }}
            color="textPrimary"
          >
          {loading?"Carregando!!!":"Sem notificação"} 
          </Typography>}/>
         </ListItem>)
       }
      </List>  
      </StyledMenu>
    </div>
  );
}
